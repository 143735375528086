.additional-info-wrapper {
	text-align: center;
	color: $color-neutral-90;
	background-color: $color-neutral-light;
	max-width: $default-max-app-width;
	box-sizing: border-box;
	position: relative;
	height: 14px;

	.selected-datetime {
		font-size: $font-size-lg;
		line-height: $line-height-md;
		position: relative;
		top: -15px;
		font-weight: $font-weight-normal;
		color: $color-time-label;
	}
}
