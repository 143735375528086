.find-colleague-page {
	height: 100%;
	display: flex;
	flex-flow: column;

	.secondary-header-wrapper {
		box-shadow: none;
	}

	.currently-info-header {
		background-color: $color-neutral-95;
		padding: $spacing-xs $spacing-m;
		font-weight: $font-weight-black;
		height: auto;

		p {
			margin: 0;
		}
	}

	.loading-wrapper {
		box-shadow: inset 0 10px 15px -15px $color-neutral-60;
		display: flex;
		justify-content: center;
		padding-top: 20%;
	}

	.colleagues-list {
		overflow-y: auto;
		.add-people-wrapper {
			margin: $spacing-m;
		}
		.info-text {
			font-weight: $font-weight-normal;
			font-size: $font-size-lg;
			padding: $spacing-m;
			padding-top: $spacing-lg;
			margin: 0;
		}
	}

	&-no-colleagues {
		box-shadow: inset 0 10px 15px -15px $color-neutral-60;
		background-color: $color-neutral-95;
		height: 100%;
		display: flex;
		align-items: center;
		flex-flow: column;
		padding-top: 20%;
		text-align: center;
		font-weight: $font-weight-black;

		.icon {
			width: 50px;
			height: 50px;
		}
		.info-text {
			font-weight: $font-weight-normal;
			width: 80%;
		}
	}
}
