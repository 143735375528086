.event-support-page {
	height: 100%;
	display: flex;
	flex-flow: column;
	&-content {
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-self: center;
		padding: 0 $spacing-m;
		flex-grow: 2;
		overflow-y: scroll;
		padding-bottom: 50px;

		.settings-item {
			width: 100%;
		}
	}
}
