.card.not-found-card {
	.search-icon-wrapper {
		display: flex;
		width: 90px;
		height: 90px;
		justify-content: center;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
		border-radius: 50%;
		border: 4px solid $color-yellow-50;
		margin-bottom: $default-spacing;

		.search-icon {
			width: 54px;
			height: 54px;
		}
	}

	.data-wrapper {
		text-align: center;

		.header {
			font-size: $font-size-xlg;
			font-weight: $font-weight-bold;
			line-height: $line-height-xlg;
			margin: 0;
			padding: 0 $default-spacing;

			&:first-letter {
				text-transform: capitalize;
			}
		}

		.text {
			overflow-wrap: break-word;
			padding: $default-spacing $default-spacing 0;
			margin-bottom: 0;
		}
	}
}
