.notification-details-page {
	height: 100%;
	display: flex;
	flex-flow: column;
	overflow-y: scroll;

	.bold {
		font-weight: bold;
	}

	&-content {
		display: flex;
		flex-flow: column;
		overflow: scroll;
		flex-grow: 2;
	}
	.message-wrapper {
		margin: 0 $default-spacing;

		.message-header {
			font-size: $font-size-mlg;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.timespan {
				font-size: $font-size-md;
				color: $color-neutral-60;
			}
		}

		.notification-header {
			color: $color-main;
			font-size: $font-size-mlg;
		}
	}

	.notification-details-wrapper {
		margin: 0 $default-spacing;

		.notification-header {
			font-size: $font-size-mlg;
		}

		.notification-info {
			margin: 0;
			margin-bottom: 5px;
		}
	}

	.button-fixed-at-bottom {
		position: static;
		display: flex;
		flex-flow: column;
		align-items: center;
		height: auto;
		padding: $spacing-m 0;

		.buttons-wrapper {
			display: flex;
			width: 100%;
			justify-content: center;
		}

		.book-another-info {
			margin: $default-spacing;
		}
	}

	.cancellation-reason {
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
	}
}
