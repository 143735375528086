.daily-calendar-wrapper {
	overflow-y: scroll;
	padding-top: $default-spacing;

	.dnd-wrapper {
		width: 100%;
		position: relative;

		.hour-skeleton-item {
			height: 60px;
			display: flex;
			justify-content: flex-end;
			position: relative;

			.hour-mark {
				width: 15%;
				display: flex;
				justify-content: flex-end;
				font-size: $font-size-sm;
				position: absolute;
				top: -5px;
				left: 0;

				p {
					margin: 0;
				}
			}

			.hour-item-empty {
				width: 85%;
				border-top: 1px dashed $color-neutral-95;
			}
		}

		.current-hour-mark {
			position: absolute;
			width: 85%;
			right: 0;
			display: flex;
			align-items: center;
			z-index: 1;

			.dot-hour-mark {
				width: 7px;
				height: 7px;
				border-radius: 50%;
				background: $color-main;
			}

			.line-hour-mark {
				height: 2px;
				width: 100%;
				background: $color-main;
			}
		}

		.new-booking-item,
		.created-booking {
			border-radius: 10px;
			background-color: $color-main-90;
			width: 83%;
			right: 0;
			position: relative;
			box-shadow: $color-neutral-95 5px 2px 2px 1px;

			.content {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: space-between;
				align-items: flex-start;

				.recurring-booking-icon {
					padding: $spacing-m;
					svg {
						width: 30px;
						height: 30px;
					}
				}
			}

			.entity-name {
				padding-left: $default-spacing;
				margin: 0;
				font-size: $font-size-sm;
				font-weight: bold;
			}

			.entity-timeslot {
				font-weight: normal;
			}

			.dot-stretcher-touch-area {
				width: 50px;
				height: 20px;
				background-color: transparent;
				position: absolute;
			}

			.dot-stretcher {
				border-radius: 50%;
				width: 8px;
				height: 8px;
				background-color: white;
				border-color: $color-main-90;
				border: 1px solid;
				position: absolute;
			}
			.touch-area-top {
				top: -10px;
				right: 0px;
			}

			.touch-area-bottom {
				bottom: -4px;
				left: 0px;
			}

			.top {
				top: -4px;
				right: 20px;
			}

			.bottom {
				bottom: -4px;
				left: 20px;
			}
		}

		.created-booking {
			background-color: $color-neutral-light;
			border-left: 4px $color-red-50 solid;
		}

		.past-booking {
			background-color: $color-red-95;
			border: none;
		}

		.own-booking {
			background-color: $color-neutral-95;
			border: none;
		}
	}
	.main-list-wrapper {
		height: 100%;
	}

	&-loading {
		display: flex;
		justify-content: center;
		margin-top: 10%;
	}
}

.stopTouch {
	touch-action: none;
	overflow-y: hidden;
}

.calendar-disabled {
	background: repeating-linear-gradient(-55deg, transparent, $color-neutral-95 5px, $color-neutral-95 5px, transparent 10px) !important;
	overflow: hidden;

	.dnd-wrapper {
		opacity: 0.5;
	}
}
