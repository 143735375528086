.settings-working-hours {
  .main-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .main-switch span {
    font-weight: bold;
  }

  p {
    font-size: $font-size-md;
    color: #666;
    margin-bottom: $spacing-m;
  }

  .time-capture {
    color: $color-main;
    font-weight: bold;
    margin-bottom: $spacing-m;
  }

  .day-input {
    margin-bottom: $spacing-lg;
  }
  .day-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing-m;
  }
}

.settings-vacation-overtime {
  p {
    font-weight: bold;
    color: $color-main;
    margin-bottom: $spacing-m;
  }
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  &-item {
    .body-large {
      color:  $color-main-20;
      font-weight: normal;
      margin: 0;
    }
    .body-medium {
      color: $color-main-20;
      font-weight: bolder;
      margin: 0;
    }
    background-color: $color-main-99;
    padding: 0.5rem;
    text-align: left;
  }
}
