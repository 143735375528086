.rounded-checkbox-wrapper {
	display: flex;
	flex-flow: wrap;
	justify-content: center;
	margin-left: -5px;
	margin-right: 5px;
	align-items: center;
	position: relative;

	.rounded-checkbox {
		width: 35px;
		height: 35px;
		border-radius: 50%;
		vertical-align: middle;
		border: 1px solid $color-neutral-90;
		appearance: none;
		-webkit-appearance: none;
		outline: none;
		cursor: pointer;
	}

	.rounded-checkbox:checked {
		background-color: $color-main;
	}

	i {
		color: $color-neutral-light;
		pointer-events: none;
		position: absolute;
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
	}
}
