.checkin-card {
	width: 370px !important;

	.card-content {
		padding: 30px !important;

		.date-time-input-wrapper {
			width: 100%;
		}

		.date-time-input-content .datepicker-wrapper {
			width: 90%;
		}

		.checkin-time-date {
			margin-top: 40px;
		}

		.checkin-card-header {
			display: flex;
			flex-direction: column;
			gap: 12px;

			&-title {
				text-align: center;
				font-size: $font-size-xlg;
				font-weight: $font-weight-bold;
			}

			&-subtitle {
				text-align: center;
				font-size: $font-size-lg;

				span {
					color: $color-main;
					font-weight: $font-weight-bold;
				}
			}
		}
	}
}
