// List that contains the header/ container for items / button
.aspen-list {
	&-page {
		height: 100%;
		display: flex;
		flex-flow: column;
	}

	&-header {
		.header-wrapper {
			display: flex;
			flex-flow: column;

			p {
				margin: 0;
			}
			.selected-date {
				font-size: $font-size-md;
				color: $color-neutral-80;
				font-weight: $font-weight-normal;
			}
		}
	}

	&-content {
		flex-grow: 2;
		margin: $spacing-m;
		overflow: scroll;

		.loading-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 20%;
		}
	}
}

// Item showed in the list
.aspen-item {
	@extend %list-item;
	height: 124px;
	padding: 0;

	&-active {
		border: 1.5px $color-main solid;
		.name {
			color: $color-main;
		}
	}

	&-image {
		min-width: 90px;
		height: 100%;

		.icon-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			background-color: $color-neutral-95;
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
		}
	}
	&-details {
		display: flex;
		flex-flow: column;
		justify-content: space-between;
		margin: $spacing-m;
		width: calc(100% - 90px - calc($spacing-m * 2)); // 100% - image width - margin
		p {
			margin: 0;
		}
		.name-action-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.name {
				font-size: $font-size-mmlg;
				line-height: $font-size-mmlg;
				font-weight: $font-weight-bold;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}

		.duration {
			font-size: $font-size-mlg;
			font-weight: $font-weight-normal;
			color: $color-neutral-80;
		}
	}
}

// Summary page that contains informations about selected aspen item
.aspen-summary {
	&-page {
		height: 100%;
		display: flex;
		flex-flow: column;
	}

	&-content {
		flex-grow: 2;
		background-color: $color-neutral-95;
		margin-top: 20px;
		overflow-y: scroll;

		.dividing-hr {
			margin: $spacing-lg 0;
		}
		&-details {
			background-color: $color-neutral-light;
			padding-bottom: $spacing-m;

			&-item {
				font-size: $font-size-lg;
				font-weight: $font-weight-bold;
				margin: $spacing-m;

				span {
					font-weight: $font-weight-normal;
				}
			}
			.total-cost {
				display: flex;
				align-items: center;
				margin-bottom: $spacing-m;

				p {
					margin: 0;
				}
			}
		}
		&-header {
			margin: 0 $spacing-m $spacing-m $spacing-m;
		}

		.small-title,
		.company-payment-info,
		.project-number-input {
			margin: 0 $spacing-m $spacing-s $spacing-m;
		}

		.company-payment-info {
			font-size: $font-size-lg;
			color: $color-neutral-80;
		}

		.additional-info-wrapper {
			display: flex;
			justify-content: center;
			height: auto;
			width: 100%;

			.textarea-holder {
				width: 100%;
				margin: 0 $spacing-m;
			}
		}
	}
}

//modal-sheet for selecting available times and other data about aspen item
.aspen-item-modal-sheet {
	&-wrapper {
		position: absolute !important;
		background-color: transparent;
		z-index: 1 !important;
	}
	&-container {
		border-radius: 25px !important;
	}
	&-content {
		display: flex;
		flex-flow: column;
		touch-action: pan-x pan-y !important;

		&-header {
			background-color: $color-neutral-95;

			.modal-header-wrapper {
				width: 100%;
				display: flex;
				justify-content: flex-end;

				p {
					margin: $spacing-s $spacing-m 0 0;
				}
			}
			.icon-wrapper {
				width: 100%;
				display: flex;
				justify-content: center;
				.icon {
					width: 100px;
					height: 100px;
				}
			}

			.order-header {
				background-color: $color-neutral-80;
				color: $color-neutral-light;
				margin-top: $spacing-md;

				.header {
					padding: $spacing-s $spacing-m;
					margin: 0;
					font-size: $font-size-mmlg;
				}
			}
		}

		.top-information {
			display: flex;
			justify-content: space-between;
			padding: $spacing-md $spacing-m;
			.pricing-information {
				width: 85%;

				.item-paid,
				.items-total {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: $font-size-lg;
					font-weight: $font-weight-semibold;
				}
				.item-paid {
					margin-bottom: $spacing-xs;
				}
				.items-total {
					font-size: $font-size-mlg;
					.total {
						color: $color-main;
						font-weight: $font-weight-bold;
					}
				}
			}
		}

		&-content {
			flex-grow: 2;

			.dropdown-label,
			.start-time-header {
				font-size: $font-size-lg;
				color: $color-neutral-80;
				padding: $spacing-s $spacing-m;
				margin: 0;
				font-weight: $font-weight-normal;
			}
			.dropdown-input {
				margin: 0 $spacing-m;
			}

			.location-info-wrapper {
				display: flex;
				padding: $spacing-xs $spacing-m;
				&-icon {
					border: solid $color-neutral-dark 2px;
					border-radius: 50%;
					min-width: 15px;
					max-height: 15px;
					padding: 1px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-right: $spacing-xxs;

					.icon {
						width: 10px;
						height: 10px;
					}
				}

				p {
					margin: 0;
					font-size: $font-size-md;

					span {
						font-weight: $font-weight-bold;
					}
				}
			}
			.time-slots {
				&-wrapper {
					display: flex;
					justify-content: center;
					height: 160px;
					overflow: scroll;
				}

				&-container {
					display: grid;
					grid-template-columns: 100px 100px 100px;
					column-gap: 12px;
					row-gap: 12px;
					place-items: center;

					.time-slot {
						font-size: $font-size-lg;
						color: $color-neutral-60;
						padding: $spacing-s $spacing-xlg;
						background-color: $color-neutral-95;
						border-radius: 30px;

						&-small-padding {
							padding: $spacing-s $spacing-m;
							font-size: $font-size-md;
						}
						&-selected {
							color: $color-main;
							background-color: $color-main-90;
						}
					}
				}
			}
		}
		&-button {
			box-shadow: none;
			margin-bottom: 50px;
		}
	}
}
.paid-by-company-amount {
	margin-left: $spacing-xxs;
	color: $color-neutral-80;
}
