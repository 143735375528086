.covid-status-page {
	height: 100%;
	overflow-y: auto;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding-bottom: 30px;

	&-container {
		padding: 0 15px;
	}

	&-content {
		width: 100%;
		align-items: center;
		display: flex;
		flex-direction: column;

		&-wrapper {
			margin-bottom: 30px;
			align-items: center;
			display: flex;
			flex-direction: column;
		}
	}

	.bold-text {
		font-weight: $font-weight-medium;
		margin-bottom: 30px;
	}

	&-text {
		line-height: 20px;
		font-size: $font-size-lg;
		text-align: justify;
	}

	&-renew-button {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		color: $color-main;
		font-weight: bold;
		width: 100%;
		margin: 0;

		.icon {
			margin-left: 5px;
		}
	}

	&-info {
		background: $color-neutral-95;
		padding: 24px;
		margin: 15px;
		box-sizing: border-box;
		border-radius: 12px;
		width: 100%;

		&-heading {
			font-weight: $font-weight-medium;
		}

		&-text {
			font-weight: $font-weight-normal;
			display: flex;
			align-items: center;
			margin-top: 12px;

			&-green {
				color: $color-green-50;
			}

			&-red {
				color: $color-red-50;
			}

			.fa-circle-xmark,
			.fa-circle-check {
				margin-right: 5px;
			}

			&-grey {
				color: $color-neutral-80;
				font-weight: bold;
			}
		}
	}

	.add-certificate-wrapper {
		background: $color-neutral-95;
		padding: 24px;
		margin: 15px;
		box-sizing: border-box;
		border-radius: 12px;
		width: 100%;

		.btn-primary.button {
			background: $color-red-60;
			border-color: $color-red-60;
		}

		.btn-secondary.button {
			color: $color-red-60;
			border-color: $color-red-60;
		}

		.btn-secondary.button:hover {
			background: $color-red-60;
			color: $color-neutral-light;

			.icon {
				color: $color-neutral-light;
			}
		}

		.buttons-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			align-content: center;
			width: 100%;

			.button {
				margin: 5px 0;
				width: 70%;
				align-items: center;
				display: flex;
				justify-content: center;

				.icon {
					margin-right: 5px;
				}
			}
		}

		.hidden-buttons-wrapper,
		.hidden {
			display: none;
		}
	}
}
