.my-reports {
	&-header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.show-all {
			padding: 0;
		}
	}

	&-container-items {
		flex-grow: 2;
		overflow-y: scroll;

		.loading-wrapper {
			height: 80%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.date-header {
			font-size: $font-size-mlg;
			color: $color-neutral-60;
			font-weight: $font-weight-bold;
			padding: $spacing-s $spacing-m;
			margin: 0;

			span {
				color: $color-neutral-80;
			}
		}

		.no-reports-wrapper {
			display: flex;
			flex-flow: column;
			justify-content: center;
			align-items: center;
			height: 80%;

			.action-icon {
				width: 50px;
				margin-bottom: $spacing-s;
			}
			p {
				margin: 0 $spacing-s;
				font-size: $font-size-lg;
				text-align: center;
			}

			.bold {
				margin: $spacing-s;
				font-size: $font-size-mmlg;
			}
		}
	}

	&-page {
		height: 100%;
		background-color: $color-neutral-95;
		display: flex;
		flex-flow: column;

		&-header {
			background-color: $color-neutral-light;
			@include sm-box-shadow;
			.content-switcher {
				margin: $spacing-s $spacing-m;
			}
		}
	}

	&-details-content {
		flex-grow: 2;
		overflow-y: scroll;
	}
	&-details-page {
		display: flex;
		flex-flow: column;
		overflow: auto;
		height: 100%;
		.section-detail {
			@include section-detail;
		}
		.section-detail-status {
			@include section-detail;
			display: flex;
			align-items: center;
			p {
				margin: 0;
			}
			.status {
				margin-left: $spacing-xs;
			}
		}
		.content-switcher {
			margin: $spacing-m;
		}
	}

	&-add-comment {
		@include md-box-shadow;
		.textarea-holder {
			margin: $spacing-m;

			textarea {
				resize: none;
				height: 80px;
			}
			textarea::placeholder {
				font-family: $body-font-family;
				white-space: pre-line;
				font-size: $font-size-md;
			}
		}
		.button-wrapper {
			display: flex;
			justify-content: center;
			margin: 0 $spacing-m $spacing-lg $spacing-m;
			button {
				margin: 0;
			}
		}
	}
}

//report item css = only contains styling for cards
.report-item {
	padding: $spacing-s;
	margin: $spacing-s;
	border-radius: 10px;
	@include sm-box-shadow;
	background-color: $color-neutral-light;

	.name-wrapper {
		display: flex;
		align-items: center;
		.name {
			font-size: $font-size-mmlg;
			color: $color-neutral-60;
			margin-bottom: $padding-s;
		}
		.new-updates {
			min-width: 20px;
			height: 20px;
			color: $color-neutral-light;
			background-color: $color-red-50;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 4px;
		}
	}
	.location,
	.date {
		font-size: $font-size-lg;
		color: $color-neutral-70;
		margin: 0;
	}

	&-status-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&-label {
		display: flex;
		justify-content: center;
		padding: 5px 10px;
		font-size: $font-size-sm;
		font-weight: bold;
		width: fit-content;
		border-radius: 5px;

		&-inProgress {
			background-color: $color-yellow-70;
			color: $color-yellow-50;
		}

		&-resolved {
			background-color: $color-green-95;
			color: $color-green-50;
		}

		&-open {
			background-color: $color-red-95;
			color: $color-red-50;
		}

		&-dismissed {
			background-color: $color-red-95;
			color: $color-red-50;
		}
	}
}
