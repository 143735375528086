.exclamation-recurring-conflicts {
	text-align: center;

	.header {
		padding: 0 $default-spacing;
		font-weight: $font-weight-medium;
	}
	.conflicts-length {
		font-weight: $font-weight-medium;
		font-size: $font-size-lg;
		color: $color-error;
	}
	.conflict-dates-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		max-height: 150px;
		overflow: auto;

		.date-item {
			display: flex;
			align-items: center;
			font-size: $font-size-lg;

			p {
				margin: 0 0 0 5px;
			}
		}
	}
}
