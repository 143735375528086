.textarea {
	border: 1px solid $color-neutral-90;
	box-sizing: border-box;
	border-radius: 5px;
	width: 100%;
	max-width: 405px;
	padding: 5px;
	font-family: Roboto;
	font-size: $font-size-lg;
	resize: none;

	&:active,
	&:focus,
	&:hover {
		border-color: $color-neutral-dark;
		outline: none;
	}

	&-hint-text {
		font-size: 12px;
		opacity: 0.5;
		margin: 0;
		padding-left: 4px;
	}

	&-error {
		border: 1px solid $color-error;
		color: $color-error;

		&::placeholder {
			color: $color-error;
			font-family: Roboto;
		}
	}

	&-label {
		display: inline-block;
		margin-bottom: 15px;
	}
}
