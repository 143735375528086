.request-feature {
	height: 100%;
	display: flex;
	flex-flow: column;

	&-content {
		flex-grow: 2;
		overflow-y: scroll;
		padding: $spacing-m;

		.textarea-holder {
			display: flex;
			flex-flow: column;

			.textarea-label {
				@extend .section-heading;
			}
			textarea {
				height: 400px;
			}
		}
	}
}
