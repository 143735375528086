.button-add-results {
	width: fit-content;
	margin: $default-spacing;
	align-self: center;
}
.settings-privacy-wrapper {
	padding-bottom: $padding-sml;
}
.privacy-dropdown {
	.custom-option {
		.icon,
		.bold {
			margin-right: 5px;
		}
		span {
			font-size: 13px;
		}
	}

	.custom-option-label {
		display: flex;
		align-items: center;
		white-space: nowrap;

		.label {
			margin-left: $default-small-spacing;
			font-weight: bold;
		}

		.description {
			margin-left: 5px;
		}
	}
}

.privacy-add-colleagues-wrapper {
	.button-wrapper {
		width: 100%;
		display: flex;
		justify-content: center;
	}
}

.outlook {
	&-settings {
		padding-bottom: 50px;
	}
	&-option {
		display: flex;
		border-radius: 20px;
		padding: $default-spacing;
		align-items: center;
		justify-content: space-between;
		box-shadow: 2px 2px 5px 0px $color-neutral-95;

		&-heading {
			display: flex;
			align-items: center;
			font-weight: bold;

			.custom-icon {
				width: 40px;
				margin-right: $default-spacing;
			}
		}
	}
}
