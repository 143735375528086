@media all and (min-width: 900px) {
	html::before {
		content: '';
		background: url(../../image/cgi_solution.svg) no-repeat;
		pointer-events: none;
		background-size: 100% 100%;
		position: absolute;
		width: 120px;
		height: 120px;
		right: 60px;
		bottom: 30px;
	}
}

html {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	position: fixed;
	background: url(../../image/wallpaperBG.png) no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

	@media all and (min-width: $breakpoint-xxlg) and (min-height: $breakpoint-height-xlg) {
		body::before {
			content: '';
			background: url(../../image/phone_frame_no_notch.svg) no-repeat;
			background-size: 100% 100%;
			pointer-events: none;
			position: absolute;
			z-index: 10;
			width: 108%;
			height: 105%;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		body,
		#root {
			border-radius: 20px;
			-webkit-user-drag: none;
			-khtml-user-drag: none;
			-moz-user-drag: none;
			-o-user-drag: none;
			user-drag: none;
		}
	}

	body {
		position: relative;
		height: 100%;
		width: 100%;
		padding: 0;
		margin: 0;
		font-family: $body-font-family;
		max-width: $default-max-app-width;
		max-height: $default-max-app-height;
		box-shadow: 0px -4px 20px rgb(0 0 0 / 10%);
		background-color: $background-color-main;
		color: $color-text;

		#root {
			height: 100%;
			width: 100%;
			position: fixed;
			max-width: $default-max-app-width;
			max-height: $default-max-app-height;
			overflow: hidden;

			.page {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				overflow: hidden;
				position: relative;

				.page-content {
					display: flex;
					flex-direction: column;
					position: relative;
					height: calc(90% - 15px);
				}

				.page-bottom-menu {
					height: 10%;
				}

				.blurred {
					filter: blur(3px);
				}

				&-no-footer {
					.page-content {
						height: 100%;
					}
				}
			}

			.error-page {
				height: 100%;
				display: flex;
				justify-content: center;
				flex-flow: column;
				align-items: center;
				text-align: center;

				h3,
				h4 {
					width: 80%;
				}
				button {
					width: 60%;
					margin-top: $default-spacing;
				}
			}
		}

		.loading-page {
			text-align: center;
			display: flex;
			flex-flow: column;
			height: 100%;

			.logo {
				width: 120px;
				margin-bottom: $spacing-md;
			}

			.loading-logo-wrapper {
				height: 80%;
				display: flex;
				flex-flow: column;
				justify-content: center;
				align-items: center;
			}
		}

		.copyright-section {
			height: 20%;
			display: flex;
			flex-flow: column;
			justify-content: flex-end;
			align-items: center;
			margin-bottom: 40px;

			.cgi-logo-wrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: $spacing-s;

				p {
					font-weight: $font-weight-medium;
					font-size: $font-size-md;
					color: $color-neutral-70;
				}
			}

			.cgi-logo {
				width: 35px;
				margin-left: 5px;
			}
			p {
				margin: 0;
				font-size: $font-size-sm;
				color: $color-neutral-60;
			}
		}

		.fallback-bookings {
			padding: 10px 20px;
		}

		a,
		a:hover,
		a:click,
		a:visited {
			border: none;
			outline: none;
			text-decoration: none;
			color: inherit;
			-webkit-tap-highlight-color: white;
		}

		.placeholderForVirtualKeyboard {
			min-height: 450px;
		}
		.placeholderForVirtualKeyboard-hidden {
			height: 0;
		}
	}
}

.bottom-space-placeholder {
	width: 100%;
	min-height: 50px;
}

* {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
input,
input:before,
input:after,
textarea {
	-webkit-user-select: text !important;
	-khtml-user-select: text !important;
	-moz-user-select: text !important;
	-ms-user-select: text !important;
	user-select: text !important;
}

textarea::placeholder {
	font-family: $body-font-family;
	white-space: pre-line;
}
.dividing-hr {
	border: none;
	outline: none;
	height: 3px;
	background: $color-neutral-95;
	margin-top: $default-spacing;
}
.no-results {
	text-align: center;
	font-weight: $font-weight-bold;
	font-size: $font-size-lg;
}
