.meeting-summary {
	&-page {
		height: 100%;

		.button-wrapper {
			background-color: $color-neutral-light;
			position: absolute;
			bottom: 0;
			width: 100%;
			display: flex;
			justify-content: center;
			padding: $default-spacing 0;
			box-shadow: 0px -5px 10px $color-neutral-95;
			height: 5%;

			.btn-primary {
				width: 90%;
				text-transform: capitalize;
			}
		}
	}
	&-content {
		overflow-y: scroll;
		height: 80%;

		.status-description {
			margin: 0 $padding-s $padding-m $padding-s;
			padding: 10px $default-spacing;
			display: flex;
			align-items: center;
			font-size: $font-size-sm;
			margin-bottom: $padding-s;
			background-color: $color-neutral-90;

			.icon {
				font-size: $font-size-lg;
				margin-right: 10px;
			}
		}

		hr {
			border: none;
			outline: none;
			height: 3px;
			background: $color-neutral-95;
			margin-top: $default-spacing;
		}

		.section,
		.info-item {
			margin: $default-spacing;
			white-space: pre-wrap;
			word-break: break-all;
		}

		.date {
			white-space: normal;
		}
		.info-meeting-details {
			white-space: pre-wrap;
			word-break: break-all;
		}
		.section {
			font-size: $font-size-mlg;
			font-weight: bold;
			color: $color-main;
		}

		.features-wrapper {
			display: flex;
			overflow: scroll;
			padding-left: $default-spacing;
		}
	}
}

.visitor-list-wrapper {
	.attendee-item {
		margin: 0 $default-spacing;
		border-bottom: 1px solid $color-neutral-95;
		display: flex;
		justify-content: space-between;

		&-name {
			font-weight: bold;
		}

		&-attendance {
			border-radius: 5px;
			margin-top: auto;
			margin-bottom: auto;
			padding: 5px 10px;
			font-size: $font-size-sm;
			overflow-wrap: anywhere;
			color: $button-variant-negative-color;

			&-0 {
				background-color: $button-variant-primary-color-disabled;
			}

			&-1 {
				background-color: $color-success;
			}

			&-2 {
				background-color: $button-variant-negative-border;
			}
		}
	}

	.attendee-item-no-border {
		margin: 0 $default-spacing;
		&-name {
			font-weight: bold;
		}
		display: flex;
		justify-content: space-between;
	}

	.attendee-show-more {
		width: 100%;
		display: flex;
		justify-content: center;
		color: $color-main;
		align-items: center;
		font-weight: bold;
		margin: $default-spacing 0;

		.icon {
			margin-left: 5px;
			width: 15px;
			height: 15px;
		}
	}
}
