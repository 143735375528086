.favorites {
	&-section {
		margin: $default-spacing 0;
	}

	&-header {
		text-transform: uppercase;
		margin-left: $spacing-m;
	}
	&-header-wrapper {
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: space-between;
		font-size: $font-size-md;
		width: 100%;

		.btn-wrapper {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-right: $default-spacing;
		}

		.show-all {
			padding: 0px;
			height: auto;

			.icon {
				width: 18px;
				height: 18px;
			}
		}
	}

	&-card-holder {
		display: flex;
		overflow: auto;
		padding: 10px;
		overflow-y: auto;
	}

	&-card {
		height: 80px;
		width: 150px;
		display: flex;
		box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
		filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.04));
		border-radius: 10px;
		margin: 0 12px;
		align-items: center;

		&:first-child {
			margin-left: 0;
		}

		&-info {
			max-width: 60px;
			margin-right: 12px;
		}

		&-name {
			color: $color-red-60;
			font-weight: $font-weight-bold;
			font-size: $font-size-md;
			line-height: $line-height-md;
			margin-bottom: 5px;
			margin-top: 5px;

			@media all and (max-width: 340px) {
				font-size: $font-size-sm;
				line-height: $line-height-sm;
			}
		}

		&-office,
		&-level,
		&-name {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		&-office,
		&-level {
			font-weight: $font-weight-bold;
			font-size: $font-size-sm;
			line-height: $line-height-sm;
			margin-bottom: 5px;
			margin-top: 5px;
		}

		&-img-wrap {
			align-items: center;
			justify-content: center;
			display: flex;
			width: 55px;
			min-width: 55px;
			height: 57px;
			background: $color-neutral-95;
			border-radius: 10px;
			margin-left: 12px;
			margin-right: 12px;

			.favoritecard-icon {
				width: 35px;
				height: 35px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.custom-icon {
				width: 35px;
			}
		}
	}

	&-container {
		.no-favorites {
			&-item {
				box-sizing: border-box;
				background-color: $color-neutral-light;
				display: flex;
				border-radius: 10px;
				line-height: 14px;
				@include sm-box-shadow;
			}

			&-image {
				fill: $color-neutral-60;
			}

			&-heading {
				font-weight: 400;
				position: relative;
				font-size: $font-size-md;
			}

			&-image-wrapper {
				width: 55px;
				height: 57px;
				border-radius: 10px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 12px;
				background: $color-neutral-95;
			}

			&-message {
				text-align: left;
				@extend .font-xsm;
				max-width: 215px;
			}

			&-text-holder {
				align-items: flex-start;
				display: flex;
				width: calc(100% - 80px);
				padding: 12px 12px 12px 0;
				flex-direction: column;
			}
		}
	}
}
