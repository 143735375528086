.agenda-item-page {
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: column;

	&-content {
		flex-grow: 2;
		overflow-y: scroll;

		&-section {
			padding: 0 $spacing-m;

			.all-day-switcher-wrapper {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: $spacing-m 0;

				p {
					margin: 0;
				}
			}
		}
	}
}
