.aspen-types {
	display: flex;
	flex-direction: column;

	&-buttons {
		display: flex;
		overflow-y: auto;
		padding: 0 $spacing-m;
	}

	&-button {
		min-height: 116px;
		display: flex;
		align-items: center;
		flex-direction: column;
		box-sizing: border-box;
		border-radius: 9px;
		background: $color-neutral-95;
		padding-top: $default-spacing;
		width: 31%;
		min-width: 112px;
		margin-right: 10px;

		&-active {
			background: $color-main-90;
		}

		.custom-icon,
		.icon {
			width: 40px;
			height: 40px;
		}
	}

	&-header {
		@extend .booking-page-headline;
	}

	&-label {
		margin-bottom: $default-spacing;
		margin-top: 0;
		font-size: $font-size-lg;
		line-height: $line-height-lg;
		text-align: center;
		max-width: 75px;
		word-wrap: break-word;
	}
}
