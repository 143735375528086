.favorites-list-page {
	display: flex;
	flex-flow: column;
	height: 100%;

	.close-button {
		margin: 0;
		position: absolute;
		right: 15px;
		top: 10px;
	}

	.btn-back {
		border: 1px solid $color-neutral-95;
		border-radius: 50%;
		margin: 0;
		top: 15px;
		left: 15px;
		position: absolute;
		width: 32px;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;

		.icon {
			height: 14.05px;
			width: 8.33px;
		}
	}

	.main-heading {
		@extend .headline;
		text-transform: inherit;
		letter-spacing: 0;
		margin-top: $default-spacing;

		&:after {
			display: none;
		}
	}

	&-heading-wrapper {
		@include md-box-shadow;
		background: $color-neutral-light;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column;
		position: relative;
		flex: 0 1 auto;

		.date-time-display {
			color: $color-neutral-60;
		}
	}

	&-list-container {
		height: 100%;
		background-color: $color-neutral-95;
		flex: 1 1 auto;
		overflow-y: auto;
	}
}
