.recurring-booking-wrapper {
	border-top: 4px solid $color-neutral-95;
	padding: $padding-m $padding-s;
	background: $color-neutral-light;

	.title {
		color: $color-main;
		font-weight: $font-weight-bold;
		font-size: $font-size-xlg;
		margin: 0 0 $padding-s 0;
	}

	&-container {
		display: flex;
		flex-direction: column;
	}

	.options-wrapper {
		.option {
			width: 100%;
			display: flex;
			align-items: center;
			color: $color-heading;

			&-label {
				font-weight: bold;
			}

			&-select-daily {
				margin-bottom: $default-spacing;
			}

			&-recurring-interval {
				.day-interval-select {
					margin: 0 $default-spacing;

					div[class*='control'] {
						background: $color-neutral-95;
						min-height: 32px;
						border-color: $color-neutral-80;

						div[class*='ValueContainer'] {
							justify-content: center;
							position: relative;
							padding: 0;
						}

						div[class*='IndicatorsContainer'] {
							position: relative;
						}

						div[class*='indicatorContainer'] {
							padding: 0 5px;
						}

						div[class*='singleValue'] {
							color: $color-neutral-dark;
							margin: 0;
						}

						.icon {
							width: 15px;
						}
					}
				}
			}

			&-select-workdays {
				margin-top: $default-spacing;
				display: flex;
				align-items: center;
				width: 100%;

				#select-workdays {
					display: flex;
					align-items: center;
					justify-content: space-evenly;
					width: 70%;
					margin-left: $default-spacing;

					.workday {
						color: $color-neutral-dark;
						padding: 3px 8px;
						border-radius: 3px;
						box-shadow: inset 0px 2px 3px 1px $color-neutral-90;
					}

					.active {
						color: $color-neutral-light;
						-webkit-box-shadow: none;
						background-color: $color-main;
					}
				}
			}

			&-start-date {
				margin-top: $default-spacing;

				.common-date-time-picker-wrapper {
					width: 80%;
					margin-left: $default-spacing;
				}
			}

			&-end-date {
				margin-top: $default-spacing;

				.common-date-time-picker-wrapper {
					width: 80%;
					margin-left: $default-spacing;
				}
			}

			.option-text-after {
				color: $color-neutral-90;
			}
		}
	}

	.recurring-limit-info {
		font-size: $font-size-md;
		color: $color-heading;
		margin: 0 0 $padding-s 0;

		.purple-text {
			color: $color-main-60;
		}
	}

	.recurring-booking-check {
		margin-bottom: $padding-s;
		.input-checkbox-label {
			font-weight: $font-weight-normal;
			font-size: $font-size-lg;
			color: $color-heading;
		}
	}
}
