.edit-visitor-wrapper {
	position: absolute;
	bottom: 0;
	background-color: $background-color-main;
	width: 100%;
	height: 100%;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
	transition: visibility 0.3s, max-height 0.5s;

	.edit-visitor-header {
		padding-bottom: 10px;
		background-color: $background-color-main;
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;

		.header {
			text-align: center;

			@extend .font-xlg;

			@include horizontal-line-at-center;

			p {
				margin-bottom: 5px;
				font-size: 20px;
			}
		}
	}
	.edit-visitor-content {
		margin: 0 10px;
		height: calc(90% - 35px);
		overflow-y: scroll;
		background-color: $background-color-main;
		padding-bottom: 20px;
		box-sizing: border-box;

		.hidden {
			height: 0;
		}

		.primary-input {
			margin: 0;
		}
		.details-name {
			display: flex;
			justify-content: space-between;

			.last-name-input {
				margin-left: $default-spacing;
			}
		}

		.select-time-date-content {
			display: flex;
			flex-direction: column;

			@media (max-width: $breakpoint-sm) {
				display: initial;
			}

			.times-wrapper {
				display: flex;

				.time-wrapper {
					margin-right: $default-spacing;
				}
			}
		}

		.common-date-time-picker-wrapper {
			margin-bottom: 25px;
			position: relative;

			@media (max-width: $breakpoint-sm) {
				width: 300px;
			}

			.error {
				left: 5%;

				@media (max-width: $breakpoint-sm) {
					left: 0;
				}
			}
		}

		.personal-message-wrapper {
			margin-bottom: $default-spacing;
			align-items: center;
			width: 100%;

			.textarea {
				width: calc(
					100% - 6px
				); // -6px because the border is 1px and padding 2px on both sides = 6px. without this it will overflow outside the 100%width
				border-radius: 10px;
			}
			.textarea:focus-visible {
				outline: none;
			}
		}

		.buttons-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-bottom: 40px;

			.btn-delete,
			.btn-primary {
				width: 80%;
			}
		}
	}
}
