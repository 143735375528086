.office-hours-list {
	p {
		margin: 0;
	}
	.day-schedule {
		display: flex;
		margin: $spacing-m;
		justify-content: center;
		p {
			min-width: 120px;
			text-align: left;
		}

		.period-list {
			display: flex;
			flex-flow: column;
		}
		.period {
			font-weight: $font-weight-bold;
		}
	}
}
