.office-description {
	&-header {
		margin-bottom: 10px;
		@extend .heading;
		@include horizontal-line-left;
		font-weight: bold;
	}

	&-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $color-neutral-95;
		border-radius: 15px;
		padding-left: 10px;
		padding-right: 10px;
	}

	&-text {
		width: 100%;
		overflow-wrap: break-word;
	}
}
