.noCertificateForCheckin-page {
	background-color: $color-neutral-light;
	width: 100%;
	height: 100%;
	padding-bottom: 20px;

	.back {
		padding-left: 20px;
		padding-top: 10px;
		display: flex;
		align-items: center;
		margin: 0;
		text-transform: uppercase;
		font-size: 14px;
		letter-spacing: 1.5px;
		font-weight: 600;

		.icon {
			color: $color-main;
			padding-right: 5px;
			padding-left: 0;
			font-size: $font-size-sm;
		}
	}

	.checkInQuestionnaire-page-content {
		padding: $default-spacing;

		.checkInQuestionnaire-page-info {
			.bold-text {
				font-weight: bold;
			}
		}

		.hidden {
			display: none;
		}
		.btn-primary.button {
			background: $color-red-60;
			border-color: $color-red-60;
		}

		.btn-secondary.button {
			color: $color-red-60;
			border-color: $color-red-60;
		}
		.btn-secondary.button:hover {
			background: $color-red-60;
			color: $color-neutral-light;

			.icon {
				color: $color-neutral-light;
			}
		}

		.buttons-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			align-content: center;
			width: 100%;
			.button {
				margin: 5px 0;
				width: 70%;
				align-items: center;
				display: flex;
				justify-content: center;

				.icon {
					margin-right: 5px;
				}
			}
		}
	}
}
