.contact-info {
	&-header {
		margin-bottom: 10px;
		@extend .heading;
		@include horizontal-line-left;
	}

	&-item {
		box-shadow: 0 3px 8px rgb(0 0 0 / 10%);
		cursor: pointer;
		padding: $default-spacing;
		border-radius: 5px;
		display: flex;
		margin: 10px 0;
		align-items: center;
		text-decoration: none;

		&-data {
			font-size: $font-size-md;
			line-height: $line-height-md;
			overflow: hidden;
			text-overflow: ellipsis;

			p {
				margin: 0;
			}
		}

		&-icon-wrap {
			min-width: 50px;
			height: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			margin-right: $default-spacing;
		}
		.icon-purple-item {
			background-color: var(--color-main-80)
		}

		.icon-green-item {
			background-color: $color-green-95;
		}

		.icon-yellow-item {
			background-color: $color-yellow-90;
		}

		.icon-red-item {
			background-color: $color-red-90;
		}

		&-text {
			line-height: $line-height-lg;
		}

		&-heading {
			font-weight: $font-weight-bold;
		}

		&-mail,
		&-website,
		&-phone {
			text-decoration: none;
			color: var(--color-text);
		}
	}

	.accordion-wrapper {
		margin-top: 10px;
	}

	.accordion-item-title {
		font-size: $font-size-md;
		line-height: $line-height-md;
		display: flex;
		align-items: center;
		margin: 0;
	}

	.accordion-status-header {
		font-weight: bold;

		p {
			margin: 0;
		}

		.status {
			background-color: $color-neutral-95;
			margin-top: 5px;
			padding: 3px 10px;
			border-radius: 5px;
			width: fit-content;
			display: flex;
			justify-content: center;
			align-items: center;

			&-open {
				color: $color-green-50;
			}

			&-closed {
				color: $color-error;
			}
		}
	}

	.accordion-icon-wrapper {
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		margin-right: $default-spacing;
		background-color: $color-blue-10;
	}

	.accordion-content {
		width: 100%;
	}

	.reception-list {
		padding-left: 20px;
	}

	.reception-list-item {
		list-style: none;
		color: $color-neutral-80;
		line-height: $line-height-md;
	}

	.reception-list-text {
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		color: $color-neutral-60;
		padding-right: 5px;
	}
}
