.data-privacy {
	height: 100%;
	display: flex;
	flex-direction: column;

	&-title {
		color: var(--color-main);
		font-weight: $font-weight-bold;
		font-size: $font-size-mlg;
		margin-bottom: $spacing-m;
	}

	&-description {
		color: $color-neutral-80;
		font-size: $spacing-m;
		margin-bottom: $spacing-m;
	}

	&-switch-container {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&-description {
			font-size: $font-size-md;
		}
	}

	&-content {
		height: 100%;
		position: relative;

		&-container {
			padding: $padding-m $padding-s;
		}

		&-seperator {
			width: 100%;
			height: 8px;
			background-color: $color-neutral-95;
		}
	}

	&-hyperlink {
		color: var(--color-main);
		text-decoration: underline;
	}
}

.content-loading {
	z-index: 10;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(90, 89, 89, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;

	.css-1bik8n0 {
		border: 6px solid;
		border-color: $color-main;
		border-bottom-color: transparent;
	}
}
