@mixin gradient($direction, $list) {
	background: -webkit-linear-gradient($direction, $list);
	background: -moz-linear-gradient($direction, $list);
	background: -o-linear-gradient($direction, $list);
	background: linear-gradient($direction, $list);
}

@mixin grey-gradient {
	@include gradient(to bottom, $gray-gradient-list);
}

@mixin rose-to-violet-gradient {
	@include gradient(to right, $purple-gradient-list);
}

@mixin horizontal-line-left {
	&:after {
		content: '';
		display: block;
		width: 26px;
		height: 2px;
		@include gradient(to right, $purple-gradient-list);
	}
}

@mixin horizontal-line-at-center {
	&:after {
		content: '';
		display: block;
		width: 26px;
		height: 2px;
		@include gradient(to right, $purple-gradient-list);
		margin: 0 auto;
	}
}

@mixin sm-box-shadow {
	box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
}

@mixin md-box-shadow {
	box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
}

// Fonts includes
.font-xlg {
	font: normal $font-weight-black $font-size-xlg $body-font-family;
	line-height: $line-height-xlg;
	letter-spacing: $letter-spacing-xlg;
}

.font-lg {
	font: normal $font-weight-bold $font-size-lg $body-font-family;
	line-height: $line-height-lg;
}

.font-md {
	font: normal $font-weight-normal $font-size-xlg $body-font-family;
	line-height: $line-height-md;
}

.font-sm {
	font: normal $font-weight-light $font-size-sm $body-font-family;
	line-height: $line-height-sm;
}

.font-xsm {
	font: normal $font-weight-normal $font-size-xsm $body-font-family;
	line-height: $line-height-xsm;
	letter-spacing: $letter-spacing-md;
}

.show-all-button {
	font-size: $font-size-md;
	color: $color-main;
	font-weight: bold;
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-right: 0;

	.icon {
		width: 12px;
		margin-left: 5px;
	}

	p {
		margin: 0;
	}
}

.loading-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}
