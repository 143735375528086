.info-bottom-card {
	.info-header {
		padding: 5px 0;
		width: 100%;
		background-color: $color-bottom-entity-info-card;
		color: $color-secondary-text;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		height: 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		img {
			width: 25px;
			height: 25px;
			border: 3px;
			border-radius: 50%;
			border-width: 3px;
			border-color: $color-neutral-light;
			border-style: solid;
			margin-right: 10px;
		}
		.status,
		.name {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			font-size: $font-size-lg;
			font-weight: bold;
			margin-left: $default-spacing;

			.status {
				width: 13px;
				height: 13px;
				border-radius: 50%;
				border-width: 2px;
				border-style: solid;
				border-color: $color-neutral-light;
				margin: $default-spacing;
				@include sm-box-shadow;
			}

			.available {
				background-color: $color-status-free;
			}

			.booked {
				background-color: $color-status-fully-booked;
			}

			.blocked {
				background-color: $color-status-blocked;
			}

			.onlyCheckIns {
				background-color: $color-status-onlyCheckIns;
			}
		}

		.close-card {
			width: 50px;
			text-align: center;
			font-size: $font-size-lg;
			color: $color-secondary-text;
		}
	}
	.info-content {
		padding: 10px 0;
		.info {
			margin: 5px $default-spacing;
			display: flex;
			align-items: center;
			color: $color-text;
			font-weight: bold;

			span {
				font-size: $font-size-lg;
				color: $color-secondary-text;
				font-weight: initial;
				margin-left: 5px;
			}
		}
	}
}
