.profile-page {
	box-sizing: border-box;
	height: calc(100% - $default-spacing);
	overflow-y: auto;

	&-heading {
		background: $color-neutral-95;
		box-sizing: border-box;
		color: $color-neutral-70;
		display: flex;
		font-size: $font-size-md;
		line-height: $line-height-sm;
		padding: 10px 19px;
		text-transform: uppercase;
		width: 100%;
		font-weight: $font-weight-normal;
	}

	&-content {
		align-items: center;
		background-color: $background-color-main;
		display: flex;
		flex-direction: column;
		margin-top: 64px;
		width: 100%;
		padding-bottom: 40px;

		&-wrapper {
			align-items: center;
			display: flex;
			flex-direction: column;
			margin-bottom: 30px;
		}
	}

	&-photo {
		height: 92px;
		margin-bottom: 24px;
		width: 92px;

		img {
			border-radius: 50%;
			height: 100%;
			object-fit: contain;
			width: 100%;
		}
	}

	&-name {
		color: $color-main;
		font-size: $font-size-mlg;
		font-weight: $font-weight-bold;
		margin: 0 0 $padding-xs 0;
	}

	&-organization-name {
		color: $color-neutral-80;
		font-size: $font-size-lg;
		font-weight: $font-weight-normal;
		line-height: $line-height-md;
		margin-top: $spacing-xxs;
		text-align: center;
	}

	&-job-title {
		color: $color-neutral-60;
		font-size: $font-size-mmlg;
		font-weight: $font-weight-normal;
		line-height: $line-height-lg;
		text-align: center;
	}

	&-description {
		margin: 0 25px 20%;

		p {
			margin: $default-spacing 0 0;
			text-align: center;
		}
	}

	.profile-item {
		align-items: center;
		border-bottom: 1px solid $color-neutral-90;
		box-sizing: border-box;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		margin: 0 $default-spacing;
		padding: 10px $default-spacing;
		position: relative;
		width: 100%;

		&:first-of-type {
			border-top: 1px solid $color-neutral-90;
		}

		&-heading {
			font-size: $font-size-mmlg;
			font-weight: $font-weight-medium;
			line-height: $line-height-lgx;
			text-transform: initial;
		}

		&-icon {
			color: $color-main;
			display: flex;
			margin-right: 10px;
			width: 24px;

			&-arrow {
				margin-left: auto;
				color: $color-neutral-90;
			}
		}

		.new-notifications {
			align-items: center;
			background-color: $color-red-50;
			border-radius: 50%;
			color: $color-neutral-light;
			display: flex;
			font-size: $font-size-sm;
			height: 10px;
			justify-content: center;
			margin-left: $default-spacing;
			padding: 5px;
			text-align: center;
			width: 10px;
			&-minimum-size {
				font-size: 8px;
			}
		}
	}

	.icon-invalid-certificate {
		height: 20px;
		margin-left: 5px;
		position: absolute;
		top: 33%;
		width: 20px;
	}

	.close-button {
		align-items: center;
		display: flex;
		justify-content: flex-end;
		margin: $default-spacing 0 0 $default-spacing;
		width: auto;

		p {
			font-size: $font-size-md;
			font-weight: $font-weight-medium;
			letter-spacing: $letter-spacing-lg;
			margin: 0;
			text-transform: uppercase;
		}
	}
}
