.filter-modal-sheet-wrapper {
	z-index: 5 !important;
}

@media all and (min-width: $breakpoint-xxlg) and (min-height: $breakpoint-height-xlg) {
	.filter-modal-sheet-wrapper {
		border-radius: 20px;
	}
}

.filter-button-wrapper {
	flex-shrink: 0;
	background-color: $color-neutral-light;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 27px;
	width: fit-content;
	padding: 0 5px;
	align-self: center;
	box-shadow: 2px 3px 5px -2px $color-neutral-dark;
	height: 40px;
	pointer-events: auto;

	&-entity-list {
		margin-top: 10px;
	}
	.icon {
		margin-right: 10px;
	}

	.requirement-filter-header-text {
		margin: 0 5px 0 0;
		font-size: $font-size-md;
		letter-spacing: $letter-spacing-lg;
		font-weight: $font-weight-medium;
	}
}

.filter-modal-sheet {
	&-wrapper {
		position: absolute !important;

		.date-time-picker-wrapper {
			border-top: 4px solid $color-neutral-95;
			padding: $padding-m $padding-s;
			margin-bottom: 0;

			.date-label {
				color: var(--color-main);
				font-weight: $font-weight-bold;
				font-size: $font-size-mlg;
				margin-bottom: $spacing-m;
			}
		}

		.features .features-header-wrapper {
			color: var(--color-main);
			font-weight: $font-weight-bold;
			font-size: $font-size-mlg;
		}

		.select-area .select-area-header-wrapper .select-area-header {
			color: var(--color-main);
			font-weight: $font-weight-bold;
			font-size: $font-size-mlg;
		}

		.features,
		.desk-booking-wrapper {
			padding: $padding-m $padding-s;
			border-top: 4px solid $color-neutral-95;
			box-sizing: border-box;
			margin: 0;
		}

		.booking-page-content-input .primary-input-label {
			color: var(--color-main);
			font-weight: $font-weight-bold;
			font-size: $font-size-mlg;
		}

		.desk-booking-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			.desk-booking-sub-container {
				display: flex;
				flex-direction: column;
				justify-content: center;
				gap: 16px;

				.title {
					margin: 0;
					@extend .booking-page-headline;
				}

				.sub-title {
					color: $color-heading;
					font-weight: $font-weight-normal;
					font-size: $font-size-lg;
					margin: 0;
				}
			}
		}

		.colleague-item-wrapper {
			margin: 10px 0;
		}

		.colleague-item-wrapper:last-of-type {
			padding-bottom: 0;
			padding-top: 10px;
			border-top: 1px solid $color-neutral-95;
		}

		.colleague-item-wrapper .photo-wrapper img {
			width: 40px;
			height: 40px;
		}

		.asset-types-header {
			color: var(--color-main);
			font-weight: $font-weight-bold;
			font-size: 20px;
			margin-bottom: $spacing-m;
		}

		.hr-colleague-item-bottom {
			margin: 0;
		}

		.desk-booking-empty-wrapper {
			align-items: center;
			border: 2px dashed $color-neutral-95;
			display: flex;
			flex-flow: column;
			margin-top: 10px;
			padding-top: 15px;

			.add-colleagues-button {
				align-items: center;
				background-color: $color-neutral-80;
				color: $color-neutral-light;
				display: flex;
				font-size: $font-size-xlg;
				height: 30px;
				justify-content: center;
				width: 30px;
			}
		}

		.react-modal-sheet-content {
			display: flex;
			flex-flow: column;
			overflow: hidden !important;

			.booking-page {
				height: 96%;
			}

			.booking-page-content {
				padding-bottom: $default-spacing;
			}

			.button-fixed-at-bottom {
				height: 100px;
			}
		}
	}

	&-header {
		display: flex;
		position: relative;
		justify-content: center;
		align-items: center;
		padding-bottom: 10px;
		border-bottom: 1px solid $color-neutral-95;

		.header {
			color: $color-main;
			font-size: $font-size-mlg;
			font-weight: $font-weight-bold;
		}

		p {
			margin: 0;
		}

		.close {
			position: absolute;
			right: $default-spacing;
		}
	}
}
