.input-checkbox {
	display: none; // take default checkboxes out of document flow, hide it

	& + .input-checkbox-label {
		display: inline-flex;
		align-items: center;
		position: relative;
		cursor: pointer;
		padding: 0;
		@extend .font-sm;
	}

	// Box
	& + .input-checkbox-label:before {
		content: '';
		margin-right: 8px;
		display: inline-block;
		vertical-align: text-top;
		min-width: 20px;
		min-height: 20px;
		background: $color-checkbox-background;
		border: 1px solid $color-checkbox-border;
		border-radius: 2px;
	}

	// Box hover, box focus
	&:hover + .input-checkbox-label:before,
	&:focus + .input-checkbox-label:before {
		border-color: $color-checkbox-focus;
	}

	// Box checked
	&:checked + .input-checkbox-label:before {
		background: $color-checkbox;
		border: 1px solid $color-checkbox;
	}

	// Checkmark
	&:checked + .input-checkbox-label:after {
		content: '';
		position: absolute;
		left: 6px;
		background: $color-checkbox-background;
		width: 2px;
		height: 2px;
		box-shadow: 2px 0 0 $color-checkbox-background, 4px 0 0 $color-checkbox-background, 4px -2px 0 $color-checkbox-background,
			4px -4px 0 $color-checkbox-background, 4px -6px 0 $color-checkbox-background, 4px -8px 0 $color-checkbox-background;
		transform: rotate(45deg);
	}

	// Disabled state label
	&:disabled + .input-checkbox-label {
		color: $color-checkbox-disabled;
		cursor: auto;
		pointer-events: none;
	}

	// Disabled box
	&:disabled + .input-checkbox-label:before {
		box-shadow: none;
		background: $color-checkbox-disabled;
		border-color: $color-checkbox-disabled;
	}

	// Disabled box, but checkbox is checked
	&:disabled:checked + .input-checkbox-label:before {
		background: $color-checkbox-checked-disabled;
	}
}
