.map-view-container {
	.map-view-overlay {
		position: absolute;
		width: 100%;
		.overlay-header {
			display: flex;
			justify-content: flex-end;
			position: relative;

			.search-wrapper {
				display: flex;
				flex-flow: column;
				width: 100%;

				.search-header {
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 100%;
					margin-top: 10px;

					.search-input-wrapper {
						margin: 5px;
						width: 80%;
						transition: 0.25s;
					}

					.full-width {
						width: 100%;
					}

					.close-button {
						min-width: 48px;
						height: 48px;
						background-color: $color-main;
						border-radius: 50%;
						justify-content: center;
						align-items: center;
						margin: 5px;
						margin-right: $default-spacing;

						.icon {
							width: 20px;
							height: 20px;
						}
					}
				}

				.search-input {
					padding-left: 48px;
					padding-right: 40px;
					box-sizing: border-box;
					box-shadow: 0px 0px 4px $color-neutral-90;
					border-width: 1px;
					border-radius: 27px;
					border-color: $color-neutral-80;
					height: 48px;
					left: 16px;
					margin-right: 32px;

					&-wrapper {
						position: relative;
						display: flex;
						align-items: center;

						.delete-icon {
							position: absolute;
							right: 30px;
							display: flex;
							justify-content: center;
							align-items: center;
							background: $color-neutral-dark;
							border-radius: 50%;
							width: 20px;
							height: 20px;
							color: $color-neutral-light;
							top: $default-spacing;
						}
					}

					&-icon {
						position: absolute;
						z-index: 1;
						margin-left: 30px;
						width: 24px;
						height: 24px;
						fill: $color-neutral-95;
					}

					&-back-icon {
						position: absolute;
						z-index: 1;
						margin-left: 35px;
						padding: 5px 0;
						width: 20px;

						.icon {
							width: 10px;
						}
					}
				}

				.chips-overview {
					display: flex;
					overflow-x: scroll;
					padding: 0 $default-spacing $default-spacing $default-spacing;

					.icon-active {
						width: 20px;
						height: 20px;
						margin-left: 5px;
						color: $color-main;
					}
				}
			}
		}
	}

	.showOverlay {
		z-index: 6;
	}
	.searchPageOpen {
		background-color: $color-neutral-light;
		display: flex;
		flex-flow: column;
		height: 100%;
		z-index: 3;
	}
}
