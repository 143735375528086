.entity-details {
	display: flex;
	flex-flow: column;
	height: 100%;

	&-content {
		padding: $spacing-m;
		flex-grow: 2;
		overflow-y: scroll;
	}
}

.image-carousel {
	.image {
		width: 100%;
	}

	.splide__pagination {
		.is-active {
			background: $color-neutral-60;
		}
	}
}
