.settings-item {
	box-sizing: border-box;

	&-details {
		font-size: $font-size-md;
		margin-bottom: 10px;
		padding-right: 20px;
	}

	&-heading {
		font-weight: $font-weight-bold;
		margin-left: 30px;
		color: initial;

		@media all and (max-width: 375px) {
			margin-left: 20px;
		}
	}

	.privacy {
		border-left: 10px solid $color-main;
	}

	&-card {
		box-shadow: 0 3px 8px rgb(0 0 0 / 10%);
		cursor: pointer;
		padding: 10px 15px;
		border-radius: 5px;
		display: flex;
		margin: 10px 5px;
		align-items: center;
		justify-content: space-between;
		text-decoration: none;

		&-text {
			display: flex;
			align-items: center;
		}
	}

	&-left-icon {
		font-size: 24px;
		max-width: 30px;
		min-width: 30px;
	}

	&-link {
		.settings-item-heading {
			margin-right: auto;
		}
	}

	&-wrapper {
		@include md-box-shadow;
		border-left: 10px solid $color-main;
		padding: 10px $default-spacing;
		border-radius: 5px;
		position: relative;
		margin: 10px $default-spacing;
	}

	.warning-icon {
		font-size: $font-size-xlg;
		margin-right: $default-spacing;
	}
}
