.entity-list-wrapper {
	display: flex;
	flex-flow: column;
	flex: 2;
	overflow: auto;

	.entity-list-header-wrapper {
		display: flex;
		justify-content: space-between;
		background-color: $color-neutral-light;
		flex: 0 1 auto;
		padding-bottom: $padding-xs;
		padding-left: $default-spacing;
		max-width: $default-max-app-width;

		.selected-area-name {
			font-weight: $font-weight-semibold;
			font-size: $font-size-mmlg;
			line-height: $line-height-lg;
			color: $color-neutral-dark;
			display: inline-flex;
			align-items: center;

			.chevron-right {
				height: 14px;
				width: 8px;
				color: $color-neutral-dark;
				padding: 2px 5px;
			}
		}

		.entity-list-header {
			padding: 3px 20px 3px $default-spacing;
			@include horizontal-line-left;

			.header {
				font-weight: $font-weight-bold;
				font-size: $font-size-lg;
			}

			.available-entities {
				color: $color-secondary-text;
				display: inline-block;
				font-size: $font-size-sm;
				margin-left: 3px;
				font-weight: $font-weight-light;
			}
		}

		.entity-list-views {
			margin-right: $default-spacing;
			display: flex;
			height: 40px;
			justify-content: space-evenly;
			align-items: center;
			font-size: 14px;
			background-color: $color-main-90;
			padding: 1px 5px;
			border-radius: 5px;
			margin-left: auto;

			.map-icon,
			.list-icon {
				width: 32px;
				height: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				box-sizing: border-box;

				.custom-icon {
					width: 24px;
					height: 24px;
				}
			}

			.icon,
			.active {
				padding: 3px;
				width: 15px;
				height: 15px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 5px;
			}

			.active {
				background-color: $color-neutral-light;
				width: 32px;
				height: 32px;
			}
		}
	}

	.disabled {
		display: none;
	}

	.entity-list-container {
		width: 100%;
		box-sizing: border-box;
		display: flex;
		flex-flow: column;
		padding: 0 $default-spacing $default-spacing;
		margin-bottom: $spacing-m;
		margin-top: 10px; //size of header with display mode switcher ( list/ map )
		position: relative;

		.results {
			&-title {
				text-align: center;
				font-size: $font-size-mlg;
				font-weight: $font-weight-bold;
			}
			&-info {
				text-align: center;
				margin: 0;
			}
		}
	}

	.entity-list-areaname {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: $font-size-mlg;
		font-weight: $font-weight-medium;
		line-height: $line-height-lg;
		color: $color-neutral-dark;
		margin-top: $padding-ml;

		&:first-of-type {
			margin-top: 0;
		}
		.results {
			font-size: $font-size-lg;
			line-height: $line-height-md;
			color: $color-neutral-60;
			font-weight: $font-weight-normal;
		}
	}

	.entity-map-container {
		height: 100%;
		width: 100%;
		flex: 1 1 auto;
		overflow: hidden;
	}

	.entity-list-img-wrap {
		text-align: center;

		img {
			width: 80%;
		}
	}

	.entity-list-message {
		margin: 30px $default-spacing $default-spacing;
		text-align: center;

		p {
			margin: 0;
			margin-bottom: 10px;
			font-size: $font-size-md;
			font-weight: $font-weight-black;
		}
	}

	.entity-all-areas {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex: 1;
		margin: 10px;

		&-content {
			display: flex;
			flex-direction: column;
			flex: 1;
			justify-content: center;
			align-items: center;
			text-align: center;
			gap: $spacing-s;
			padding: 0 $spacing-xxlg;

			&-header {
				font-size: $font-size-mmlg;
				font-weight: $font-weight-bold;
			}

			&-icon {
				display: inline;
				vertical-align: middle;
				margin-left: 3px;
			}
		}
	}
}
