.repeat-booking-page {
	height: 100%;
	display: flex;
	flex-flow: column;

	.repeat-booking-header {
		background-color: $color-neutral-light;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.repeat-booking-content {
		display: flex;
		flex-flow: column;
		margin-top: 20px;
		overflow: scroll;
		flex-grow: 2;

		.meeting-name {
			padding: 24px 16px;
			border-top: 4px solid $color-neutral-95;
		}

		.title,
		.primary-input-label {
			color: $color-main;
			font-weight: $font-weight-bold;
			font-size: $font-size-mlg;
			margin: 0 0 $padding-s 0;
		}

		.booking-information {
			padding: 0 $padding-s $padding-s $padding-s;
			display: flex;
			flex-flow: column;
			background: white;

			.booking-page-content {
				margin-bottom: 100px;
				padding-bottom: $default-spacing;
			}

			.entity-information {
				display: flex;
				flex-direction: column;
				gap: 12px;

				p {
					margin: 0;
					margin-bottom: 5px;
				}
				.info-entity {
					font-weight: $font-weight-bold;
					align-self: center;
					width: 100%;
					color: $color-heading;

					span {
						font-weight: normal;
						color: $color-heading;
					}
				}

				.entity-features {
					display: flex;
					flex-wrap: wrap;
				}

				.features-content {
					display: flex;
					margin: 5px 0px;
					padding: 5px 0px;
					max-height: 190px;
					overflow-x: scroll;
					color: $color-heading;
				}
			}
		}

		.select-date-time-wrapper {
			border-top: 4px solid $color-neutral-95;
			padding-top: 24px;

			.common-date-time-picker-wrapper {
				.date-wrapper {
					.desk-date {
						max-width: 100%;
					}
				}
				.error {
					position: initial;
					p {
						text-align: left;
					}
				}
				.times-wrapper {
					display: flex;
					justify-content: space-between;
					margin-top: $default-spacing;

					.time-wrapper {
						width: 45%;
					}
				}
			}
		}

		.buttons-wrapper {
			display: flex;
			justify-content: center;
			margin-top: 30px;

			.button {
				padding: 10px 50px;
			}
		}
	}
}
