.app-technical-problem {
	&-wrapper {
		display: flex;
		flex-flow: column;
		height: 100%;
		justify-content: space-between;

		.take-photo,
		.upload-photo {
			background: $color-red-60;
			border-color: $color-red-60;

			&-icon {
				color: $color-neutral-light;
				height: 14px;
				padding: 0;
				margin: 0;
				margin-right: 10px;
			}
		}
	}

	&-content {
		display: flex;
		flex-flow: column;
		overflow: scroll;
		margin: 0 $default-spacing;
		flex-grow: 2;

		.upload-photo.hidden {
			display: none;
		}

		.hidden {
			height: 0;
		}

		.buttons-wrapper {
			margin-bottom: $default-spacing;
		}
	}

	&-label {
		display: flex;
		margin-bottom: $default-spacing;
		align-self: flex-start;
	}

	&-hint {
		font-size: $font-size-md;
		margin: 0;
		opacity: 0.5;
	}

	&-field {
		margin: $default-spacing 0;

		.report-problem-close-icon {
			font-size: 30px;
			margin-left: 10px;
		}

		.report-problem-images-list {
			border-radius: 10px;
			background: $color-neutral-95;
			padding: 16px;
			max-width: 90%;
			margin: 24px auto;
			box-sizing: border-box;
		}

		.report-problem-image-item {
			display: flex;
			align-items: center;
		}

		.report-problem-image-name {
			max-width: 90%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.report-problem-add-more {
			display: inline-block;
			cursor: pointer;

			.icon {
				margin-right: 5px;
				vertical-align: middle;
			}
		}

		.report-problem-quantity {
			color: $color-neutral-60;
			font-size: $font-size-sm;
			border-bottom: 1px solid $color-neutral-60;
			padding-bottom: $default-spacing;
		}

		.error .primary-input-error-message {
			font-size: 13px;
		}
	}
}
