.favorites-booking-page {
	overflow: auto;
	height: 100%;
	display: flex;
	flex-flow: column;

	.booking-page-switcher {
		box-shadow: 0px 20px 20px -10px rgba(0, 0, 0, 0.12);
		padding-bottom: $spacing-s;
	}
	.close-button {
		margin: 0;
		position: absolute;
		right: 15px;
		top: 10px;
	}

	.main-heading {
		@extend .headline;
		text-transform: inherit;
		letter-spacing: 0;
		margin-top: $default-spacing;

		&:after {
			display: none;
		}
	}

	&-heading-wrapper {
		@include md-box-shadow;
		background: $color-neutral-light;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
	}

	&-info-wrapper {
		display: flex;
		align-items: center;
		flex-flow: column;
		padding: $default-spacing;

		.info-icon {
			background-color: $color-red-95;
			padding: $default-spacing;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.info-text {
			text-align: center;
			color: $color-neutral-60;
		}
	}
	&-date-time-picker-wrapper,
	.title {
		padding: 0 $spacing-m;

		.service-date-picker {
			width: 100%;
			margin: 0;
			height: 20px;
		}
	}

	&-button-container {
		background: $color-neutral-light;
		position: absolute;
		bottom: 0;
		height: 92px;
		filter: drop-shadow(4px 0 24px rgba(0, 0, 0, 0.12));
		width: 100%;
		align-items: center;
		justify-content: center;
		display: flex;
		max-width: 450px;
		left: 0;
		z-index: 10;

		.btn-primary {
			width: 290px;
		}
	}
}
