.user-review-card {
	.user-review-content {
		.header {
			width: 100%;
			text-align: center;
			font-weight: $font-weight-bold;
		}

		.stars-wrapper {
			text-align: center;
			font-size: $font-size-xlg;
			display: flex;
			justify-content: center;
		}

		.star-item {
			display: flex;
			flex-direction: column;
		}

		.hidden-star {
			display: none;
		}

		.upper-layer-star {
			position: absolute;
		}

		.feedback-input {
			margin: 30px;
			textarea {
				resize: vertical;
			}
			::-webkit-input-placeholder {
				text-align: center;
			}
			:-moz-placeholder {
				/* Firefox 18- */
				text-align: center;
			}
			::-moz-placeholder {
				/* Firefox 19+ */
				text-align: center;
			}
			:-ms-input-placeholder {
				text-align: center;
			}
		}
	}

	.followup-allowed-check,
	.email-wrapper {
		padding: 0 30px;

		input::placeholder {
			font-size: $font-size-sm;
		}
	}

	.buttons-wrapper {
		text-align: center !important;
		padding: $default-spacing 10% !important;

		.no-rating {
			text-align: left;
			margin: 0;
			color: $color-error;
			font-size: $font-size-md;
		}
		.button {
			width: 100%;
			margin: 5px 0;
		}
	}

	.hidden {
		height: 0;
	}
}
