.features {
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;

	&-header-wrapper {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

        .show-all {
            padding: 0 0 $default-spacing 0;

            .icon {
                width: 18px;
                height: 18px;
            }
        }
	}

	&-header {
		@extend .booking-page-headline;
	}

    &-holder {
        display: flex;
        flex-shrink: 0;
        width: 100%;
        overflow-x: scroll;
        align-items: center;
        align-content: space-evenly;
        box-sizing: border-box;
    }
}
