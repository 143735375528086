.all-visitors-page {
	height: 100%;
	display: flex;
	flex-flow: column;

	&-header {
		z-index: 1;
		height: 10%;

		.secondary-header-wrapper {
			height: 100%;
		}
	}

	&-content {
		background-color: $color-neutral-95;
		height: 80%;
		display: flex;
		flex-direction: column;

		&-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: $spacing-xlg $spacing-m 0 $spacing-m;

			p {
				margin: 0;
				font-weight: bold;
				font-size: $font-size-mmlg;
			}

			.add-new-visitor {
				background-color: $color-main;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 40px;
				height: 40px;

				.icon {
					width: 20px;
					height: 20px;
				}
			}
		}

		&-container {
			overflow-y: auto;
		}

		.visitor-item {
			display: flex;
			justify-content: space-between;
			border-radius: 12px;
			background-color: $color-neutral-light;
			margin: $spacing-m;

			&-content {
				padding: $spacing-m;
				display: flex;
				flex-flow: column;
				justify-content: space-between;
				height: 90px;

				p {
					margin: 0;
				}

				.name {
					font-size: $font-size-mmlg;
					font-weight: $font-weight-bold;
					word-break: break-all;
				}

				.company {
					font-size: $font-size-lg;
					font-weight: $font-weight-medium;
					color: $color-main;
				}

				.email,
				.phone {
					font-weight: $font-weight-normal;
					color: $color-heading;
				}
			}

			&-action-buttons {
				padding: $spacing-m;
				display: flex;
				flex-flow: column;
				justify-content: space-between;
				height: 90px;

				.icon {
					margin-right: $default-spacing;
				}
			}
		}
	}
	.button-wrapper {
		height: 10%;
		text-transform: capitalize;
	}
}
