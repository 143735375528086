.office-first-aiders {
	&-wrapper {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		height: 80%;
		overflow-y: scroll;
	}
	&-container {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		background-color: $color-neutral-light;
		box-shadow: 0px 0px 10px -5px black;
		box-sizing: border-box;
		padding: 1rem;
		margin-top: 0.5rem;
		margin-bottom:0.5rem;
		gap: 1rem;

		&-desks-and-floors {
			flex: 30% 1 0;
			border-radius: 10px;
			color: $color-red-30;
			background-color: $color-red-90;
			padding: 1rem;
			font-weight: bold;
			p {
				padding: 0;
				margin: 0;
				font-size: medium;
			}
		}
		&-descriptions {
			flex: 30% 0 0;
			p {
				padding: 0;
				margin: 0;
				color: $color-neutral-70;
				font-size: medium;
				font-weight: bold;
			}
			p:first-child {
				font-weight: bolder;
				font-size: large;
				color: unset;
			}
			.certificate {
				font-weight: normal;
			}
		}
		&-icon-img {
			flex: 10% 1;
			width: 43px;
			height: 43px;
		}
	}

	&-text {
		width: 100%;
		overflow-wrap: break-word;
	}
}
.office-first-aiders-header {
	margin-bottom: 10px;
	@extend .heading;
	@include horizontal-line-left;
	font-weight: bold;
}
