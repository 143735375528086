.qr-reader-wrapper {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: $color-neutral-light;
	background-position: center;
	background-attachment: fixed;
	color: $color-neutral-light;
	font-size: 18px;
	text-align: center;
	position: absolute;
	z-index: 3;

	.button-wrapper {
		width: 100%;
		margin: 0px 16px;
		display: flex;
		justify-content: center;
	}

	&-action {
		font-size: $font-size-xlg;
		font-weight: $font-weight-bold;
		display: flex;
		justify-content: center;
		color: $color-main;
		margin-bottom: $spacing-m;

		p {
			margin: 0;
		}

		.word-placeholder {
			border-bottom: 2px solid $color-main;
			min-width: 100px;
			text-align: center;
			margin-left: 5px;
		}
	}

	.info-icons-wrapper {
		margin-top: $default-spacing;
		margin-bottom: 15%;
		display: flex;
		justify-content: center;
		align-items: center;

		.icon-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: 0 $default-spacing;

			.icon {
				font-size: 40px;
			}

			p {
				margin: 0;
				font-size: $font-size-sm;
			}
		}
	}
}

.desk-qr-reader-button-wrapper {
	margin: 40px 0;
}
