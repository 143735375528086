.day-selection-wrapper {
	.month-selection {
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;
		border-bottom: 1px $color-neutral-95 solid;
		padding: 0 $default-spacing;
		margin-bottom: 5px;
		color: $color-main;

		.selected-day {
			color: $color-neutral-60;
			margin: 0;
			margin-bottom: $spacing-m;
			margin-top: -$padding-m;
		}

		.selected-month {
			font-weight: bold;
			text-align: center;
			width: 200px;
			margin: 0 0 $default-spacing 0;
		}
	}

	.day-selection {
		display: flex;
		justify-content: space-between;

		.action-icon {
			display: flex;
			align-items: center;
			margin: 0 5px;

			.icon {
				width: 20px;
				height: 20px;
			}
		}
	}
}

.day-item-wrapper {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: flex-start;
	padding: 10px;
	border-radius: 10px;

	.day-header {
		font-weight: bold;
		font-size: $font-size-lg;
	}

	.day-abbreviation {
		font-size: $font-size-sm;
	}

	p {
		margin: 0;
	}
}

.today {
	background-color: $color-main;
	color: $color-neutral-light;

	.day-dot {
		background-color: $color-neutral-light;
	}
}

.past {
	background-color: $color-neutral-95;
}

.future {
	background-color: $color-main-80;
}

.day-dot {
	border-radius: 50%;
	width: 5px;
	height: 5px;
	background-color: $color-main;
}
