.report-sick-page {
	height: calc(100% - 15px);
	box-sizing: border-box;
	overflow: auto;

	.common-date-time-picker-wrapper {
		width: 100%;

		.desk-date {
			max-width: 100%;
		}
	}

	.report-sick-question {
		font-weight: $font-weight-bold;
		margin-bottom: 20px;
	}

	.report-sick-intro {
		text-align: justify;
	}

	&-content {
		width: 100%;
		background-color: $background-color-main;
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		padding: $default-spacing 20px 0;
		box-sizing: border-box;
		margin-top: 20px;

		&-wrapper {
			margin-bottom: 30px;
			align-items: center;
			display: flex;
			flex-direction: column;
		}
	}

	.close-button {
		display: flex;
		align-items: center;
		margin: 0 0 0 auto;

		p {
			text-transform: uppercase;
			font-size: 14px;
			letter-spacing: 1.5px;
			font-weight: 600;
		}
	}

	.report-sick-intro {
		line-height: 20px;
		font-size: $font-size-lg;
		text-align: justify;
	}

	.back {
		display: flex;
		align-items: center;
		margin: 0;
		text-transform: uppercase;
		font-size: 14px;
		letter-spacing: 1.5px;
		font-weight: 600;

		.icon {
			color: $color-main;
			padding-right: 5px;
			padding-left: 0;
			font-size: $font-size-sm;
		}
	}

	.report-sick-button {
		width: 205px;
	}

	.buttons-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		align-content: center;
		width: 100%;
		margin-top: 30px;

		.button {
			margin: 5px 0;
			width: 70%;
			align-items: center;
			display: flex;
			justify-content: center;
		}
	}
}
