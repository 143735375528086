.own-assets-page {
	height: 100%;
	display: flex;
	flex-flow: column;
	&-content {
		flex-grow: 2;
		overflow-x: scroll;
		&-intro-info {
			margin: $spacing-m;
			font-size: $font-size-md;
		}

		&-table {
			width: 100%;
			border-collapse: collapse;
			thead {
				background-color: $color-neutral-90;
				text-align: left;
				th {
					padding: $spacing-xs $spacing-m;
				}
			}

			tr {
				border-bottom: 1px solid $color-neutral-95;
				.status-label {
					display: flex;
					justify-content: center;
					padding: $spacing-xxs $spacing-xs;
					font-size: $font-size-md;
					font-weight: bold;
					width: fit-content;
					border-radius: 4px;

					p {
						margin: 0;
					}
				}

				.status-label-shared {
					background-color: $color-green-80;
					color: $color-green-50;
				}

				.status-label-notShared {
					background-color: $color-yellow-80;
					color: $color-yellow-50;
				}

				td {
					padding: $spacing-m;
				}
				.table-cell-asset {
					max-width: 100px;
				}
				.table-cell-status {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}
		}
	}
}
