.session-details-page {
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	position: relative;

	&-content {
		margin: 0 $spacing-lg;
		margin-bottom: 50px;
		p {
			margin: 0;
		}
		.session {
			&-name {
				font-size: $font-size-mlg;
				font-weight: $font-weight-bold;
				color: $color-main;
				margin-bottom: $spacing-s;
			}

			&-location {
				font-size: $font-size-lg;
				font-weight: $font-weight-semibold;
				color: $color-neutral-70;
				margin-bottom: $spacing-xxs;
			}

			&-time {
				font-size: $font-size-lg;
				font-weight: $font-weight-normal;
				color: $color-neutral-70;
			}
			&-speaker-header {
				font-size: $font-size-lg;
				font-weight: $font-weight-bold;
				margin: $spacing-m 0;
			}
		}

		.event-session-status-label {
			margin-top: $spacing-md;
			margin-bottom: $spacing-lg;
		}

		.session-description {
			white-space: pre-line;
		}

		.session-gala-menu {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: $spacing-lg;
			text-align: center;
			text-decoration: none;
			color: $color-main;
			svg {
				height: 80px;
				width: 80px;
				padding-bottom: $spacing-s;
				cursor: pointer;
			}
		}

		.speaker-list {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: $spacing-m;

			.speaker-item {
				display: flex;
				flex-flow: column;
				align-items: center;
				cursor: pointer;
				img {
					width: 120px;
					height: 120px;
					border-radius: 50%;
				}

				.name {
					margin: 0;
					margin-top: 8px;
					color: $color-main;
					font-size: $font-size-m;
					font-weight: $font-weight-medium;
					max-width: 60px;
					text-align: center;
				}
			}
		}
	}
}
