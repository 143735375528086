.new-visitor-meeting {
	height: 100%;

	&-wrapper {
		background-color: $background-color-main;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;

		.button-wrapper {
			background-color: $color-neutral-light;
			position: absolute;
			bottom: 0;
			width: 100%;
			display: flex;
			justify-content: center;
			padding: $default-spacing 0;
			box-shadow: 0px -5px 10px $color-neutral-95;
			height: 5%;
			min-height: 40px;

			.btn-primary {
				width: 90%;
				text-transform: capitalize;
			}
		}
	}

	&-content {
		overflow-y: auto;
		height: 80%;
		display: flex;
		flex-flow: column;

		.content-section {
			margin: 0 $spacing-m;
		}

		.new-meeting-input {
			margin-top: $spacing-xlg;
			margin-bottom: $spacing-lg;
		}

		.date-time-input {
			margin-bottom: $spacing-lg;

			.date-time-picker-wrapper {
				margin: 0;
			}
		}

		.meeting-details-input,
		.room-booking {
			margin-top: $spacing-xlg;
			margin-bottom: $spacing-xlg;
		}

		.room-booking-info {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.primary-input-label,
		.input::placeholder {
			font-size: $font-size-md;
		}

		.add-visitors-section {
			.bold {
				font-weight: bold;
			}

			.add-more-button {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $color-main;
				font-weight: bold;

				.plus-sign {
					margin-right: 5px;
					width: 20px;
					height: 20px;
					font-size: $font-size-lg;
					border: 2px solid $color-main;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}

			.visitor-item-wrapper {
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px $color-neutral-95 solid;

				.info-content {
					p {
						margin: 5px 0;
					}

					.name {
						font-weight: $font-weight-bold;
						word-break: break-all;
					}

					.email {
						font-size: $font-size-md;
						margin-bottom: $default-spacing;
					}
				}

				.action-icons {
					.icon {
						margin-left: $default-spacing;
						width: 20px;
						height: 20px;
					}
				}
			}
		}

		.meeting-details {
			textarea {
				resize: none;
				white-space: pre-wrap;
			}
		}
	}
}
