@mixin mapview-zone-label {
	color: $color-heading;
	font-size: $font-size-mlg;
	font-weight: $font-weight-bold;
}

.map-zone-view {
	position: absolute !important;
	z-index: 5 !important;

	@media all and (min-width: $breakpoint-xxlg) and (min-height: $breakpoint-height-xlg) {
		border-radius: 20px;
	}

	.react-modal-sheet-container {
		.search-header {
			margin: 8px;

			.divider-container {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
			}

			.divider {
				width: 30px;
				height: 4px;
				border-radius: 2px;
				background: #c4c4c4;
			}

			.mapview-title {
				display: grid;
				grid-template-columns: 0.5fr 1fr 0.5fr;
				align-items: center;
				text-align: center;

				.title {
					color: $color-main;
					font-size: $font-size-mlg;
					font-weight: $font-weight-bold;
				}

				.close {
					font-size: $font-size-mmlg;
					font-weight: $font-weight-normal;
				}
			}

			.mapview-zone-label {
				@include mapview-zone-label;
			}
		}

		.react-modal-sheet-content {
			touch-action: unset !important;
			.map-zone-view-item {
				align-items: center;
				border-bottom: 1px solid #c4c4c4;
				display: flex;
				flex-direction: row;
				height: 60px;
				justify-content: flex-start;
				position: relative;
				width: 100%;

				.header {
					margin-left: 8px;
					font-size: $font-size-mmlg;
					font-weight: $font-weight-normal;
					color: $color-heading;
				}

				.map-zone-view-thumbnail {
					margin: 8px;
					border-radius: 22px;
					height: 40px;
					width: 40px;
					display: flex;
					justify-content: center;
					align-items: center;

					.icon {
						height: 20px;
						width: 20px;
					}
				}
			}

			.map-zone-view-item:active {
				background: #d5d5d5;
				.header {
					color: $color-neutral-light;
				}
			}

			.mapview-no-result-label {
				@include mapview-zone-label;
				text-align: center;
			}
		}
	}

	.react-modal-sheet-backdrop {
		position: absolute !important;
	}
}
