.agenda-page {
	display: flex;
	align-items: center;
	flex-flow: column;
	width: 100%;
	height: 100%;

	&-header {
		width: 100%;
		z-index: 2;
	}
	&-content {
		flex-grow: 2;
		width: 100%;
		background-color: $color-neutral-95;
		display: flex;
		flex-flow: column;
		overflow: scroll;

		.day-selection-wrapper {
			z-index: 1;
			background-color: $color-neutral-light;
			box-shadow: 0 4px 18px rgba(0, 0, 0, 0.12);
		}

		&-items {
			overflow: scroll;
			width: 100%;

			.placeholder-list-items-wrapper {
				width: auto;
			}
		}
	}
}

.agenda-list {
	&-items {
		list-style-type: none;
		padding: 0 $spacing-m;

		&-no-results {
			height: 100%;
			display: flex;
			align-items: center;
			flex-flow: column;
			padding-top: 20%;
			text-align: center;
			font-size: $font-size-mmlg;
			font-weight: bold;
			gap: $spacing-s;

			p {
				margin: 0;
			}
			.icon {
				width: 50px;
				height: 50px;
			}
			.info-text {
				font-size: $font-size-lg;
				font-weight: $font-weight-normal;
				width: 80%;
			}
		}
	}

	&-item {
		padding: $spacing-m;
		@include sm-box-shadow;
		border-radius: 12px;
		background-color: $color-neutral-light;
		margin-bottom: $spacing-m;
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		gap: 0.5rem;
		
		&-content {
			display: flex;
			flex-flow: column;
			flex: 1;

			&-header {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.name {
					font-size: $font-size-mmlg;
					font-weight: $font-weight-bold;
					color: $color-main;
					margin: 0;
				}
			}

			&-details {
				margin-top: $spacing-m;
				p {
					line-height: $line-height-lgx;
					margin: 0;
					color: $color-neutral-70;

					span {
						color: $color-neutral-60;
					}
				}
			}
		}
	}
}
