.show-all-visitors {
	&-page {
		height: 100%;
		background-color: $color-neutral-95;
		display: flex;
		flex-flow: column;
	}

	&-header {
		background-color: $color-neutral-light;

		.day-selection {
			padding-bottom: $default-spacing;

			.day-item-wrapper {
				min-height: 45px;
			}
		}
	}

	&-content {
		overflow-y: scroll;

		.visitor-invitations-item {
			margin: $spacing-m;
		}

		&-no-invitations {
			margin-top: 15%;
			display: flex;
			flex-flow: column;
			align-items: center;
			text-align: center;

			p {
				margin: $default-spacing;
				max-width: 80%;
			}

			.bold {
				font-size: $font-size-mlg;
			}
		}
	}
}
