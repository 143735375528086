.notification-settings-page {
	&-content {
		display: flex;
		flex-flow: column;
		gap: 12px;
		padding: $spacing-m;

		.title-large {
			font-weight: bold;
			color: $color-main;
		}

		.body-small {
			color: $color-neutral-80;
		}
	}
}

.select-setting {
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: $color-neutral-50;
}
