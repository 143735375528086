.title {
	color: $color-main;
	margin-bottom: $spacing-m;
	font-size: $font-size-mlg;
	font-weight: $font-weight-medium;

	p {
		margin: 0;
	}
}

.subtitle {
	font-size: $font-size-lg;
	margin-bottom: $spacing-m;
	font-weight: $font-weight-normal;
	color: $color-neutral-60;

	p {
		margin: 0;
	}
}

.small-title {
	font-size: $font-size-mmlg;
	font-weight: $font-weight-bold;
	color: $color-neutral-60;
}

.error-info {
	color: $color-error;
}
