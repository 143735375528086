.iframe-page {
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: column;

	&-content {
		flex-grow: 2;
		overflow: scroll;

		iframe {
			height: 100%;
			width: 100%;
		}
	}
}
