.feature-item {
	display: flex;
	align-items: center;
	background-color: $color-neutral-95;
	padding: 3px 5px;
	border-radius: 5px;
	margin: 5px;
	min-width: 80px;
	height: 30px;
	justify-content: center;

	.icon,
	.custom-icon {
		margin-right: 5px;
	}
}
