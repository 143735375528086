.display {
	&-large {
		font-style: 'Roboto regular';
		line-height: 64px;
		font-size: 57px;
		font-weight: 400;
		letter-spacing: 0;
		margin: 0;
	}
	&-medium {
		font-style: 'Roboto regular';
		line-height: 52px;
		font-size: 45px;
		font-weight: 400;
		letter-spacing: 0;
		margin: 0;
	}

	&-small {
		font-style: 'Roboto regular';
		line-height: 44px;
		font-size: 36px;
		font-weight: 400;
		letter-spacing: 0;
		margin: 0;
	}
}
.headline {
	&-large {
		font-style: 'Roboto regular';
		line-height: 40px;
		font-size: 32px;
		font-weight: 400;
		letter-spacing: 0;
		margin: 0;
	}
	&-medium {
		font-style: 'Roboto regular';
		line-height: 36px;
		font-size: 28px;
		font-weight: 400;
		letter-spacing: 0;
		margin: 0;
	}
	&-small {
		font-style: 'Roboto regular';
		line-height: 32px;
		font-size: 24px;
		font-weight: 400;
		letter-spacing: 0;
		margin: 0;
	}
}
.title {
	&-large {
		font-style: 'Roboto regular';
		line-height: 28px;
		font-size: 22px;
		font-weight: 400;
		letter-spacing: 0.15;
		margin: 0;
	}
	&-medium {
		font-style: 'Roboto medium';
		line-height: 24px;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0.1;
		margin: 0;
	}
	&-small {
		font-style: 'Roboto medium';
		line-height: 20px;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0.1;
		margin: 0;
	}
}
.label {
	&-large {
		font-style: 'Roboto medium';
		line-height: 20px;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0.1;
		margin: 0;
	}
	&-medium {
		font-style: 'Roboto medium';
		line-height: 16px;
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0.5;
		margin: 0;
	}
	&-small {
		font-style: 'Roboto medium';
		line-height: 16px;
		font-size: 11px;
		font-weight: 500;
		letter-spacing: 0.5;
		margin: 0;
	}
}

.body {
	&-large {
		font-style: 'Roboto regular';
		line-height: 24px;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0.5;
		margin: 0;
	}
	&-medium {
		font-style: 'Roboto regular';
		line-height: 20px;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0.25;
		margin: 0;
	}
	&-small {
		font-style: 'Roboto regular';
		line-height: 16px;
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 0.4;
		margin: 0;
	}
}

.color-main {
	color: $color-main;
}
