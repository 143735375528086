.event-attendees-page {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	flex-flow: column;

	&-header {
		width: 100%;
	}

	&-content {
		color: $color-neutral-60;
		width: 100%;
		flex-grow: 2;
		display: flex;
		justify-content: center;
		flex-flow: column;
		height: 100%;
		overflow-y: scroll;

		&-search-wrapper {
			padding: $spacing-m;
			box-shadow: 0px 20px 20px 0px rgb(0 0 0 / 5%);

			.search-input {
				border-radius: 4px;
			}
		}

		&-filter-button {
			margin: $spacing-m 0;
			display: flex;
			justify-content: center;
			.attendees-filter-button {
				padding: $spacing-xs;
				color: $color-neutral-60;
				border-color: $color-neutral-60;

				.icon {
					color: $color-neutral-60;
				}
			}
			.attendees-filter-button-active {
				color: $color-main;
				border-color: $color-main;

				.icon {
					color: $color-main;
				}
			}
		}

		&-contacts-header {
			background-color: $color-neutral-95;
			margin: 0;
			padding: $spacing-xs $spacing-m;
			font-size: $font-size-lg;
			font-weight: $font-weight-bold;
		}

		&-contacts-list {
			overflow-y: scroll;
			flex-grow: 2;
			padding-bottom: 50px;
			display: flex;
			flex-flow: column;

			.loader-wrapper,
			.loader-wrapper-end-list {
				display: flex;
				justify-content: center;
			}
		}
	}
}
