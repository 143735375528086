@mixin header {
    color: $color-main;
    font-weight: $font-weight-bold;
    font-size: $font-size-mlg;
    margin: 0 0 $padding-s 0;
}
.time-tracker-settings-wrapper {
    overflow-y: auto;
    .activity-container {
        .activity-name-container {
            display: flex;
            flex-direction: column;
            padding: $spacing-lg $spacing-m;
            border-bottom: 4px solid $color-neutral-95;
            box-sizing: border-box;

            .activity-days {
                @include header;
            }
            .time-tracker-activity-dropdown {
                max-width: 100%;
                .custom-option {
                    .icon,
                    .bold {
                        margin-right: 5px;
                    }
                    span {
                        font-size: 13px;
                    }
                }
            }
        }
        .activity-days-container {
            display: flex;
            flex-direction: column;
            padding: $spacing-lg $spacing-m;
            border-bottom: 4px solid $color-neutral-95;
            box-sizing: border-box;

            .activity-name {
                @include header;
            }
            .time-tracker-activity-dropdown {
                max-width: 100%;
                .custom-option {
                    .icon,
                    .bold {
                        margin-right: 5px;
                    }
                    span {
                        font-size: 13px;
                    }
                }
            }
        }
        .sick-notes-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: $spacing-lg $spacing-m;
            box-sizing: border-box;
            border-bottom: 4px solid $color-neutral-95;
            gap:1rem;
            
            .sick-note {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                p{
                    font-weight: bolder;
                    margin:0;
                }
            }
        }
        .activity-notes-container {
            .activity-notes {
                padding: $spacing-lg $spacing-m $spacing-xxlg;
                label {
                    @include header;
                }
                textarea {
                    padding-bottom: $spacing-xxlg * 1.5;
                }
                .primary-input-hint-text {
                    float: right;
                    margin-right: $spacing-m;
                }
            }
        }
        .activity-date-times {
            border-bottom: 4px solid $color-neutral-95;
            #date-times {
                padding: $spacing-lg $spacing-m $spacing-xxlg;
                p:first-of-type {
                    margin-bottom: 0;
                }
                .date-time-input-wrapper {
                    padding-bottom: $spacing-m;
                }
            }
        }
    }
}
.time-tracker-buttons-wrapper {
}
.activity-buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    padding: 1rem 1rem;
    gap: 1rem;
    background-color: white;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1019607843);

    .activity-button-delete {
        background-color: $color-red-50;
        border-color: var(--color-red-50);
    }
}

.tt-section-title {
    color: var(--color-main);
    font-weight: 700;
    font-size: 20px;

    &.inset {
        padding-left: 16px;
        margin-top: 16px;
    }

    &.margin-bottom {
        margin-bottom: 16px;
    }
}
