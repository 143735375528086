.aspen-requests {
	&-wrapper {
		width: 100%;

		.date-header {
			font-weight: $font-weight-bold;
			margin: $spacing-m;
			font-size: 20px;

			span {
				color: $color-neutral-60;
				font-weight: $font-weight-normal;
			}
		}
	}

	&-item {
		&-wrapper {
			display: flex;
			margin: $spacing-m;
			padding: $spacing-m;
			background-color: $color-neutral-light;
			border-radius: 12px;
			@include sm-box-shadow;
		}
		&-icon-wrapper {
			width: 32px;
			height: 100%;
			display: flex;
			justify-content: flex-start;
			margin-right: $spacing-xs;

			.icon-wrapper {
				width: 32px;
				height: 32px;
				background-color: $color-neutral-95;
				border-radius: 5px;
				display: flex;
				justify-content: center;
				align-items: center;

				.icon {
					width: 16px;
					height: 16px;
				}
			}
		}
		&-details-wrapper {
			.name-wrapper {
				display: flex;
				align-items: center;
				margin-bottom: $spacing-m;
				.aspen-type-name {
					margin: 0;
					font-size: $font-size-mmlg;
				}
				.new-updates {
					min-width: 20px;
					height: 20px;
					color: $color-neutral-light;
					background-color: $color-red-50;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-left: 4px;
				}
			}
			.aspen-item-name,
			.aspen-request-start {
				margin: 0;
				margin-bottom: $spacing-xxs;
				font-size: $font-size-lg;
				color: $color-neutral-70;
				font-weight: $font-weight-semibold;
			}
			.aspen-request-start {
				font-weight: $font-weight-normal;
			}
		}
	}
}

.aspen-item-status {
	.status-label {
		display: flex;
		justify-content: center;
		padding: $spacing-xxs $spacing-xs;
		font-size: $font-size-md;
		font-weight: bold;
		width: fit-content;
		border-radius: 4px;

		p {
			margin: 0;
		}
	}
	.status-label-open {
		background-color: $color-blue-10;
		color: $color-blue-20;
	}

	.status-label-inProgress {
		background-color: $color-main-90;
		color: $color-main;
	}

	.status-label-completed {
		background-color: $color-green-95;
		color: $color-green-50;
	}

	.status-label-cancelled {
		background-color: $color-red-95;
		color: $color-red-60;
	}
}

.aspen-request-details {
	&-wrapper {
		display: flex;
		flex-flow: column;
		height: 100%;
	}
	&-information {
		padding: 0 $spacing-m;
	}
	&-container {
		flex-grow: 2;
		overflow: scroll;
	}
	&-comments {
		.title {
			margin: $spacing-m;
		}
	}
}
