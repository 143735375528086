.booking-page {
	height: 100%;
	display: flex;
	flex-flow: column;

	&-switcher {
		display: flex;
		justify-content: center;

		svg {
			fill: $color-main !important;
		}
		.custom-option-label {
			display: flex;
			align-items: center;
			justify-content: center;
			width: fit-content;

			.label {
				margin-left: 5px;
				font-weight: bold;
				color: $color-main;
				font-size: $font-size-mmlg;
			}

			.icon {
				width: 20px;
				height: 20px;
			}
		}
	}
	&-content {
		display: flex;
		flex-flow: column;
		overflow-y: scroll;
		scroll-behavior: smooth;
		flex-grow: 2;

		&-input {
			padding: 24px 16px;
			border-top: 4px solid $color-neutral-95;
		}
	}

	.primary-input-label {
		@extend .booking-page-headline;
	}

	.features,
	&-date-time-picker {
		padding: 24px 16px;
		margin-bottom: 0;
		border-top: 4px solid $color-neutral-95;
		position: static;

		.office-hours {
			display: flex;
			flex-flow: column;
			align-items: flex-start;
			margin-top: $spacing-s;

			p {
				margin: 0;
			}
			.header-wrapper {
				display: flex;
				align-items: center;
				.icon {
					width: 15px;
					height: 15px;
					margin-right: 5px;
				}

				span {
					font-weight: $font-weight-bold;
					text-decoration: underline;
					cursor: pointer;
				}
			}
			.info-wrapper {
				display: flex;
				justify-content: flex-start;
				margin-top: 4px;
				.periods {
					margin-left: 5px;
					font-weight: $font-weight-bold;
				}
			}
		}
	}

	.features {
		min-height: 100px;
	}

	&-entity-view {
		height: 100%;
		background-color: $color-neutral-95;
	}

	.close-button {
		margin: 0;
		position: absolute;
		right: 15px;
		top: 16px;
	}

	.main-heading {
		@extend .headline;
		text-transform: inherit;
		letter-spacing: 0;
		margin-top: $default-spacing;

		&:after {
			display: none;
		}
	}

	&-heading-wrapper {
		@include md-box-shadow;
		background: $color-neutral-light;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: $default-max-app-width;
		z-index: 1;
		padding: $default-spacing 0;
	}

	.no-area {
		display: flex;
		flex-direction: column;
		text-align: center;
	}

	img.no-area-image {
		width: 80%;
	}

	.booking-page-headline {
		color: $color-main;
		font-weight: $font-weight-bold;
		font-size: $font-size-mlg;
		margin: 0 0 $padding-s 0;
	}

	.desk-booking-wrapper {
		display: flex;
		flex-direction: column;
		padding: 24px 16px;
		border-top: 4px solid $color-neutral-95;
		box-sizing: border-box;

		.colleague-item-wrapper:last-of-type {
			padding-bottom: 0;
			padding-top: 10px;
			border-top: 1px solid $color-neutral-95;
		}

		.colleague-item-wrapper .photo-wrapper img {
			width: 40px;
			height: 40px;
		}

		.switch-label {
			margin-top: 20px;
		}

		.desk-booking-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			.desk-booking-sub-container {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;

				.title {
					@extend .booking-page-headline;
				}

				.sub-title {
					color: $color-heading;
					font-weight: $font-weight-normal;
					font-size: $font-size-lg;
					margin: 0 0 $padding-s 0;
				}
			}
		}

		.desk-booking-empty-wrapper {
			align-items: center;
			border: 2px dashed $color-neutral-95;
			display: flex;
			flex-flow: column;
			margin-top: 10px;
			padding-top: 15px;

			.add-colleagues-button {
				align-items: center;
				background-color: $color-neutral-80;
				color: $color-neutral-light;
				display: flex;
				font-size: 24px;
				height: 30px;
				justify-content: center;
				width: 30px;
				border-radius: 50%;
			}
		}

		.colleague-item-wrapper {
			margin: 10px 0;
		}

		.hr-colleague-item-bottom {
			margin: 0;
		}
	}
	.booking-page-content {
		.hybrid-virtual-meeting-container {
			padding: 24px 16px;
			border-top: 4px solid $color-neutral-95;

			.hybrid-virtual-switcher-container {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 1rem;

				#hybrid-virtual-meeting-switch {
					position: absolute;
				}
			}
		}
	}
}

.booking-page-entity-view {
	display: flex;
	flex-flow: column;
	height: 100%;
	.search-wrapper .search-header .close-button {
		top: 0;
	}

	.map-view-container::before {
		display: none;
	}

	.booking-page-heading-wrapper {
		box-shadow: none;
	}

	.additional-info-wrapper {
		padding-top: 0;
	}

	.entity-list-header-wrapper {
		z-index: 2;
		width: 100%;
		box-sizing: border-box;
		box-shadow: 0 20px 10px -15px rgb(0 0 0 / 12%);
		padding-top: 0;
	}

	.booking-page-entity-view-header-wrapper {
		z-index: 1;
	}

	&-overlay {
		background-color: rgba($color: #333333, $alpha: 0.5) !important;
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
	}
}

.show-overlay {
	.entity-list-header-wrapper {
		z-index: 0;
	}
}
