.office-element-page {
	height: 100%;
	display: flex;
	flex-flow: column;

	&-content {
		flex-grow: 2;
		overflow-y: scroll;
		.info-wrapper {
			display: flex;
			flex-flow: column;
			justify-content: flex-start;
			margin: $spacing-s $spacing-m;

			p {
				margin: 0;
			}
			.title {
				margin-bottom: $spacing-xs;
			}
			.value {
				font-size: $font-size-mmlg;
			}
		}

		.images-wrapper {
			display: flex;
			flex-flow: column;
			margin: $spacing-m;
			.ph-item,
			.ph-row,
			.ph-col-12 {
				width: 150px;
				height: 250px;
				margin: 0;
				padding: 0;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.images-list {
				display: flex;
				overflow-x: scroll;
				.image {
					margin-right: $spacing-m;
					img {
						width: 150px;
					}
				}
			}
		}
	}
}

.large-image {
	background-color: $color-neutral-80;
	padding: $spacing-m;
	.office-element-name {
		display: flex;
		align-items: center;
		.icon-wrapper {
			border-radius: 50%;
			background: $color-neutral-90;
			min-width: 32px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 10px;
			margin-right: 10px;
			border: 3px solid $color-neutral-light;

			.custom-icon {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 15px;
				height: 15px;
				color: $color-neutral-dark;
			}
		}

		p {
			margin: 0;
			color: $color-neutral-light;
			font-weight: $font-weight-bold;
			font-size: $font-size-xlg;
		}
	}
	.close-card {
		font-size: $font-size-xxlg;
		display: flex;
		justify-content: space-between;
		margin-right: $spacing-m;
	}
}
