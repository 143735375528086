.badge-card {
	height: 100vh;
	display: flex;
	flex-flow: column;
	overflow-y: auto;

	&-content {
		width: 100%;
		align-items: center;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		flex: 1 1 auto;
		position: relative;

		.edit-icon {
			position: absolute;
			right: 15px;
			top: 15px;
			cursor: pointer;
		}

		&-qr-container {
			width: 100%;
			position: relative;
			display: flex;
			flex-flow: column;
			align-items: center;

			.badge-wrapper {
				position: relative;
				display: flex;
				flex-flow: column;
				align-items: center;
				width: 100%;
				margin-bottom: 50px;

				.badge-qr {
					margin-top: 40px;

					&.small {
						margin-top: 55px;
					}

					@media all and (min-width: $breakpoint-sm) {
						margin-top: 70px;
					}
				}
				.progress-circle {
					position: absolute;

					#progress-bar {
						transition: all 1s linear;
					}
				}

				.loading-wrapper {
					width: 200px;
					height: 200px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 100px;
				}
				.hidden {
					display: none;
				}

				.text-countdown {
					font-size: $font-size-xlg;
					margin: 0;
				}
			}
		}

		&-description {
			display: inline-block;
			width: 280px;
			margin: 0 auto;
			text-align: center;
			white-space: pre-line;
			padding-top: 15px;
			margin-bottom: 50px;

			span {
				font-weight: bold;
				color: $color-red-60;
			}
		}
	}

	.close-button {
		display: flex;
		align-items: center;
		margin: 0 0 0 auto;

		p {
			text-transform: uppercase;
			font-size: 14px;
			letter-spacing: 1.5px;
			font-weight: 600;
		}
	}

	.back {
		display: flex;
		align-items: center;
		margin: 0;
		text-transform: uppercase;
		font-size: 14px;
		letter-spacing: 1.5px;
		font-weight: 600;

		.icon {
			color: $color-main;
			padding-right: 5px;
			padding-left: 0;
			font-size: $font-size-sm;
		}
	}
}
