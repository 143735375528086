.features {
	&-page {
		display: flex;
		flex-direction: column;
		height: 90%;

		.close-button,
		.btn-back {
			z-index: 10;
		}

		&-header {
			.main-heading {
				@extend .headline;
				text-transform: initial;
				margin: 0;
				position: absolute;
				top: 24px;
				left: 0;
				right: 0;
				z-index: 0;
				padding-left: 70px;
				padding-right: 70px;
				letter-spacing: normal;

				&:after {
					display: none;
				}
			}
		}

		.search-input-wrapper {
			margin: 0 $default-spacing;
		}

		.buttons-wrapper {
			filter: drop-shadow(4px 0 24px rgba(0, 0, 0, 0.12));
			display: flex;
			justify-content: center;
			background-color: $background-color-main;
			width: 100%;
			position: absolute;
			bottom: 0;
			padding: $default-spacing 0;

			.btn-primary {
				width: 80%;
			}
		}
	}

	&-chips-wrapper {
		display: flex;
		width: 100%;
		overflow: auto;
		align-items: center;
		align-content: space-evenly;
		padding: $default-spacing;
		box-sizing: border-box;
		margin-top: 5px;
	}

	&-container {
		overflow-y: scroll;

		.no-results {
			font-weight: bold;
			text-align: center;
		}

		.feature-option {
			align-items: center;
			justify-content: flex-start;
			display: flex;
			flex-direction: row;
			padding: 16px;
			height: 25px;
			border-bottom: 1px solid $color-neutral-90;

			&-icon {
				display: flex;
				height: 25px;
				width: 25px;
				align-items: center;
				color: $color-neutral-60;
			}

			p {
				margin-left: $default-spacing;
			}

			.icon-check {
				margin-left: auto;
				width: 15px;
			}
		}
	}
}
