.ask-question-modal-sheet-wrapper {
	z-index: 5 !important;
}

.ask-question-modal-sheet {
	&-wrapper {
		position: absolute !important;
	}
	&-content,
	&-header {
		background-color: $color-main;
		p {
			font-size: $font-size-mlg;
			color: $color-neutral-light;
			text-align: center;
			font-weight: $font-weight-semibold;
		}
	}

	&-header {
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
		.modal-header-wrapper {
			width: 100%;
			display: flex;
			justify-content: flex-end;

			p {
				margin: $spacing-s;
			}
		}
	}

	&-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		p {
			max-width: 350px;
		}

		&-details {
			flex-grow: 2;
			display: flex;
			flex-flow: column;
			align-items: center;
		}

		#ask-question {
			margin-top: 20px;
			width: 80%;
		}

		.button {
			margin-top: $spacing-m;
			min-height: 48px;
		}

		.ask-question-textarea {
			width: 100%;
			display: flex;
			justify-content: center;
		}

		.textarea-full-height {
			height: 200px;
		}

		&-input {
			width: 100%;
			margin-bottom: 50px;
			display: flex;
			align-items: center;
			flex-flow: column;
		}
	}
}
