.heading {
	text-align: left;
	text-transform: uppercase;
	@extend .font-lg;
	@include horizontal-line-left;
}

.main-heading {
	flex: 0 1 auto;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 40px;
	padding: 0 $default-spacing;

	@include horizontal-line-at-center;
	@extend .font-xlg;

	@media all and (max-width: 375px) {
		font-size: 21px;
	}

	@media all and (max-width: 340px) {
		font-size: 19px;
	}
}

.bold {
	font-weight: bold;
}

.section-heading {
	color: $color-main;
	font-weight: $font-weight-bold;
	font-size: $font-size-mlg;
	margin: $padding-s;
}

//used for pages that show details. example -> <p class="section-detail"> Label: <span>detail</span></p>
@mixin section-detail {
	color: $color-neutral-60;
	font-size: $font-size-lg;
	font-weight: $font-weight-bold;
	margin: $spacing-m;
	span {
		font-weight: $font-weight-normal;
	}
}
.subheading {
	font-weight: $font-weight-bold;
	font-size: 18px;
	line-height: $line-height-md;
}

.header-title {
	font-weight: $font-weight-bold;
	font-size: 18px;
	line-height: $line-height-md;
	margin-top: -47px;
	margin-bottom: 29px;
	text-align: center;
}

.breadcrumbs {
	font-weight: $font-weight-normal;
	font-size: 16px;
	line-height: $line-height-md;
	color: $color-neutral-80;
}

//CSS classes used for designing the header of pages - including back/close button
.header-wrapper {
	flex: 0 1 auto;
	display: flex;
	justify-content: space-between;
}

.header-wrapper-only-close {
	flex: 0 1 auto;
	display: flex;
	justify-content: flex-end;
}

.header-wrapper-only-back {
	flex: 0 1 auto;
	display: flex;
	justify-content: flex-start;
}

.close-button {
	flex: 0 1 auto;
	display: flex;
	align-items: center;
	margin: $default-spacing $default-spacing $default-spacing 10px;

	p {
		font-size: $font-size-mmlg;
		font-weight: $font-weight-normal;
		letter-spacing: 1.5px;
		margin-top: $default-spacing;
	}
}

.btn-back {
	width: 32px;
	height: 32px;
	background-color: $color-neutral-95;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #000;
	font-size: $font-size-mmlg;
	font-weight: $font-weight-normal;
	margin: $default-spacing 10px $default-spacing $default-spacing;
	border-radius: 100%;

	.icon {
		color: $color-main;
		padding-right: 5px;
		padding-left: 5px;
		font-size: 12px;
		height: 14.05px;
		width: 8.33px;
	}

	.area-list {
		margin-left: -7px;
	}

	p {
		text-transform: uppercase;
		font-size: $font-size-md;
		letter-spacing: $letter-spacing-lg;
		font-weight: $font-weight-medium;
	}
}

.secondary-header {
	&-wrapper {
		min-height: 80px;
		display: flex;
		justify-content: space-between;
		background: $color-neutral-light;
		align-items: center;
		//.header {
		//  margin: 0 $default-spacing;
		//}
		box-shadow: 0 4px 18px rgba(0, 0, 0, 0.12);
	}

	&-back-button {
		min-width: 100px;
	}

	&-main-heading {
		width: 100%;
		text-align: center;
		font-weight: bold;
		font-size: $font-size-mlg;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&-close-button {
		display: flex;
		justify-content: flex-end;
		min-width: 100px;
	}
}

.disable-box-shadow {
	box-shadow: none;
}

.headline {
	font-weight: $font-weight-bold;
	margin-bottom: $default-spacing;
	font-size: $font-size-mlg;
	line-height: $line-height-lg;

	&-semibold {
		font-weight: $font-weight-medium;
		margin-bottom: $default-spacing;
		font-size: $font-size-mlg;
	}
}

.section-separation-line {
	background-color: $color-neutral-95;
	width: 100%;
	min-height: 6px;
}
