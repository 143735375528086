.edit-booking {
	&-page {
		height: 100%;
		display: flex;
		flex-flow: column;
		overflow-y: scroll;
	}

	&-information {
		flex-grow: 2;
		overflow-y: scroll;

		&-meeting-name {
			padding: 0 $spacing-m;

			p {
				margin: $spacing-m 0;
			}
		}
		&-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: $spacing-m;

			p {
				margin: 0;
			}
			.icon {
				width: 24px;
				height: 24px;
			}

			&-icons {
				display: flex;
				gap: 10px;
			}
		}

		.date-time-picker {
			padding: 0 $spacing-m;
		}

		&-location {
			padding: $spacing-m;
			p {
				margin: 0;
			}
			.section-heading {
				margin-bottom: $spacing-lg;
			}
			.entity-name {
				font-size: $font-size-mmlg;
				font-weight: $font-weight-bold;
				margin-bottom: $spacing-xxs;
			}
			.entity-location {
				font-size: $font-size-mlg;
				font-weight: $font-weight-normal;
				color: $color-neutral-70;
				margin-bottom: $spacing-xxs;
			}
			.entity-features {
				display: flex;
				overflow-x: scroll;
			}
		}

		&-repeated-bookings {
			padding: 0 $default-spacing;
			border-top: 4px solid $color-neutral-95;

			.info-section {
				background-color: $color-main-80;
				display: flex;
				align-items: center;

				.icon {
					margin: 0 10px;
				}
			}
		}
		.pine-invitation-details,
		.pine-invitation-summary {
			margin: 0 $spacing-m;
		}
	}

	&-buttons {
		@include md-box-shadow;
		z-index: 0;
	}
}
