.favorites-list-wrapper {
	display: flex;
	flex-flow: column;
	flex-grow: 2;
	overflow-y: scroll;

	.loading-spinner-wrapper {
		margin: $default-spacing;
		display: flex;
		justify-content: center;
	}

	.favorites-amount {
		font-size: $font-size-mlg;
		color: $color-neutral-60;
		margin: $spacing-s $spacing-m;
		font-weight: $font-weight-bold;

		span {
			color: $color-neutral-80;
			margin-left: $spacing-xxs;
		}
	}

	.favorites-list-container {
		margin: 0 $spacing-m;
		padding-bottom: 40px;
		display: flex;
		flex-flow: column;
		flex: 1 1 auto;
		overflow-y: auto;

		.no-favorites-wrapper {
			display: flex;
			flex-flow: column;
			justify-content: center;
			height: 100%;
			align-items: center;

			p {
				text-align: center;
				font-weight: $font-weight-bold;
				font-size: $font-size-lg;
				line-height: $line-height-lg;
				color: $color-neutral-60;
				padding: 0 $default-spacing;
			}

			img {
				width: 200px;
			}
		}
	}
}
