.office-selection {
	height: 100%;
	background: $color-neutral-light;
	overflow-y: scroll;
	overflow-x: hidden;
	&-container {
		@extend .profile-completion-subContainer;
	}

	&-sub-container {
		height: 100%;
	}

	&-ui {
		@extend .profile-completion-ui;

		.languageSwitcher {
			margin: 0 0 $padding-m 0;
		}

		&-label {
			height: 29px;
			font-size: $font-size-mmlg;
			font-weight: $font-weight-bold;
			margin: unset;
			color: $color-text-label;
		}
	}

	&-confirm-button-wrapper {
		display: flex;
		justify-content: center;
	}
	&-error {
		font-size: $font-size-lg;
		color: $color-error;
		height: 100%;
	}
}
