.primary-input {
	// margin: $default-spacing 0;

	&-label {
		display: inline-block;
		margin-bottom: $spacing-s;
		color: $color-sub-heading;
	}

	.input-wrapper {
		position: relative;
		display: flex;
		align-items: center;

		.icon-green {
			position: absolute;
			right: 10px;
		}
	}

	&-hint-text {
		font-size: $font-size-lg;
		opacity: 0.8;
		color: $color-main;
		text-align: right;
		margin: $spacing-s 0 0 0;
	}
}

.disabled {
	.input-wrapper {
		input {
			background-color: $color-disabled-state;
			cursor: not-allowed;

			&:active,
			&:focus,
			&:hover {
				border: none;
				outline: none;
			}
		}
	}
}

.error {
	.primary-input-error-message {
		margin: 0;
		font-size: $font-size-md;
		color: $color-error;
	}

	.input-wrapper {
		position: relative;
	}

	.icon-error {
		position: absolute;
		right: 10px;
		color: $color-error;
	}
}
