.colleague-list-page {
	display: flex;
	flex-direction: column;
	height: 100%;

	.action-wrapper {
		display: flex;
		align-items: center;
		margin: $spacing-m;
		justify-content: space-between;

		.search-input-wrapper {
			width: 100%;
			position: relative;
			align-items: center;
		}

		.action-button {
			margin: 0 0 0 $default-spacing;
		}
	}

	.img-wrapper {
		height: 60%;
		width: 100%;
		display: flex;
		justify-content: center;
		flex-flow: column;
		align-items: center;

		img {
			width: 200px;
		}

		p {
			font-size: $font-size-lg;
			text-align: center;
			margin: $default-spacing;
		}
	}

	.loader-wrapper {
		margin-top: 5em;
	}

	.loader-wrapper,
	.loader-wrapper-end-list {
		display: flex;
		justify-content: center;
	}

	.list-header {
		background-color: $color-neutral-90;
		color: $color-neutral-light;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: space-between;

		p {
			padding: 10px;
			margin: 0;
		}

		.save-action-button {
			background-color: $color-main;
		}
	}

	.list-content {
		width: 100%;
		padding-bottom: 50px;
		display: flex;
		flex-flow: column;
		flex-grow: 2;
		overflow-y: scroll;
	}

	.hidden {
		display: none;
	}
}
