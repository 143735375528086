.auth-heading {
	font-size: $font-size-xxlg;
	font-weight: $font-weight-bold;
	display: flex;
	margin: unset;
	color: $color-heading;
}

.auth-subheading {
	font-size: $font-size-lg;
	display: flex;
	margin: $padding-s 0 0 0;
	color: $color-sub-heading;
}
