.office-info {
	&-page {
		display: flex;
		flex-direction: column;
		height: 100%;
		position: relative;

		.header-wrapper {
			display: flex;
			flex-flow: column;
			color: $color-neutral-light;
			justify-content: flex-start;
			width: 100%;
			box-shadow: rgb(255, 255, 255) 0px 30px 15px -7px inset;

			.close-button {
				align-self: flex-end;
			}
		}
	}

	&-map-icon {
		position: absolute;
		right: 25px;
		top: 20px;
		z-index: 5;
		border: solid $color-neutral-light 2px;
		border-radius: 50%;
		padding: 5px;
		width: 15px;
		height: 15px;
		display: flex;
		justify-content: center;
		align-items: center;

		.icon {
			width: 15px;
			height: 15px;
		}
	}

	&-content {
		margin-top: 50px;
		padding: $default-spacing;
		display: flex;
		flex-direction: column;
		border-top-left-radius: 25px;
		border-top-right-radius: 25px;
		background-color: $color-neutral-light;
		overflow-y: scroll;
		height: 100%;

		.google-map-container {
			.location-header {
				@extend .heading;
				@include horizontal-line-left;
			}
		}
	}

	&-equipments {
		&-header-wrapper {
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: space-between;
			margin-top: $default-spacing;

			.header {
				@extend .heading;
				@include horizontal-line-left;
			}

			.btn-wrapper {
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}

			.show-all {
				padding: $default-spacing 0 $default-spacing 0;

				.icon {
					width: 18px;
					height: 18px;
				}
			}
		}

		&-content {
			margin-top: $default-spacing;
			display: flex;
			overflow-x: scroll;

			.equipment-option {
				display: flex;
				flex-flow: column;
				align-items: center;
				min-width: 130px;
				height: 100px;
				background-color: $color-neutral-95;
				border-radius: 20px;
				justify-content: center;
				padding: $default-spacing;
				margin-right: $default-spacing;
				cursor: pointer;

				.icon-wrapper {
					background-color: $color-neutral-light;
					border-radius: 50%;
					min-width: 50px;
					min-height: 50px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				p {
					overflow: hidden;
					max-width: 120px;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-size: $font-size-sm;
				}
			}
		}
	}

	&-header {
		display: flex;
		flex-flow: column;
		align-items: center;

		p {
			margin: 0;
		}

		.office-name {
			font-size: $font-size-xlg;
			font-weight: bold;
			text-align: center;
		}
	}

	&-breadcrumbs {
		@extend .breadcrumbs;
		margin-top: 0;
	}
}
