.attendee-chain-names {
	position: relative;
	height: 35px;
}

.attendee-chain-name {
	background-color: $color-neutral-95;
	color: $color-text;
	border: 2px solid white;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
}

.chain-purple {
	background-color: $color-main-80;
	color: $color-main;
}

.chain-blue {
	background-color: $color-blue-10;
	color: $color-blue-20;
}

.chain-green {
	background-color: $color-green-95;
	color: $color-green-50;
}

.chain-yellow {
	background-color: $color-yellow-90;
	color: $color-yellow-50;
}
