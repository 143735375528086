.tt-cr-card {
  display: flex !important;
  align-items: center !important;
  margin: $padding-s !important;
  padding: $padding-s !important;
  background-color: var(--color-neutral-light) !important;
  border-radius: 10px !important;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1) !important;
  margin: 1rem;
}

.centered-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: $padding-s !important;
  padding: $padding-s !important;
  background-color: var(--color-neutral-light) !important;
  border-radius: 10px !important;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1) !important;
  width: max-content;
}

.tt-cr-icon {
  margin-right: $padding-s;
  width: 50px;
  height: 50px;

  &-accepted {
    color: #2ac769;
  }

  &-declined {
    color: #dd062a;
  }

  &-pending {
    color: #ffaa44;
  }
}

.tt-cr-content {
  display: flex;
  flex-direction: column;
}

.tt-cr-card-content {
  align-items: start;
  display: flex;
  flex-direction: row;
  height: 22vh;
  justify-content: space-between;
  gap: 1rem;
}

.tt-cr-card-icon-holder {
  background-color: #ececec;
  padding: 14px;
  border-radius: 10px;
  width: 18px;
  height: 18px;
}

.tt-cr-card-icon {
  color: var(--color-neutral-darkgrey);
  width: 100%;
  height: 100%;
}

.tt-cr-title {
  font-weight: bold;
}

.tt-cr-details {
  display: flex;
  flex-direction: column;
  margin-top: $padding-xxs;
}

.action-wrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.time-tracker-overview-accordion {
  padding-top: $padding-sml;
  border: 0;
  border-radius: 10px !important;
  background-color: transparent !important;
  box-shadow: none !important;

  &-title {
    padding-left: $padding-xxs;
    font-size: $font-size-lg;
    font-weight: bold;
    color: var(--color-neutral-dark);
  }
}

.tt-cr-time {
  font-size: $font-size-md;
  color: var(--color-time-label);
}

.tt-cr-card-backdrop {
  height: 100%;
  width: 100%;
  z-index: 4;
}