.customRadio {
	display: flex;
	align-items: center;
	margin: 10px 0;
	@extend .font-sm;
}

input[type='radio'] {
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	border: 2px solid $color-checkbox;
	background-color: white;
	height: 1.5em;
	width: 1.5em;
	border-radius: 1em;
	vertical-align: bottom;
	margin: 5px 10px 5px 0;
}

input[type='radio']:checked {
	border: 2px solid $color-checkbox;
	box-shadow: inset 0px 0px 0px 3px $color-neutral-light;
	background-color: $color-checkbox;
}

input[type='radio']:disabled {
	border: 2px solid $color-checkbox-disabled;

	&:checked {
		background-color: $color-checkbox-disabled !important;
	}
}
