.proposals {
    &-section {
        border-top: 4px solid $color-neutral-95;
        padding-top: $padding-m;
        background: $color-neutral-light;
        position: relative;

        .title {
            @extend .booking-page-headline;
            margin: 0 0 $padding-s 0;
        }
    }
    &-list {
        box-shadow: 0px 4px 10px -5px;
        display: flex;
        flex-flow: column;
    }
}
