.booking-item {
	display: flex;
	box-sizing: border-box;
	margin: $default-spacing 0;
	background-color: $color-neutral-light;
	border-radius: 10px;
	line-height: 14px;
	overflow: hidden;
	@include sm-box-shadow;

	.label {
		padding: 0 2px;
	}

	&-amount {
		font-weight: bold;
		margin: 5px 0;
		font-size: $font-size-sm;

		span {
			font-weight: normal;
		}
	}

	&-data {
		margin-bottom: 6px;
	}

	&-action-wrapper {
		margin-left: auto;
	}

	&-time,
	&-area-name-text,
	&-office-name-text,
	&-office-name,
	&-area-name {
		display: inline-block;
		font-size: $font-size-xsm;
		line-height: $line-height-xsm;
		max-width: 120px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin-right: 3px;

		@media all and (min-width: 414px) {
			font-size: $font-size-sm;
			line-height: $line-height-sm;
		}
	}

	&-type {
		max-width: 70px;

		&:after {
			content: "•";
			display: inline-block;
			position: relative;
			padding: 0 3px;
		}
	}

	&-name {
		color: $color-main;
		font-weight: 700;
		max-width: 190px;

		@media all and (max-width: 413px) {
			max-width: 155px;
		}

		@media all and (max-width: 380px) {
			max-width: 100px;
		}
	}

	&-time {
		margin: 0;
		max-width: 215px;
	}

	&-icon {
		width: 35px;
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $color-neutral-60;
	}

	&-recurring-booking-icon {
		position: absolute;
		position: absolute;
		top: 3px;
		right: 3px;
		z-index: 1;
	}

	&-icons-wrap {
		position: relative;
	}

	&-name,
	&-type {
		display: inline-block;
		font-size: $font-size-md;
		line-height: $line-height-md;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		@media all and (max-width: 340px) {
			font-size: $font-size-sm;
			line-height: $line-height-sm;
		}
	}

	&-img-wrap {
		width: 55px;
		height: 57px;
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 12px;
		background: $color-neutral-95;

		.custom-icon {
			width: 35px;
		}
	}

	&-info {
		align-items: center;
		display: flex;
		width: calc(100% - 80px);
		padding: 12px 12px 12px 0;
	}

	&-icons {
		display: flex;
		margin-left: auto;
		height: 100%;
		flex-direction: column;
		justify-content: space-between;
		padding-left: 5px;
	}

	&-details {
		display: flex;
		flex-direction: column;
	}

	&-icon-labels {
		display: flex;
		align-items: flex-end;
		max-width: 85px;
	}

	&-ellipsis-icon.icon {
		margin-bottom: auto;
		height: 20px;
		width: 25px;
	}

	&-status {
		display: flex;
		justify-content: center;
		padding: 5px 10px;
		font-size: $font-size-sm;
		font-weight: bold;
		width: fit-content;
		border-radius: 5px;

		p {
			margin: 0;
		}
	}
	&-pending-status-attendee {
		background-color: $color-yellow-40;
		color: $color-neutral-light;
		font-weight: bold;
		padding: 0.5rem;
		border-radius: 5px;
	}
	&-pending-status-invite {
		background-color: $color-neutral-90;
		color: $color-neutral-dark;
		font-weight: bold;
		padding: 0.5rem;
		border-radius: 5px;
	}
	&-pending-status-organizer {
		background-color: $color-main-20;
		color: $color-neutral-light;
		font-weight: bold;
		padding: 0.5rem;
		border-radius: 5px;
	}
}

.status-cancelled {
	background-color: $color-red-95;
	color: $color-red-60;
	margin-top: 0.5rem;
}

.status-expired {
	background-color: $color-main-90;
	color: $color-main;
	margin-top: 0.5rem;
}

.status-checkInForgotten {
	background-color: $color-main-90;
	color: $color-main;
	margin-top: 0.5rem;
}

.status-checkOutForgotten {
	background-color: $color-neutral-95;
	color: $color-neutral-60;
	margin-top: 0.5rem;
}

.status-checkedOut {
	background-color: $color-blue-10;
	color: $color-blue-20;
	margin-top: 0.5rem;
}

.status-autoCheckedIn {
	background-color: $color-green-95;
	color: $color-green-50;
	margin-top: 0.5rem;
}

.status-approvalPending {
	background-color: $color-yellow-95;
	color: $color-yellow-40;
	margin-top: 0.5rem;
}

.status-declined {
	background-color: $color-red-95;
	color: $color-red-60;
	margin-top: 0.5rem;
}

.status-attendee {
	background-color: $color-yellow-40;
	color: $color-neutral-light;
	margin-top: 0.5rem;
}

.status-invite {
	background-color: $color-neutral-90;
	color: $color-neutral-dark;
	margin-top: 0.5rem;
}

.status-organizer {
	background-color: $color-blue-50;
	color: $color-neutral-light;
	margin-top: 0.5rem;
}
.status-newProposal {
	background-color: $color-red-65;
	color: $color-neutral-light;
}
.status-accepted {
	background-color: $color-green-50;
	color: $color-neutral-light;
	margin-top: 0.5rem;
}
.status-tentativelyAccepted {
	color: $color-neutral-light;
	background-color: $color-green-50;
	margin-top: 0.5rem;
}
.status-accepted {
	background-color: $color-green-50;
	color: $color-neutral-light;
	margin-top: 0.5rem;
}
