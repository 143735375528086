.ceylon-info-content {
	.header {
		padding: 0 $default-spacing;
		font-weight: $font-weight-medium;
		text-align: center;
	}
	.hidden {
		display: none;
	}
}
