.booking-card {
	box-sizing: border-box;
	margin: $spacing-m;
	background-color: $color-neutral-light;
	border-radius: 10px;
	@include sm-box-shadow;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	min-width: 80%;

	&-details {
		display: flex;
		justify-content: space-between;

		&-type-icon {
			min-width: 32px;
			height: 32px;
			border-radius: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 15px 12px 12px;
			background: $color-neutral-95;

			.next-booking-icon {
				width: 16px;
				height: 16px;
				color: $color-neutral-60;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.custom-icon {
				width: 16px;
			}
		}

		&-info {
			display: flex;
			justify-content: space-between;
			width: 100%;
			p {
				margin: 0;
			}
			&-details {
				display: flex;
				flex-flow: column;
				gap: $spacing-xs;

				.office-name,
				.area-name {
					font-size: $font-size-lg;
					line-height: $line-height-lgx;
					display: inline-block;
					max-width: 100px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					color: $color-neutral-80;
				}
				.office-name {
					margin-right: 5px;
				}
				.entity-name {
					display: inline-block;
					color: $color-main;
					font-weight: $font-weight-bold;
					font-size: $font-size-mmlg;
					line-height: $line-height-md;
					max-width: 200px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;

					@media all and (max-width: 340px) {
						font-size: $font-size-sm;
						line-height: $line-height-sm;
					}
				}

				.light {
					color: $color-neutral-80;
				}

				.bold,
				.light {
					font-size: $font-size-lg;
				}
			}
		}

		&-info-wrapper {
			display: flex;
			flex-flow: column;
			width: 100%;
			padding: $spacing-m;
			padding-left: 0;
			flex-grow: 2;
		}

		&-favorite {
			display: flex;
			align-items: flex-end;
			margin-left: auto;
			flex-direction: column;
		}
	}

	&-footer {
		display: flex;
		justify-content: space-between;
		background-color: $color-neutral-95;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;

		&-button {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.attendee-chain-names {
			margin: $spacing-m;
		}

		.btn-countdown {
			color: $color-neutral-60;
			font-size: $font-size-sm;
		}

		.btn-wrapper,
		.btn-countdown {
			padding: $spacing-s $spacing-m;
		}
		.checkin {
			padding: $spacing-xs $spacing-s;
		}
	}
	&-footer-checkin {
		background-color: $color-main-90;
	}
}
