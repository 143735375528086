.unscheduled-visitors {
	&-page {
		height: 100%;
		background-color: $color-neutral-95;
		display: flex;
		flex-flow: column;
	}

	&-header {
		background-color: $color-neutral-light;
	}

	&-content {
		display: flex;
		flex-flow: column;
		overflow-y: scroll;
	}

	&-item {
		display: flex;
		align-items: flex-start;
		flex-flow: column;
		justify-content: flex-start;
		border-radius: 15px;
		box-shadow: 4px 4px 20px rgb(0 0 0 / 10%);
		padding: $default-spacing;
		margin: $default-spacing;
		text-align: center;
		background-color: $color-neutral-light;

		p {
			margin: 5px 0;
		}

		.name {
			font-weight: bold;
			font-size: $font-size-lg;
		}

		.company {
			color: $color-main;
			font-weight: bold;
		}

		.label-wrapper {
			display: flex;
			align-items: center;

			.label {
				border-radius: 5px;
				padding: 0 5px;
				margin-right: 10px;
			}

			.checkin {
				background-color: $color-red-95;
				color: $color-red-50;
			}

			.checkout {
				background-color: $color-blue-10;
				color: $color-blue-20;
			}
		}
	}
}
