.page-login {
	height: 100%;
	display: flex;
	flex-flow: column;
	background: $color-neutral-light;
	background-position: center;
	background-attachment: fixed;
	overflow-y: scroll;
	overflow-x: hidden;

	&-content {
		flex-grow: 1;
	}
	.logo-wrapper {
		display: flex;
		justify-content: center;

		@media all and (min-height: $breakpoint-height-xs) {
			margin-top: $padding-m;
		}

		.logo {
			max-width: 124px;
		}
	}

	.error-container {
		display: flex;
		margin: 0 16px;
		align-items: center;
	}

	.error-icon-size {
		width: 16px;
		height: 16px;
	}

	.error-info {
		width: 100%;
		color: $color-error;
		font-size: $font-size-md;
		margin: 0 $padding-xxs;
	}

	&-info {
		text-align: center;
		min-height: 75px;

		@media all and (min-height: $breakpoint-height-xs) {
			margin: 50px $padding-s 60px;
		}

		&-headline {
			font-size: $font-size-xxlg;
			font-weight: $font-weight-bold;
			color: $color-heading;
		}

		&-sub-headline {
			font-size: $font-size-mlg;
			font-weight: $font-weight-medium;
			color: $color-heading;
		}

		&-description {
			font-size: $font-size-lg;
			color: $color-neutral-80;
			min-height: 21px;
			max-height: 130px;
			overflow: hidden;
			margin-top: $padding-xs;
		}
	}

	&-warning-wrapper {
		background-color: $color-warning;
		border-radius: 20px;
		padding: $spacing-m;
		margin: 0 $spacing-m;
	}
	.emailWrapper {
		margin: $padding-xs $padding-s;

		.input-wrapper input {
			height: 48px;
		}
		.input-wrapper input[type='text'] {
			font-size: $font-size-lg;
		}
	}

	.emailWrapper .primary-input-label {
		font-size: $font-size-mmlg;
		font-weight: $font-weight-medium;
		margin-bottom: $padding-xs;
		color: $color-text-label;
	}

	.error.emailWrapper {
		.primary-input-error-message {
			margin-top: $padding-xs;
		}
	}

	.checkbox-wrapper-label {
		margin: 0 0 15px 37.5px;
	}

	.login-other-info {
		color: $color-main;
		font-size: $font-size-xsm;
		width: 100%;
		text-align: center;
		text-decoration: underline;
	}

	.buttonWrapper {
		display: flex;
		justify-content: center;
		width: 100%;

		@media all and (min-height: $breakpoint-height-xs) {
			margin: $padding-s 0;
		}

		.input-checkbox-label {
			font-size: $font-size-lg;
		}

		.hyperlink {
			color: $color-main;
			text-decoration: underline;
		}

		.loginButton {
			border-radius: 8px;
			box-shadow: 0 10px 20px rgba(82, 54, 171, 0.3);
		}
	}

	.strategyButtons {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: $padding-s;
	}

	.hidden {
		height: 50px;
	}

	&-not-login {
		font-size: $font-size-mmlg;
		display: flex;
		justify-content: center;
		color: $color-sub-heading;
		margin: 0 $padding-s;
		align-items: center;

		p {
			margin: 0;
			text-align: center;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: $font-size-lg;
			font-weight: $font-weight-normal;
		}
		a {
			font-size: $font-size-lg;
			font-weight: $font-weight-bold;
			color: $color-main;
			text-decoration: none;
			background: none;
			border: none;
			cursor: pointer;
			padding: 4px;
		}
	}
	.copyright-section {
		width: 100%;
		height: 120px;
		justify-content: center;

		.website-redirect {
			font-size: $font-size-md;
			color: $color-neutral-80;
			text-align: center;
			margin: $padding-sm $spacing-lg;
			white-space: pre;

			a {
				cursor: pointer;
				color: $color-main;
				font-weight: $font-weight-bold;
			}
		}
	}
}

@media all and (min-width: 767px) {
	#react-joyride-portal {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		max-width: $default-max-app-width;
		max-height: $default-max-app-height;
		margin: 20px auto;
		overflow: hidden;
	}
}

.website-redirect {
	display: flex;
	justify-content: center;
	align-items: center;
}
