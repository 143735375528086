.user-image-card-backdrop {
    height: 100%;
    width: 100%;
    z-index: 4;
}

.centered-absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: $padding-s !important;
    padding: $padding-s !important;
    background-color: var(--color-neutral-light) !important;
    border-radius: 10px !important;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1) !important;
    width: max-content;
}

.user-image-card-content {
    height: 50vh;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}

.colleague-image {
    height: 80%;
}
