.recurring-booking-card {
	.recurring-booking-card-content {
		.card-header {
			font-weight: $font-weight-bold;
			text-align: center;
		}
		.options-wrapper {
			padding: 5% 10% 0 10%;
		}

		.option {
			width: 100%;
			display: flex;
			align-items: center;

			&-select-daily {
				margin-bottom: $default-spacing;
			}

			&-recurring-interval {
				.day-interval-select {
					margin: 0 $default-spacing;
				}
			}
			&-select-workdays {
				margin-top: $default-spacing;
				display: flex;
				align-items: center;
				width: 100%;

				#select-workdays {
					display: flex;
					align-items: center;
					justify-content: space-evenly;
					width: 100%;
					margin-left: $default-spacing;

					.workday {
						color: $color-neutral-dark;
						border: 1px solid;
						padding: 5px;
						border-radius: 5px;
						-webkit-box-shadow: inset 0 2em 3em -1em $color-neutral-95;
					}

					.active {
						color: $color-neutral-light;
						-webkit-box-shadow: none;
						background-color: $color-main;
					}
				}
			}

			&-start-date {
				margin-top: $default-spacing;

				.common-date-time-picker-wrapper {
					width: 80%;
					margin-left: $default-spacing;
				}
			}

			&-end-date {
				margin-top: $default-spacing;

				.common-date-time-picker-wrapper {
					width: 80%;
					margin-left: $default-spacing;
				}
			}
		}
	}

	.recurring-limit-info {
		margin: $default-spacing;
		text-align: center;
		font-size: $font-size-sm;
		color: $color-neutral-60;

		.purple-text {
			color: $color-main-60;
		}
	}

	.buttons-wrapper {
		margin-top: $default-spacing;
		text-align: center !important;
		padding: 0 10% !important;

		.button {
			width: 100%;
			margin: 5px 0;
		}

		.button-secondary {
			margin-bottom: $default-spacing !important;
		}
	}
}
