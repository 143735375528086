.action-sheet {
	height: 100%;
	width: 100%;
	background-color: rgba($color: #e7e7e7, $alpha: 0.5) !important;
	position: absolute;
	z-index: 5;

	&-content {
		bottom: 0;
		width: 100%;
		position: absolute;
	}

	.checked-in-view {
		color: $color-main;
		font-weight: $font-weight-semibold;
	}
	.checked-in-view:hover {
		background-color: $color-neutral-light;
		cursor: default;
	}
	&-option {
		background-color: $color-neutral-light;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid $color-neutral-95;
		font-size: $font-size-mlg;
		color: inherit;
		text-decoration: none;

		&-disabled {
			@extend .action-sheet-option;
			color: $color-neutral-95;
			background-color: $color-neutral-light !important;
		}

		&-main {
			@extend .action-sheet-option;
			background-color: $color-main-90;
		}
	}

	&-option:hover {
		background-color: $color-neutral-95;
		cursor: pointer;
	}

	.close {
		color: $color-red-50;
		padding-bottom: $spacing-m;
	}

	&-status-of-entity {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $color-main;
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		padding: $spacing-s;
		color: $color-neutral-light;

		&-status {
			&-circle {
				background-color: $color-neutral-light;
				height: 30px;
				width: 30px;
				border-radius: 50%;
				margin-right: $spacing-s;
				display: flex;
				justify-content: center;
				align-items: center;

				&-inner {
					height: 22px;
					width: 22px;
					border-radius: 50%;
				}

				.status-available {
					background-color: $color-green-50;
				}

				.status-unavailable {
					background-color: $color-red-50;
				}
			}
		}
		&-text {
			p {
				margin: 0;
			}
			.name {
				font-weight: $font-weight-bold;
				font-size: $font-size-mlg;
			}
			.available-info {
				font-weight: $font-weight-light;
				font-size: $font-size-md;
			}
		}
	}
	&-status-of-entity:hover {
		cursor: default !important;
		background-color: $color-main !important;
	}
}
