.health-questionnaire-page {
	padding: 40px 30px;
	display: flex;
	flex-direction: column;

	&-header {
		margin-bottom: 20px;
		@extend .main-heading;
	}

	&-list-item &-label {
		display: block;
	}

	&-text {
		display: inline-block;
		width: 80%;
		vertical-align: text-top;
	}

	&-button {
		text-transform: uppercase;
		width: 100%;
		margin: 5px 0;
	}

	&-list {
		padding-left: 0;
		margin-bottom: 40px;

		&-item {
			list-style: none;
			display: inline-flex;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
