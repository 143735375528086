.card-entity {
	&-time-wrapper {
		display: flex;
		justify-content: space-evenly;
		margin-top: $default-spacing;
		padding: 0 10%;
		align-items: flex-end;
		gap: 1rem;
	}
}
