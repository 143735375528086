.page-content {
	.refreshing-content-dashboard {
		background-color: $color-main-0;
		padding-top: 30px;
	}
	.ptr__children {
		overflow: hidden !important;
	}
	.dashboard-wrapper {
		position: relative;
		height: 90%;
		padding-bottom: 200px;
		display: flex;
		flex-flow: column;
		overflow-y: scroll !important;
		background-color: $background-color-main;

		.event-redirect {
			background-color: $color-main;
			background-position: center;
			background-size: cover;
			margin: $spacing-m;
			border-radius: 12px;
			padding: $spacing-m;
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;
			font-size: $font-size-mmlg;
			position: relative;

			p {
				margin: $spacing-xs;
				color: $color-neutral-60;
			}
			.name {
				font-weight: $font-weight-bold;
				font-size: $font-size-mmlg;
			}
			.info {
				font-size: $font-size-lg;
			}
			.details {
				z-index: 0;
			}
			.icon-wrapper {
				min-width: 40px;
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: $color-main-30;
				border-radius: 50%;
				z-index: 0;
			}
			.event-redirect-overlay {
				position: absolute;
				top: 0;
				left: 0;
				border-radius: 12px;
				background-color: white;
				opacity: 75%;
				width: 100%;
				height: 100%;
			}
		}

		.header-wrapper {
			background-color: $color-main-0;
		}

		.welcome-message {
			font-size: $font-size-mlg;
			color: $color-neutral-95;
			margin: $default-spacing;
			margin-left: 25px;
			align-self: flex-start;
			animation: fadeIn 1s;
			-webkit-animation: fadeIn 1s;
			-moz-animation: fadeIn 1s;
			-o-animation: fadeIn 1s;
			-ms-animation: fadeIn 1s;

			span {
				font-size: $font-size-xlg;
			}
		}

		.desk-icon {
			width: 40px;
			height: 40px;
		}

		.office-banner {
			z-index: -1;
			height: 100%;
			width: 100%;
		}

		.office-selector-redirect {
			color: $color-neutral-95;
			font-size: $font-size-mlg;
			display: flex;
			align-items: center;
			text-transform: uppercase;
			border: solid $color-main 2px;
			padding: 10px;
			border-radius: 5px;
			animation: fadeIn 0.5s;
			-webkit-animation: fadeIn 0.5s;
			-moz-animation: fadeIn 0.5s;
			-o-animation: fadeIn 0.5s;
			-ms-animation: fadeIn 0.5s;

			p {
				margin: 0 0 0 5px;
			}

			.icon {
				margin-left: 10px;
				width: 20px;
				height: 20px;
			}
		}
		@keyframes fadeIn {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		@-moz-keyframes fadeIn {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		@-webkit-keyframes fadeIn {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		@-o-keyframes fadeIn {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		@-ms-keyframes fadeIn {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		.dashboard-information {
			padding-bottom: 20px;
			box-sizing: border-box;

			.ceylon-status-content {
				margin: $default-spacing;
				font-size: $font-size-md;
				@include sm-box-shadow;
				background-color: $color-neutral-95;
				align-items: center;
				padding: 5px;
				border-radius: 10px;
				display: flex;
				justify-content: space-between;

				.status {
					font-weight: 600;
					display: flex;
					align-items: center;
					span {
						margin-left: 5px;
					}
				}

				.green {
					color: $color-green-50;
				}
				.red {
					color: $color-error;
				}

				p {
					margin: 0;
				}

				.status-btn {
					background-color: $color-main;
					padding: 5px;
					color: $color-neutral-95;
					align-items: center;
					display: flex;
					height: 20px;

					.button {
						font-size: $font-size-sm;
						white-space: nowrap;
						margin-right: 5px;
					}
				}
			}
		}

		.desk-booking-button-wrapper {
			display: flex;
			justify-content: space-evenly;
			position: absolute;
			bottom: -$default-spacing;
			z-index: 3;

			.action-wrapper {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 100%;
				p {
					margin: 2px;
				}

				&.desk-booking-button {
					.button {
						box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.32);
					}
				}
			}

			.action-button {
				color: $color-main;
				font-weight: 500;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				max-width: 40px;
				padding: 8px 0;
				background-color: $color-neutral-90;
				margin: 5px;
				font-size: $font-size-md;
				text-transform: uppercase;
				box-shadow: 2px 2px 10px 0 $color-neutral-90;

				svg {
					width: 25px;
					height: 20px;
				}

				&-large {
					width: 170px;
					max-width: 100%;
					justify-content: space-around;
					color: $color-neutral-95;
					text-align: center;
				}

				.desk-booking-button-description {
					margin: 5px $default-spacing;
				}
			}
		}
	}
}
