.facility-problem {
	&-wrapper {
		display: flex;
		flex-flow: column;
		height: 100%;

		.take-photo,
		.upload-photo {
			background: $color-red-60;
			border-color: $color-red-60;

			&-icon {
				color: $color-neutral-light;
				height: 14px;
				padding: 0;
				margin: 0 10px 0 0;
			}
		}
	}

	&-content {
		display: flex;
		flex-flow: column;
		overflow: scroll;
		flex-grow: 2;
		padding: $spacing-m;

		.upload-photo.hidden {
			display: none;
		}

		.error {
			.primary-input-error-message {
				margin-top: -$default-spacing;
			}
		}

		.hidden {
			height: 0;
		}
	}

	&-label {
		display: flex;
		margin-bottom: $default-spacing;
		align-self: flex-start;
	}

	&-field {
		margin: $default-spacing 0;
	}

	&-buttons {
		display: flex;
		justify-content: space-around;
	}
}
