.install-popup-wrapper {
	width: 100%;
	position: fixed;
	bottom: 0;
	z-index: -10;
	opacity: 0;
	transition: 0.5s;

	.content {
		color: $color-neutral-light;
		padding: $default-spacing;
		background-color: $color-main;

		.header-wrapper {
			width: 100%;
			display: flex;
			align-items: center;

			.header {
				justify-content: center;
				width: 100%;
				text-align: center;
				margin: 0;
				font-weight: bold;
			}

			.close {
				font-size: 30px;
			}
		}

		.info {
			text-align: center;
			font-size: $font-size-lg;
			line-height: 30px;

			.shareIcon {
				width: 20px;
				height: 20px;
				background-color: $color-neutral-light;
				border-radius: 50%;
				padding: 5px;
			}
		}
	}
	.arrow-wrapper {
		background-color: transparent;
		width: 100%;
		display: flex;
		justify-content: center;

		.arrow {
			width: 0;
			height: 0;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;

			border-top: 10px solid $color-main;
		}
	}
}

.open {
	opacity: 0.9;
	z-index: 10;
}
