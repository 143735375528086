body.bellTheme {
	--color-main-0: #{'#000e1a'};
	--color-main-10: #{'#00325b'};
	--color-main-20: #{'#004680'};
	--color-main-30: #{'#0059a4'};
	--color-main-40: #{'#007de6'};
	--color-main-50: #{'#00549a'};
	--color-main-60: #{'#229bff'};
	--color-main-70: #{'#4daeff'};
	--color-main-80: #{'#80c5ff'};
	--color-main-90: #{'#b3dcff'};
	--color-main-95: #{'#e5f3ff'};
	--color-main-99: #{'#f2f1f9'};
	--color-main: #{'#00549a'};

	--color-neutral-0: #{'#151515'};
	--color-neutral-10: #{'#141414'};
	--color-neutral-20: #{'#202020'};
	--color-neutral-30: #{'#272727'};
	--color-neutral-40: #{'#2E2E2E'};
	--color-neutral-50: #{'#333333'};
	--color-neutral-60: #{'#525252'};
	--color-neutral-70: #{'#707070'};
	--color-neutral-80: #{'#999999'};
	--color-neutral-90: #{'#C2C2C2'};
	--color-neutral-95: #{'#E7E7E7'};
	--color-neutral-light: #{'#FFFFFF'};
	--color-neutral-dark: #{'#333333'};

	//yellow
	--color-yellow-0: #{'#E36C00'};
	--color-yellow-10: #{'#FF800D'};
	--color-yellow-20: #{'#FF9116'};
	--color-yellow-30: #{'#FF9A1B'};
	--color-yellow-40: #{'#FFA421'};
	--color-yellow-50: #{'#FFAB25'};
	--color-yellow-60: #{'#FFB846'};
	--color-yellow-70: #{'#FFC466'};
	--color-yellow-80: #{'#FFD592'};
	--color-yellow-90: #{'#FFE6BE'};
	--color-yellow-95: #{'#FFF5E5'};

	//green
	--color-green-0: #{'#027637'};
	--color-green-10: #{'#089347'};
	--color-green-20: #{'#0FA25A'};
	--color-green-30: #{'#13AA64'};
	--color-green-40: #{'#17B26F'};
	--color-green-50: #{'#1AB977'};
	--color-green-60: #{'#3CC48B'};
	--color-green-70: #{'#5FCEA0'};
	--color-green-80: #{'#8DDCBB'};
	--color-green-90: #{'#BAEAD6'};
	--color-green-95: #{'#E4F7EF'};

	//blue
	--color-blue-10: #{'#cce8fe'};
	--color-blue-20: #{'#78c3fe'};
	--color-blue-30: #{'#5bacec'};
	--color-blue-40: #{'#2f80ed'};
	--color-blue-50: #{'#344982'};

	//red
	--color-red-0: #{'#6B0009'};
	--color-red-10: #{'#87000B'};
	--color-red-20: #{'#970013'};
	--color-red-30: #{'#A00018'};
	--color-red-40: #{'#A9001C'};
	--color-red-50: #{'#B00020'};
	--color-red-60: #{'#BC2641'};
	--color-red-65: #{'#db093b'};
	--color-red-70: #{'#C84D63'};
	--color-red-80: #{'#D88090'};
	--color-red-90: #{'#E7B3BC'};
	--color-red-95: #{'#F6E0E4'};

}
