.visitors-input {
    margin: $spacing-m;

    .add-visitors-section {
        .bold {
            font-weight: bold;
        }

        .add-more-button {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $color-main;
            font-weight: bold;

            .plus-sign {
                margin-right: 5px;
                width: 20px;
                height: 20px;
                font-size: $font-size-lg;
                border: 2px solid $color-main;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .visitor-item-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px $color-neutral-95 solid;

            .info-content {
                p {
                    margin: 5px 0;
                }

                .name {
                    font-weight: $font-weight-bold;
                    word-break: break-all;
                }

                .email {
                    font-size: $font-size-md;
                    margin-bottom: $default-spacing;
                }
            }

            .action-icons {
                .icon {
                    margin-left: $default-spacing;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}
