.desk-problem {
	&-wrapper {
		display: flex;
		flex-flow: column;
		overflow: scroll;
		height: 100%;

		.confirm-details {
			width: 100%;
			max-width: 375px;
			margin: 10px auto;
			display: flex;
			justify-content: center;
		}

		.take-photo,
		.upload-photo {
			background: $color-red-60;
			border-color: $color-red-60;

			&-icon {
				color: $color-neutral-light;
				height: 14px;
				padding: 0;
				margin: 0;
				margin-right: 10px;
			}
		}
	}

	&-areaDeskNameWrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: $font-size-md;
		padding: 20px 0;
		background-color: $color-neutral-95;
		margin-top: $default-spacing;

		p {
			margin: 5px;
		}

		&-areaData,
		&-deskData {
			font-weight: 500;
			font-size: $font-size-xlg;
		}

		&-deskData {
			color: $color-text-special;
		}
	}

	&-content {
		display: flex;
		flex-flow: column;
		overflow: scroll;
		margin: 0 $default-spacing;

		@media all and (min-width: $breakpoint-xlg) {
			height: 100%;
		}

		.upload-photo.hidden {
			display: none;
		}

		.hidden {
			height: 0;
		}

		.buttons-wrapper {
			margin-bottom: $default-spacing;
		}
	}

	&-label {
		display: flex;
		margin-bottom: $default-spacing;
		align-self: flex-start;
	}

	&-field {
		margin: $default-spacing 0;
	}

	&-scanQR {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 30px;
	}

	&-qr-code-option {
		background: $color-main;
		border-radius: 50%;
		width: 56px;
		min-height: 56px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		margin-bottom: 40px;
	}
}
