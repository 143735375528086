.your-checkins {
	&-section {
		.data-holder {
			margin-top: 6px;
		}
	}

	&-header {
		margin-left: $spacing-m;
	}

	&-list {
		display: flex;
		overflow-x: scroll;
	}
}

.MuiAccordionDetails-root {
	padding: 0 !important;
}