.common-time-wrapper {
	display: flex;
	flex-direction: column;

	.time-label {
		font-size: $font-size-lg;
		margin-bottom: 4px;
	}
	.time-container {
		cursor: pointer;
		border-radius: 8px;
		border: 1px solid $color-neutral-95;
		color: $color-neutral-60;
		outline: none;
		box-shadow: none;
		font-weight: $font-weight-bold;
		font-size: $font-size-md;
		background: transparent;
		display: flex;
		align-items: center;
		padding: $spacing-s;
		position: relative;

		.rc-time-picker-input {
			cursor: pointer;
			border: none;
			font-family: "Roboto";
			color: $color-neutral-60;
			font-size: $font-size-lg;
			font-weight: $font-weight-bold;
			padding: 0;
			outline: 0;
			z-index: 1;
			background: transparent;
			-moz-appearance: textfield;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}

		.rc-time-picker {
			width: 100%;

			&-panel-combobox {
				display: flex !important;
			}

			&-panel {
				z-index: 9;
			}
		}

		.icon {
			position: absolute;
			right: $spacing-s;
		}
	}
}
