.chip {
	background-color: $color-neutral-light;
	padding: 8px $default-spacing;
	font-weight: $font-weight-medium;
	border-radius: 20px;
	width: fit-content;
	margin-right: $default-spacing;
	display: flex;
	align-items: center;
	white-space: nowrap;

	.chip-icon-wrapper {
		margin-right: 5px;
		.icon {
			width: 20px;
		}
	}
}

.disabled-chip {
	border-style: solid;
	border-color: $color-neutral-90;
	border-width: 1px;
	background-color: $color-neutral-light;
	font-size: $font-size-md;

	.icon {
		color: black;
		width: 20px;
		height: 20px;
	}
}

.active-chip {
	background-color: $color-main-90;
	border-radius: 30px;
	font-size: $font-size-lg;
	color: $color-main;
	font-weight: $font-weight-semibold;

	.icon {
		color: $color-main;
	}
}

.inactive-chip {
	background-color: $color-neutral-95;
	border-radius: 30px;
	font-size: $font-size-lg;
	color: $color-neutral-60;
	font-weight: $font-weight-semibold;

	.icon {
		color: $color-neutral-60;
	}
}
