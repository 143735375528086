.visitor-invitations {
	&-wrapper {
		.outlook-integration-info-wrapper {
			background-color: $color-blue-10;
			padding: $default-spacing;
			border-radius: 10px;
			margin: $default-spacing;
			@include sm-box-shadow;
			display: flex;
			align-items: center;

			.text-info {
				.header {
					display: flex;
					align-items: center;
					font-weight: bold;
					margin-bottom: 5px;

					.icon {
						margin-right: 10px;
					}
				}
				p {
					margin: 0;
				}
			}

			.action-wrapper {
				min-width: 50px;
				height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
				background-color: $color-main-0;
				margin-left: $default-spacing;
			}
		}

		.button-wrapper {
			display: flex;
			justify-content: center;
			margin: $default-spacing $default-spacing 50px;
		}
	}

	&-header {
		text-transform: uppercase;

		&-wrapper {
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: space-between;
			margin: $default-spacing $default-spacing 0 $default-spacing;

			.show-all {
				padding: 0;

				.icon {
					width: 18px;
					height: 18px;
				}
			}
		}
	}

	&-quantity {
		text-transform: initial;
		font-weight: $font-weight-light;
		font-size: $font-size-xsm;
		padding-left: 7px;
		vertical-align: middle;
		display: inline-block;
	}

	&-show-all {
		font-size: $font-size-md;
		color: $color-main;
		font-weight: bold;
		cursor: pointer;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		p {
			margin: 0;
		}

		.icon {
			width: 12px;
		}
	}

	&-list {
		list-style: none;
		display: flex;
		align-items: flex-start;
		padding: $default-spacing;
		overflow-x: scroll;

		.loading-spinner {
			display: flex;
			justify-content: center;
		}

		.no-visitors {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-flow: column;
			width: 100%;

			&-image {
				width: 150px;
			}

			&-info {
				font-weight: bold;
				color: $color-main-0;
			}
		}
	}

	&-button {
		align-items: center;
		display: flex;
		font-weight: $font-weight-normal;
		padding: 5px 10px;
		letter-spacing: $letter-spacing-md;
		background-color: $color-main-0;
		border-color: $color-main-0;

		.fa-user-plus {
			padding-right: 5px;
			color: $color-neutral-light;
		}
	}

	&-item {
		display: flex;
		align-items: flex-start;
		flex-flow: column;
		justify-content: flex-start;
		border-radius: 15px;
		box-shadow: 4px 4px 20px rgb(0 0 0 / 10%);
		padding: $spacing-m;
		min-width: 300px;
		margin-right: $spacing-m;
		text-align: center;
		background-color: $color-neutral-light;
		min-height: 144px;

		&-initials {
			width: 50px;
			height: 50px;
			background-color: $color-neutral-95;
			color: $color-neutral-60;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			text-transform: uppercase;
			margin-bottom: $default-spacing;
		}

		&-summary {
			font-size: $font-size-mlg;
			font-weight: bold;
			margin: 0;
			margin-bottom: 4px;
			word-break: break-word;
			text-align: left;
		}

		&-office {
			font-size: $font-size-md;
			font-weight: $font-weight-normal;
			color: $color-main;
			margin: 0;
			margin-bottom: $spacing-m;
		}

		&-time {
			font-size: $font-size-md;
			font-weight: $font-weight-normal;
			margin: 0;
			margin-bottom: $spacing-xxs;

			span {
				font-weight: bold;
			}
		}

		&-date,
		&-area {
			color: $color-neutral-60;
			font-size: $font-size-sm;
			margin: 0;
		}

		.colleague-photo-container {
			position: relative;
			height: 35px;
		}

		.colleague-photo,
		.colleague-number {
			background-color: $color-neutral-95;
			color: $color-text;
			border: 2px solid white;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			text-transform: uppercase;
		}

		.action-icon {
			display: flex;
			justify-content: flex-end;
			width: 100%;
		}
	}
}
.unscheduled-item {
	background-color: rgba(#9b8dc7, 0.3);
}
