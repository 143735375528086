.tour-start {
	&-page {
		height: 100%;
		width: 100%;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-content: center;
	}

	&-headline {
		margin-top: 10%;
		margin-left: 15%;
		margin-right: 15%;
		letter-spacing: 5px;

		&-text {
			margin: 0;
			font-size: $line-height-xlg;
			color: $color-main;
			font-weight: $font-weight-bold;
		}
	}

	&-image {
		margin-top: 2 * $default-spacing;
		background-image: url('../../../image/start_tour_image.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		height: 30%;
	}

	&-description {
		margin: 2 * $default-spacing;
		line-height: $line-height-lg;

		&-headline {
			margin: 0;
			font-size: $font-size-lg;
			font-weight: $font-weight-bold;
		}

		&-text {
			margin: 0;
			font-size: $font-size-lg;
			color: $color-secondary-text;
		}
	}
	&-buttons {
		display: flex;
		flex-flow: column;
		align-items: center;

		.btn {
			@extend .btn-primary;
		}
		&-skip {
			margin-top: $default-spacing;
			color: $color-main;
			font-size: $font-size-md;
			cursor: pointer;
		}
	}
}
