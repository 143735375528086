.search-input {
	padding-left: 30px;
	padding-right: 40px;
	box-sizing: border-box;
	border-radius: 20px;

	&-wrapper {
		position: relative;
		display: flex;
		align-items: center;

		.delete-icon {
			position: absolute;
			right: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: $color-neutral-dark;
			border-radius: 50%;
			width: 20px;
			height: 20px;
			color: $color-neutral-light;
			top: 8px;
		}
	}

	&-icon {
		position: absolute;
		z-index: 1;
		margin-left: 10px;
		width: 15px;
		fill: $color-neutral-95;
	}

	&-back-icon {
		position: absolute;
		z-index: 1;
		margin-left: 10px;
		padding: 5px 0;
		width: 20px;

		.icon {
			width: 10px;
		}
	}
}
