.page-bottom-menu {
	align-items: center;
	background-color: $background-color-main;
	bottom: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	padding-top: $default-spacing;
	position: absolute;
	width: 100%;
	z-index: 3;
	@include md-box-shadow;

	&:after {
		border: 6px solid $color-neutral-light;
		border-radius: 50%;
		box-shadow: 0 -5px 5px -5px rgb(0 0 0 / 20%);
		content: "";
		height: 56px;
		left: 0;
		margin: 0 auto;
		position: absolute;
		right: 0;
		top: -26px;
		width: 56px;
	}

	.options-wrapper {
		display: flex;
		width: 48%;
	}

	.option {
		text-align: center;
		width: 100%;

		&.profile-option {
			position: relative;

			.new-notifications {
				align-items: center;
				background-color: $color-red-50;
				border-radius: 50%;
				color: $color-neutral-light;
				display: flex;
				font-size: $font-size-sm;
				height: 8px;
				justify-content: center;
				padding: 5px;
				text-align: center;
				width: 8px;
				&-minimum-size {
					font-size: 8px;
				}
			}

			.icon-invalid-certificate,
			.new-notifications {
				position: absolute;
				right: 15px;
				top: -10px;
			}

			.icon-invalid-certificate {
				height: 20px;
				width: 20px;
			}
		}

		&.bookings-option {
			margin-right: 20px;
			position: relative;
			.new-notifications {
				align-items: center;
				background-color: $color-red-50;
				border-radius: 50%;
				color: $color-neutral-light;
				display: flex;
				font-size: $font-size-sm;
				height: 8px;
				justify-content: center;
				padding: 5px;
				text-align: center;
				width: 8px;
				&-minimum-size {
					font-size: 8px;
				}

				position: absolute;
				right: 15px;
				top: -10px;
			}
		}

		&.reportProblem-option {
			margin-left: 20px;
		}

		&.qr-code-option {
			align-items: center;
			background: $color-main;
			border-radius: 50%;
			display: flex;
			height: 56px;
			justify-content: center;
			left: 0;
			margin: 0 auto;
			position: absolute;
			right: 0;
			top: -20px;
			width: 56px;
			z-index: 500;

			&.disabled {
				background: $color-neutral-dark;
				border: $color-neutral-dark;
				opacity: 1;
				pointer-events: none;
				cursor: not-allowed;
			}
		}

		p {
			color: $color-text;
			cursor: pointer;
			font-size: $font-size-md;
			font-weight: 500;
			margin: 5px 0;

			@media all and (max-width: 330px) {
				font-size: 13px;
			}
		}

		.icon-active,
		&:active .icon,
		&:active p,
		.active {
			color: $color-main !important;
		}

		.disabled {
			color: $color-disabled-state;
		}

		.purple-bar {
			background-color: $color-main;
			height: 4px;
			margin: 0 100% $default-spacing 100%;
			-webkit-transition: margin 0.3s linear;
			-moz-transition: margin 0.3s linear;
			-o-transition: margin 0.3s linear;
			transition: margin 0.3s linear;
		}

		.active-bar {
			margin: 0 $default-spacing $default-spacing;
		}
	}
}
