.check-availability-page {
	height: 100%;
	display: flex;
	flex-flow: column;

	.selected-date {
		padding: $default-spacing;
		margin: 0;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	}

	.button-wrapper {
		display: flex;
		justify-content: center;
		padding: $default-spacing 0;
		box-shadow: 0px -4px 4px rgb(0 0 0 / 10%);
	}
}
