.bookings-wrapper {
	height: 100%;
	display: flex;
	flex-flow: column;
	background-color: $color-neutral-95;

	.bookings-filters {
		display: flex;
		justify-content: space-between;

		[class*='-container'] {
			font-size: 14px;

			@media all and (max-width: 385px) {
				width: 155px;
				font-size: 12px;

				[class*='-option'] {
					font-size: 12px;
				}
			}

			@media all and (max-width: 340px) {
				width: 138px;
				font-size: 11px;

				[class*='-ValueContainer'] {
					padding: 2px;
				}

				[class*='-option'] {
					font-size: 11px;
				}
			}
		}
	}

	.FilterByOrder {
		width: 150px;

		[class*='-singleValue'] {
			display: none;
		}
	}

	.filter-label {
		display: flex;
		padding-bottom: 5px;

		@media all and (max-width: 385px) {
			font-size: 12px;
		}
	}

	.booking-container {
		background: $color-neutral-light;
		padding: 10px 20px;
	}

	.bookings-content {
		display: flex;
		overflow: auto;
		box-sizing: border-box;
		margin-top: 15px;

		.hidden {
			display: none;
		}

		[class*='-control'] {
			border: none;
			box-shadow: none;
			background-color: $color-neutral-95;
		}

		.sorting-icon {
			background-color: $color-neutral-95;
		}

		.checkins-wrapper {
			width: 100%;
			margin: 0 20px;
		}

		.historical-bookings-wrapper,
		.checkins-wrapper,
		.next-bookings-wrapper {
			.bookings-filters {
				width: 100%;
				display: flex;
				justify-content: flex-end;
			}

			.date-header {
				font-weight: bold;
				margin: $spacing-m 0;
				font-size: 20px;

				span {
					color: $color-neutral-60;
				}
			}
		}

		.next-bookings-wrapper {
			margin: 0 20px;
			width: 100%;

			.next-bookings-content {
				height: 80%;

				.next7days-info {
					margin: 0 0 0 5px;
					color: $color-secondary-text;
				}

				.booking-item-card {
					margin: 5px 3px;
				}
			}
		}

		.historical-bookings-wrapper {
			overflow: auto;
			width: 100%;

			.loader-wrapper-end-list {
				margin-bottom: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.infinite-content {
				margin: 0 20px;

				.historical-bookings-content {
					height: 80%;
					padding-bottom: 25px;
					box-sizing: border-box;

					@media all and (max-width: $breakpoint-sm) {
						padding-bottom: 60px;
					}

					.booking-item-card {
						margin: 5px 3px;
					}
				}
			}
		}
	}

	.image-no-bookings {
		display: block;
		background-image: url('../../../image/no-bookings.svg');
		background-repeat: no-repeat;
		background-position: center;
		width: 250px;
		height: 250px;
		margin-left: auto;
		margin-right: auto;

		@media all and (max-width: 400px) {
			width: 200px;
			height: 200px;
			background-size: contain;
		}

		@media all and (max-width: 375px) and (min-height: 800px) {
			width: 250px;
			height: 250px;
			background-size: contain;
		}

		@media all and (max-width: 340px) {
			width: 100px;
			height: 100px;
		}
	}

	.text-no-bookings {
		font-weight: 700;
		text-align: center;
		color: $color-main;
		margin: 0 auto;
		padding-bottom: 15px;
		max-width: 250px;
	}
}

.placeholder-list-items {
	&-wrapper {
		width: 100%;
		padding: $spacing-m;

		.ph-item {
			border-radius: 10px;
			margin-bottom: $spacing-m;
			height: unset;
		}

		.ph-item-wrapper {
			&-photo {
				width: 20%;
			}
			&-details {
				width: 80%;
			}
		}
	}
}
