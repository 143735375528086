.area-list {
	&-page {
		height: 100%;

		.secondary-header-wrapper {
			height: 10%;
			box-shadow: 0 4px 18px rgb(0 0 0 / 12%);
		}

		&-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			background: $color-neutral-light;
			height: 75%;
			overflow-y: auto;
			overflow-x: hidden;
			margin-top: $default-spacing;
			padding: 0 10px $default-spacing;
			box-sizing: border-box;
		}

		&-button-container {
			background: $color-neutral-light;
			position: absolute;
			bottom: 0;
			filter: drop-shadow(4px 0 24px rgba(0, 0, 0, 0.12));
			width: 100%;
			align-items: center;
			justify-content: center;
			display: flex;
			max-width: 450px;
			height: 10%;

			.btn-primary {
				width: 90%;
			}
		}

		&-item {
			align-items: center;
			justify-content: flex-start;
			display: flex;
			flex-direction: row;
			padding: 16px;
			width: 100%;
			height: 25px;
			border-bottom: 1px solid $color-neutral-90;
			position: relative;

			&-icon-angle {
				width: 15px;
				height: 15px;
				margin-left: auto;
			}

			&-icon-check {
				position: absolute;
				right: $default-spacing;
			}

			&-quantity {
				padding: 5px;
				box-sizing: border-box;
				width: 40px;
				height: 24px;
				border-radius: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: $font-weight-normal;
				font-size: $font-size-md;
				line-height: $line-height-sm;
				margin-right: 8px;

				&.active {
					background: $color-green-95;
				}

				&.inactive {
					background: $color-neutral-95;
				}
			}
		}

		.back-button {
			min-width: 80px;
		}

		.placeholder-container {
			width: 40px;
			margin-right: 10px;

			.ph-item,
			.ph-row,
			.ph-col-12 {
				padding: 0;
				margin: 0;
				border: none;
				width: 40px;
				height: 24px;
				border-radius: 32px;
			}
		}
	}
}
