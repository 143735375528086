.icon {
	width: 25px;
	height: 25px;
	color: $color-disabled-state;

	&-purple {
		color: $color-main;
		stroke: $color-main;
	}

	&-border-gray {
		color: $color-neutral-80;
		stroke: $color-neutral-80;
	}
	&-favorite {
		fill: $color-neutral-light;
		stroke: $color-main;
	}

	&-active-favorite {
		fill: $color-main;
	}

	&-inactive-favorite {
		stroke: $color-main;
		fill: none;
	}

	&-red-active-favorite {
		fill: $color-red-50;
	}

	&-red-favorite {
		fill: $color-neutral-light;
		stroke: $color-red-50;
	}

	&-yellow {
		color: $color-yellow-50;
		stroke: $color-yellow-50;
	}

	&-black {
		color: $color-neutral-dark;
		stroke: $color-neutral-dark;
	}

	&-black-privacy-dropdown {
		color: $color-neutral-dark;
		stroke: $color-neutral-dark;
		width: 20px;
		height: 20px;
	}

	&-blue {
		color: $color-blue-20;
		stroke: $color-blue-20;
	}

	&-white {
		color: $color-neutral-light;
		stroke: $color-neutral-light;
	}

	&-green {
		color: $color-green-50;
		stroke: $color-green-50;
	}

	&-magenta {
		color: $color-red-60;
		stroke: $color-red-60;
	}

	&-fill-black {
		fill: $color-neutral-dark;
	}

	&-fill-purple {
		fill: $color-main;
	}

	&-fill-red {
		fill: $color-red-50;
	}

	&-desk-stroke {
		stroke: $color-neutral-60;
	}

	&-red-stroke,
	&-red-desk-stroke {
		stroke: $color-red-50;
	}

	&-active-desk-fill {
		fill: $color-neutral-light;
	}

	&-booking-desk-fill {
		fill: $color-main;
	}

	&-booking-desk-stroke {
		stroke: $color-main;
	}

	&-desk-fill {
		fill: $color-neutral-60;
	}

	&-default-stroke {
		stroke: $color-red-80;
	}

	&-default-fill {
		fill: $color-red-80;
	}

	&-next-bookings-desk-stroke,
	&-room-stroke {
		stroke: $color-neutral-60;
	}

	&-room-fill {
		fill: $color-red-80;
	}

	&-rose {
		color: $color-default-icon;
		stroke: $color-default-icon;
	}

	&-red {
		color: $color-red-50;
		stroke: $color-red-50;
	}

	&-open {
		transform: rotate(180deg);
	}

	&-disabled {
		color: $color-disabled-state;
		stroke: $color-disabled-state;
	}

	&-main-theme-color,
	&-active {
		color: $color-main;
		stroke: $color-main;
	}

	&-lightMagenta {
		color: $color-red-80;
		stroke: $color-red-80;
	}

	&-error {
		color: $color-error;
		stroke: $color-error;
	}

	&-gray {
		color: $color-neutral-60;
		stroke: $color-neutral-60;
	}

	&-light-gray {
		color: $color-neutral-90;
	}

	&-medium-gray {
		color: $color-neutral-80;
	}
}

.size-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: $font-size-md;
	border-width: 2px;
	border-style: solid;
	border-radius: 5px;
}

.custom-icon {
	width: 25px;
	height: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.booking-icon-stroke {
	stroke: $color-main;

	&.active {
		stroke: $color-neutral-light;
	}
}

.booking-icon.active .icon-booking-desk-fill {
	fill: $color-neutral-light;
}
