.new-visitor-page {
	height: 100%;
	padding-bottom: 120px;
	box-sizing: border-box;
	overflow-y: auto;

	&-form {
		margin: 0 $spacing-s;
		overflow-y: scroll;

		.primary-input {
			margin-top: $spacing-lg;
		}

		.button-wrapper {
			height: 10%;
			display: flex;
			justify-content: center;

			button {
				width: 90%;
				text-transform: capitalize;
			}
		}
	}
}
