.modal-card .emergency-card {
	margin: $default-spacing auto;
	width: 90%;

	.confirmation-wrapper {
		//text-align: center;
		display: flex;
		flex-flow: column;
		justify-content: center;
		padding: $default-spacing;

		.custom-icon {
			width: auto;

			svg {
				width: 60px;
				height: 60px;
				margin: auto;
			}
		}

		.buttons-wrapper {
			.button {
				margin-bottom: 5px;
				font-weight: $font-weight-bold;

				&.green {
					background: $color-green-95;
					border-color: $color-green-95;
					color: $color-green-50;
				}

				&.red {
					background: $color-red-95;
					border-color: $color-red-95;
					color: $color-gradient-first;
				}
			}
		}
	}

	&-header {
		font-size: $font-size-xlg;
		font-weight: $font-weight-bold;
		line-height: $line-height-xlg;
		text-transform: capitalize;
		text-align: center;
		margin-bottom: 0;
	}

	&-message {
		ol {
			text-align: left;
		}
	}
}
