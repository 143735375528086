.content-switcher {
	color: $color-main;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	border: 2px solid $color-main;
	box-sizing: border-box;
	border-radius: 8px;
	margin: 20px 0;
	overflow: hidden;

	&-item {
		padding: 12px;
		display: flex;
		width: 100%;
		justify-content: center;
		font-size: $font-size-md;
		line-height: $line-height-sm;
		font-weight: $font-weight-medium;
		text-align: center;

		@media all and (max-width: 330px) {
			font-size: 13px;
		}

		&:first-child,
		&:nth-child(2) {
			border-right: 2px solid $color-main;
		}
	}

	.active {
		background-color: $color-main;
		color: $color-neutral-light;
	}
}
