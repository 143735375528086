.time-tracker-overview {
	&-page {
		height: 80%;
		background-color: $color-neutral-95;
		display: flex;
		flex-flow: column;

		&-no-times {
			margin-top: 15%;
			display: flex;
			flex-flow: column;
			align-items: center;
			text-align: center;

			p {
				margin: $default-spacing;
				max-width: 80%;
			}

			.bold {
				font-size: $font-size-mlg;
			}
		}
		.tab-content {
			padding-top: 3rem;
		}

		.switcher-container {
			padding: 1rem 0;
			margin: 0;
		}
	}
	&-tabs {
		overflow-y: auto;
		scrollbar-width: none;
		-ms-overflow-style: none;
	}
	&-content-no-times {
		align-items: center;
		display: flex;
		flex-flow: column;
		margin-top: 15%;
		text-align: center;
	}
	&-header {
		background-color: $color-neutral-light;

		.day-selection {
			margin-bottom: $padding-xxs !important;
			.day-item-wrapper {
				min-height: 45px;
			}
		}
	}
}
.on-top {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	box-sizing: border-box;
	min-height: unset;
	padding: 1rem 1rem;
	height: 20%;
  }
