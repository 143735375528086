.tabs-container {
    .MuiPaper-root {
        position: absolute;
        z-index: 1;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .tab-content {
        padding-top: 1rem;
    }
}

.app-bar {
    background-color: $color-main-60;
}

.tabs-header {
    display: flex;
    flex-direction: column; // Stellt sicher, dass zusätzliche Header unter den Tabs angezeigt wird
}

.additional-header {
    background-color: #f5f5f5; // Hintergrundfarbe nach Bedarf anpassen
}

.MuiTabs-root {
    .MuiTabs-indicator {
        background-color: white;
    }
}
