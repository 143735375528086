.next-booking {
	&-container {
		&-no-data {
			.next-booking-description {
				max-width: 150px;
			}
		}

		.icon-favorite {
			width: 24px;
			height: 24px;
		}

		.ph-item {
			margin: $default-spacing;
			height: 120px;
			background-color: $color-neutral-light;
			display: flex;
			border-radius: 10px;
			line-height: 14px;
			@include sm-box-shadow;

			.ph-col-12 {
				border-radius: 10px;
			}
		}

		.disabled {
			pointer-events: none;
			opacity: 0.5;
			color: $color-disabled-state !important;
		}

		.fa-angle-right {
			padding-left: 5px;
			padding-right: 5px;
		}
	}

	&-list {
		display: flex;
		overflow: scroll;
	}
	&-health-questionnaire-info {
		border-radius: 10px;
		box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
		font-size: $font-size-sm;
		margin: 0 $default-spacing;
		background-color: $color-yellow-90;
		color: $color-secondary-text;

		.notification-holder {
			display: flex;
			flex-direction: column;
			position: relative;

			&-link {
				display: flex;
				flex-direction: row;
				align-items: center;
			}

			&-header {
				font-size: 18px;
				color: $color-main-0;
				font-weight: $font-weight-bold;
				margin: 0;
				padding: $default-spacing;
				padding-bottom: 0;
			}

			&-text {
				font-size: 16px;
				color: $color-main;
				font-weight: $font-weight-normal;
				padding: $default-spacing;
				margin: 0;
				width: 75%;
			}
		}

		.notification-link {
			width: 40px;
			height: 40px;
			margin-bottom: $default-spacing;
			text-decoration: underline;
			border-radius: 50%;
			background: $color-main;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			right: 15px;
			top: 26px;
		}
	}

	&-headerContainer {

		display: flex;
		align-content: center;
		align-items: center;
		justify-content: space-between;
		font-size: $font-size-md;
		width: 100%;

		.btn-wrapper {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-right: $default-spacing;
		}

		.show-all {
			padding: 0px;
			height: auto;

			.icon {
				width: 18px;
				height: 18px;
			}
		}
	}

	&-header {
		margin-left: $spacing-m;
	}
	&-image {
		width: auto;
		height: 100%;
		padding: 0;
		box-sizing: border-box;
	}

	&-status {
		margin-bottom: 14px;
		font-weight: 400;
		position: relative;
		font-size: $font-size-md;
	}

	&-info {
		align-items: center;
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	&-item {
		margin: $spacing-m;
		background-color: $color-neutral-light;
		display: flex;
		border-radius: 10px;
		@include sm-box-shadow;
	}

	&-image-wrapper {
		min-width: 55px;
		height: 57px;
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 15px 12px 12px;
		background: $color-neutral-95;

		.next-booking-image {
			width: 38px;
			height: 38px;
			stroke: $color-neutral-dark;
		}
	}
	&-description {
		text-align: left;
		@extend .font-xsm;
	}

	&-date {
		display: flex;
		flex-flow: column;
		align-items: flex-start;

		p {
			font-size: $font-size-lg;
			line-height: $line-height-lgx;
			margin: 0;
			color: $color-neutral-80;
		}
		span {
			color: $color-neutral-60;
		}
	}

	&-entity-data {
		margin-bottom: 6px;
	}

	.disabled-HQ {
		display: none;
	}

	.countdown-wrapper {
		text-align: right;
		margin-right: $default-spacing;
		margin-top: 5px;
		margin-bottom: $default-spacing;
	}
}
