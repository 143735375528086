.image-loader {
	.report-problem-warning {
		color: $color-red-60;
		font-size: $font-size-md;
		margin: $default-spacing 0;
	}

	.report-problem-close-icon {
		font-size: 30px;
		margin-left: 10px;
	}

	.report-problem-images-list {
		border-radius: 10px;
		background: $color-neutral-95;
		padding: 16px;
		max-width: 90%;
		margin: 5px 24px auto;
		box-sizing: border-box;
	}

	.report-problem-image-item {
		display: flex;
		align-items: center;
	}

	.report-problem-image-name {
		max-width: 90%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.report-problem-add-more {
		display: inline-block;
		cursor: pointer;

		.icon {
			margin-right: 5px;
			vertical-align: middle;
		}
	}

	.report-problem-quantity {
		color: $color-neutral-60;
		font-size: $font-size-sm;
		border-bottom: 1px solid $color-neutral-60;
		padding-bottom: $default-spacing;
	}

	.common-area-problem-hint {
		font-size: 12px;
		opacity: 0.5;
		margin-top: $default-spacing;

		&-header {
			margin: 0;
		}

		&-list {
			margin-top: 5px;
		}
	}

	.button-wrapper {
		display: flex;
		justify-content: center;
	}
}
