.our-areas {
	&-wrapper {
		margin: 20px 0;

		&.placeholder-loading {
			height: 120px;
		}

		.ph-item {
			padding-left: 0;
			padding-top: 10px;
			border: none;
		}

		.ph-picture {
			height: 100px;
			border-radius: 15px;
		}

		.ph-col-4 {
			padding-right: 5px;
			padding-left: 5px;
			margin-bottom: 0;
		}
	}

	&-header {
		margin-bottom: 10px;
		@extend .heading;
		@include horizontal-line-left;

		&-info {
			text-transform: initial;
			font-weight: 300;
			font-size: 10px;
			padding-left: 7px;
			vertical-align: middle;
			display: inline-block;
		}
	}
}
