.header-wrapper-cgi {
    z-index: 1;
    position: sticky;
    top: 0;
    width: 100%;

    background-color: $color-neutral-light;
    margin-bottom: $default-spacing;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header-buttons {
            display: flex;
            padding: 0px $spacing-m;
            padding-top: 3px;
            gap: $spacing-xs;
            .header-button {
                border: solid var(--color-neutral-light) 2px;
                border-radius: 50%;
                width: 50px;
                height: 50px;
            }

            .office-info-button {
                background-color: $color-main;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .profile-button {
                img {
                    border-radius: 50%;
                    height: 100%;
                    object-fit: contain;
                    width: 100%;
                }
            }
        }
    }

    .action-buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 67px;
        box-shadow: 0 8px 14px 0px rgba(0, 0, 0, 0.2);
        .office-selector-redirect-cgi {
            background-color: $color-neutral-95;
            font-size: $font-size-mlg;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            padding: $default-spacing;
            .chevron-right {
                height: 15px;
            }
        }
        .start-booking {
            height: 100%;
            border-radius: 0px;
        }
    }
}
