.sensor-info {
	display: flex;

	&-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-evenly;

		&:first-of-type {
			margin-right: 12px;
		}
	}

	.checkin-information &-icon,
	&-icon {
		width: auto;
		height: 16.77px;
	}

	&-data {
		font-size: $font-size-sm;
	}

	.next-booking-info & {
		position: absolute;
		bottom: 5px;
	}
}
