.select-area {
	border-top: 4px solid $color-neutral-95;
	padding: 24px 16px 0 16px;
	margin-bottom: 0;
	position: static;

	&-header-wrapper {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		.show-all {
			padding: 0 0 $default-spacing 0;

			.icon {
				width: 18px;
				height: 18px;
			}
		}
	}

	&-header {
		@extend .booking-page-headline;
	}

	&-message {
		color: $color-neutral-80;
		font-size: $font-size-md;
		margin-bottom: 30px;
		margin-top: 0;
		line-height: 1;

		&-error {
			color: $color-red-50;

			&:before {
				content: '\f00d';
				font-family: 'Font Awesome 5 Free', sans-serif;
				font-weight: 900;
				display: inline-flex;
				width: 24px;
				height: 24px;
				color: $color-neutral-light;
				background: $color-red-50;
				border-radius: 50%;
				justify-content: center;
				align-items: center;
				margin-right: 8px;
			}
		}
	}

	&-checked-message {
		color: $color-neutral-dark;
		font-size: $font-size-lg;

		&:before {
			content: '\f00c';
			font-family: 'Font Awesome 5 Free', sans-serif;
			font-weight: 900;
			display: inline-flex;
			width: 24px;
			height: 24px;
			color: $color-neutral-light;
			background: $color-green-50;
			border-radius: 50%;
			justify-content: center;
			align-items: center;
			margin-right: 8px;
		}
	}
}
