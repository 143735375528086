.event-page {
	height: 100%;
	overflow-y: scroll;
	display: flex;
	flex-flow: column;

	.loading-wrapper {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 50px;
	}

	&-header {
		background-color: $color-main;
		background-size: cover;
		background-position: center;
		&-wrapper {
			background: rgba(0, 0, 0, 0.25);
			position: relative;
			height: 200px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		p {
			margin: 0;
		}

		.event-header {
			font-size: 30px;
			font-weight: $font-weight-bold;
			color: white;
			text-transform: uppercase;
			font-family: Helvetica;
			font-weight: 100;
			text-align: center;
		}
		.office-info-map-icon {
			position: absolute;
			top: 20px;
			right: 10px;
		}
		.event-participation-card-wrapper {
			height: 60px;
			width: 100%;
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			bottom: -50px;

			.event-participation-card {
				background-color: $color-neutral-light;
				width: 100%;
				height: 100%;
				margin: 0 $spacing-m;
				padding: $spacing-m;
				border-radius: 12px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				@include sm-box-shadow;

				.info {
					display: flex;
					align-items: center;
					p {
						margin-left: 5px;
						font-size: $font-size-mlg;
						font-weight: $font-weight-bold;
					}
				}
			}
		}
	}
	&-content {
		&-header {
			margin: $spacing-m;
			font-size: $font-size-mlg;
			font-weight: $font-weight-bold;
		}
		&-headerContainer {
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: space-between;
			font-size: $font-size-mlg;
			font-weight: $font-weight-bold;

			.btn-wrapper {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-right: $spacing-m;
			}

			.show-all {
				padding: $spacing-m 0 $spacing-m 0;

				.icon {
					width: 18px;
					height: 18px;
				}
			}
		}
		.menimeter-redirect {
			background-color: #afcdfb;
			margin: $spacing-m;
			border-radius: 12px;
			font-size: $font-size-mmlg;
			padding: $spacing-m;
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;

			.icon-wrapper {
				width: 40px;
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: $color-main-30;
				border-radius: 50%;
			}
		}

		.no-sessions {
			display: flex;
			justify-content: center;
			align-items: center;

			p {
				color: $color-main;
			}
		}

		&-other-cards {
			.card-list {
				padding: 0 $spacing-m;
				padding-bottom: $spacing-lg;
				display: grid;
				overflow-x: scroll;
				grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
				gap: $spacing-s;
				&-item {
					flex: 1;
					display: flex;
					justify-content: start;
					align-items: center;
					flex-flow: column;
					@include sm-box-shadow;
					padding: $spacing-m;
					border-radius: 12px;
					font-weight: $font-weight-semibold;
					cursor: pointer;
					text-align: center;
					color: black;
					text-decoration: none;
					.icon-wrapper {
						height: 50px;
					}

					p {
						margin: 0;
					}
					.small-font {
						font-size: $font-size-sm;
					}
					&.wifi {
						grid-column-end: span 2;
					}
				}
			}
		}
	}

	&-info {
		padding: $spacing-m;
		display: flex;
		align-items: center;
		flex-flow: column;
		flex-grow: 2;

		.event-info {
			padding: $spacing-lg 0;
		}

		p {
			margin: 0;
			font-size: $font-size-mlg;
			text-align: center;
		}
		.technology-session {
			display: flex;
			flex-flow: column;
			justify-content: center;
			padding: $spacing-m;
			gap: $spacing-m;

			.welcome-title {
				font-size: $font-size-mmlg;
				font-weight: $font-weight-medium;
				color: $color-main-0;
				margin-bottom: $spacing-md;

				&-info {
					font-size: $font-size-lg;
					font-weight: $font-weight-normal;
				}
			}
			.info-wrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 50px;
				cursor: pointer;

				.info {
					font-size: $font-size-lg;
					font-weight: $font-weight-normal;
					color: $color-main;
					max-width: 250px;
				}
				.redirect-button {
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: $color-main-0;
					border-radius: 50%;
					width: 40px;
					height: 40px;

					.icon {
						width: 25px;
						height: 25px;
					}
				}
			}
			p {
				text-align: left;
				margin: 0;
			}
		}
	}
	.personal-agenda-list-item {
		padding: $spacing-m;
		@include sm-box-shadow;
		border-radius: 12px;
		background-color: $color-neutral-light;
		margin: $spacing-m;

		&-content {
			display: flex;
			flex-flow: column;

			&-header {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.name {
					font-size: $font-size-mmlg;
					font-weight: $font-weight-bold;
					margin: 0;
				}
			}

			&-details {
				margin-top: $spacing-m;
				p {
					line-height: $line-height-lgx;
					margin: 0;
					color: $color-neutral-70;

					span {
						color: $color-neutral-60;
					}
				}
			}
		}
	}
}

.event-session-status-label {
	display: flex;
	justify-content: center;
	padding: 5px 10px;
	font-size: $font-size-sm;
	font-weight: bold;
	width: fit-content;
	border-radius: 5px;

	p {
		margin: 0;
	}
}
.event-session-status-label-inProgress {
	background-color: $color-red-95;
	color: $color-red-50;
}

.event-session-status-label-aboutToStart {
	background-color: $color-green-95;
	color: $color-green-50;
}

.event-session-status-label-finished {
	background-color: $color-neutral-95;
	color: $color-neutral-dark;
}

.event-session-status-label-notStarted {
	background-color: $color-main-90;
	color: $color-main;
}
