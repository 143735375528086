.emergency-info {
	text-align: center;
	color: $color-neutral-60;
	max-height: 70vh;
	overflow-y: scroll;
	padding-top: 5rem;

	.buttons-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.button {
			margin-bottom: $default-spacing;
			font-weight: $font-weight-bold;

			&.green {
				background: $color-green-95;
				border-color: $color-green-95;
				color: $color-green-50;
				border-style: none;
				border-color: $color-green-50;
			}

			&.red {
				background: $color-red-95;
				border-color: $color-red-95;
				color: $color-gradient-first;
				border-style: none;
				border-color: $color-red-50;
			}

			&.border {
				border-style: solid;
				border-width: 4px;
			}
		}
	}

	&-icon {
		fill: $color-neutral-90;
		margin: auto;
		width: 60px;
		height: 60px;

		svg {
			width: 100%;
			height: 100%;
		}
	}

	&-wrapper {
		padding: 30px $spacing-xlg 150px;
	}

	&-header {
		font-size: $font-size-xlg;
		font-weight: $font-weight-bold;
		line-height: $line-height-xlg;
		text-transform: capitalize;
		text-align: center;
	}

	&-description {
		color: $color-main;
	}

	&-message {
		p {
			margin: 0px;
		}
		ol {
			text-align: left;
		}
	}

	.latest-location {
		&-description {
			text-align: start;
		}
		&-input-wrapper {
			display: flex;
			justify-content: space-between;
		}
		&-button {
			border-radius: 5px;
			width: 10%;
			margin-left: 5px;
		}
		&-input {
			width: 100%;
		}
	}
}
