.bookable-session-page {
	height: 100%;
	display: flex;
	flex-flow: column;

	&-content {
		display: flex;
		flex-flow: column;
		flex-grow: 2;
		overflow-y: scroll;

		.loading-wrapper {
			width: 100%;
			display: flex;
			justify-content: center;
		}
		&-title {
			display: flex;
			align-items: center;
			padding: $spacing-m;
			justify-content: space-between;

			p {
				margin: 4px 0;
				color: $color-neutral-60;
				font-size: $font-size-lg;
				line-height: $line-height-md;
			}

			.name {
				font-weight: $font-weight-bold;
			}

			.timeSlot {
				font-weight: $font-weight-normal;
			}

			.numbers {
				color: $color-neutral-60;
			}
		}
		&-bookable-list {
			overflow-y: scroll;
			padding: $spacing-xs $spacing-m;
			flex-grow: 2;
			padding-bottom: 50px;

			&-item {
				padding: $spacing-m;
				margin-bottom: $spacing-xs;
				border-radius: 12px;
				@include sm-box-shadow;
				gap: $spacing-xs;
				display: flex;
				flex-flow: column;

				p {
					margin: 0;
				}
				.title-wrapper {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.title {
						font-size: $font-size-mmlg;
						font-weight: $font-weight-bold;
						color: $color-neutral-60;
					}
				}

				.description {
					white-space: pre-line;
					font-weight: $font-weight-normal;
					color: $color-neutral-60;
					font-size: $font-size-md;
					font-style: italic;
				}

				.capacity {
					font-weight: $font-weight-semibold;
					font-size: $font-size-md;
					padding: 4px 6px;
					width: fit-content;
					border-radius: 4px;

					&-almostFull {
						background-color: $color-yellow-70;
						color: $color-yellow-20;
					}
					&-full {
						background-color: $color-red-95;
						color: $color-red-50;
					}
					&-free {
						background-color: $color-green-95;
						color: $color-green-50;
					}
				}
			}
		}
	}
}
