.share-asset-page {
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;

	&-header {
		width: 100%;
	}
	&-content {
		flex-grow: 2;
		overflow-x: scroll;
		width: 100%;
		&-date-time {
			margin: $spacing-m;
		}
		&-info {
			margin: $spacing-m 0;
			color: $color-main-30;
		}
		&-sharing-rule {
			margin: $spacing-m;
		}
	}
}
.add-specific-colleagues-wrapper {
	.error-info {
		margin: 0 $spacing-m;
	}
	.add-specific-colleagues {
		padding: $spacing-m;
		display: flex;
		align-items: flex-end;

		.primary-input {
			width: 100%;
		}

		.input-full-width {
			margin: 0;
			width: 100%;
		}

		.save-colleague-button {
			display: flex;
			justify-content: center;

			button {
				padding: 0 $spacing-m;
			}
		}
	}
	.specific-colleagues {
		&-list {
			display: flex;
			flex-flow: column;
			gap: $spacing-m;
			padding: 0 $spacing-m;
		}
		&-item {
			padding: $spacing-xs $spacing-m;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border: 1px solid $color-neutral-80;
			border-radius: 12px;

			.close {
				font-size: $font-size-xlg;
			}
		}
	}
}
