.booking-details-wrapper {
	height: 100%;
	display: flex;
	flex-flow: column;

	.container {
		display: flex;
		flex-flow: column;
		overflow: scroll;
		flex-grow: 2;
	}

	hr {
		border: none;
		outline: none;
		min-height: 3px;
		background: $color-neutral-95;
		margin-top: $default-spacing;
		width: 100%;
	}

	.booking-information {
		margin: $padding-s 0;
		padding: 0 $padding-s;
		display: flex;
		flex-flow: column;
		background: white;

		.title {
			color: $color-main;
			font-weight: $font-weight-bold;
			font-size: $font-size-mlg;
			margin: 0 0 $padding-s 0;
		}

		.cancellation-reason {
			display: flex;
			flex-direction: column;

			.reason-title {
				margin: 0 0 5px;
				font-weight: $font-weight-bold;
				align-self: center;
				width: 100%;
				color: $color-heading;
			}

			.reason-text {
				font-weight: normal;
				color: $color-heading;
			}
			margin-bottom: 12px;
		}

		.entity-information {
			display: flex;
			flex-direction: column;
			gap: 12px;

			p {
				margin: 0 0 5px;
			}
			.info-entity {
				font-weight: $font-weight-bold;
				align-self: center;
				width: 100%;
				color: $color-heading;

				span {
					font-weight: normal;
					color: $color-heading;
				}
			}

			.entity-features {
				display: flex;
				flex-wrap: wrap;
			}

			.features-content {
				display: flex;
				margin: 5px 0;
				padding: 5px 0;
				max-height: 190px;
				overflow-x: scroll;
				color: $color-heading;
			}
		}
		.ms-teams-container {
			border-top: 4px solid $color-neutral-95;
			padding: 1rem 0;
			.title {
				color: $color-main;
				font-weight: $font-weight-bold;
				font-size: $font-size-mlg;
				margin-top: $padding-s;
			}
			.link-section {
				padding: $padding-s 0;
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 1rem;
				img {
					width: 29px;
				}
				a {
					color: $color-heading;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					text-decoration-line: underline;
				}
			}
		}
	}

	.add-people-section {
		padding: 0;
		padding-top: $padding-s;
		.bold {
			font-weight: bold;
		}

		.add-more-button {
			width: 100%;
			display: flex;
			align-items: center;

			.plus-sign {
				margin-right: $default-spacing;
				width: 20px;
				height: 20px;
				color: $color-neutral-light;
				font-size: $font-size-lg;
				background-color: $color-neutral-80;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}

	.pine-invitation-details,
	.pine-invitation-summary {
		padding: 0 $padding-s $padding-s $padding-s;
		display: flex;
		flex-flow: column;
		background: white;

		.attendee-item {
			margin: 0;
		}

		.info-meeting-details {
			white-space: pre-wrap;
			word-break: break-all;
		}
	}

	.recurring-booking-check {
		margin: 0 $default-spacing;
	}

	.buttons-wrapper {
		display: flex;
		align-items: center;
		flex-flow: column;
		position: sticky;
		top: 100%;
		margin-bottom: 30px;
		width: 100%;

		.button {
			margin-bottom: $default-spacing;
			width: 60%;
		}
	}

	//see details of booking page
	.added-people-wrapper {
		border-top: 4px solid $color-neutral-95;
		padding-top: $default-spacing;
		margin-top: $default-spacing;

		.list-wrapper {
			display: flex;
			flex-flow: column;
			overflow-y: scroll;
			max-height: 250px;
		}
	}

	.recurring-booking-info {
		padding: 0 $default-spacing;

		.info-section {
			background-color: $color-main-80;
			display: flex;
			align-items: center;

			.icon {
				margin: 0 10px;
			}
		}
	}
}
