.checkInQuestionnaire-page {
	background-color: $color-neutral-light;
	width: 100%;
	height: 100%;
	padding-bottom: 20px;

	&-header {
		text-align: center;

		@extend .font-xlg;
		@include horizontal-line-at-center;

		p {
			margin-top: $default-spacing;
			margin-bottom: 5px;
			font-size: 20px;
		}
	}

	.back {
		padding-left: 20px;
		padding-top: 10px;
		display: flex;
		align-items: center;
		margin: 0;
		text-transform: uppercase;
		font-size: 14px;
		letter-spacing: 1.5px;
		font-weight: 600;

		.icon {
			color: $color-main;
			padding-right: 5px;
			padding-left: 0;
			font-size: $font-size-sm;
		}
	}

	.questionnaire-wrapper {
		position: absolute;
		width: 100%;
		height: 70%;
		margin-top: 20px;

		&-content {
			background-color: $color-neutral-light;
			padding: 0 70px;
			display: flex;
			flex-direction: column;
			height: 100%;
			overflow-y: scroll;
		}
		&-header {
			margin-bottom: 20px;
			@extend .heading;
		}

		&-answerCheckInQuestionnaire {
			margin-top: 20px;
			font-weight: $font-weight-bold;
			font-size: $font-size-md;
			line-height: $line-height-lg;
			color: $color-neutral-dark;
		}

		&-questionnaireLink {
			margin-top: 12px;
			font-size: 14px;
			line-height: $line-height-lg;
			color: $color-main;
			margin-bottom: 40px;
		}

		&-text {
			display: inline-block;
			width: 90%;
			vertical-align: text-top;
		}

		&-button {
			text-transform: uppercase;
			width: 231px;
			height: 40px;
			padding: 10px 0;
			font-weight: 500;
			margin: 0 auto;

			@media all and (max-width: $breakpoint-xxs) {
				width: 150px;
				height: 35px;
			}

			@media all and (max-width: $breakpoint-xs) {
				width: 180px;
			}
		}

		&-list {
			padding-left: 0;

			&-item {
				list-style: none;
				display: inline-flex;
				margin-bottom: $default-spacing;
				width: 100%;
			}
		}
	}
}
