.notifications-page {
	display: flex;
	flex-flow: column;
	height: 100%;

	.search-input-wrapper {
		margin: $padding-sml $default-spacing;
	}

	.notifications-amount {
		display: flex;
		align-items: center;
		margin: 5px $default-spacing;

		.icon-wrapper {
			position: relative;
			margin-right: $default-spacing;

			.red-dot {
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background-color: $color-red-50;
				position: absolute;
				right: -1px;
				top: 5px;
			}
		}
	}

	.notifications-select {
		text-align: right;
		margin-top: $default-spacing;
		margin-right: $default-spacing;
	}

	.notifications-selectAll {
		color: $color-main;
		font-weight: 700;
		margin-top: $default-spacing;
		margin-left: $default-spacing;
	}

	.loading-wrapper {
		display: flex;
		justify-content: center;
		margin-top: 10%;
	}

	.notifications-header {
		margin-top: $default-spacing;
		background-color: $color-neutral-95;
	}

	.notifications-selectButtons {
		display: flex;
		flex-flow: row;
		justify-content: space-between;
	}

	.notifications-wrapper {
		background-color: $color-neutral-95;
		height: 100%;
		overflow-y: scroll;
	}

	.no-notification-wrapper {
		margin: $default-spacing;
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		height: 80%;

		p {
			text-align: center;
			color: $color-neutral-60;
			margin: 0 0 $default-spacing;
			font-size: $font-size-lg;
		}

		.bold {
			font-size: $font-size-mlg;
			font-weight: bold;
		}
	}

	.notifications-list {
		display: flex;
		flex-flow: column;
		overflow-y: auto;

		.notification-item-wrapper {
			margin: 6px $default-spacing;
			padding: $default-spacing;
			display: flex;
			@include sm-box-shadow;
			min-height: 60px;
			background-color: $color-neutral-light;
			border-radius: 12px;
			justify-content: space-between;

			.notification-status {
				margin-left: 40px;
				display: flex;
				flex-flow: row-reverse;

				.unread-icon {
					width: 8px;
					height: 8px;
					border-radius: 50%;
					background-color: $color-red-50;
				}
			}

			.notification-content {
				width: 90%;

				p {
					margin: 0;
				}

				.bold {
					font-weight: bold;
				}

				.text-info {
					margin-top: 5px;
					color: $color-main;
				}

				.text-info {
					margin-top: 5px;
					color: $color-main;
				}

				.timestamp {
					color: $color-neutral-60;
					margin-top: 10px;
					font-size: $font-size-md;
				}
			}
		}
	}

	.delete-btn-container {
		background-color: $color-neutral-light;
		height: 12%;
		bottom: 0;
		filter: drop-shadow(4px 0px 20px rgba(0, 0, 0, 0.1));
		z-index: 2;
		display: flex;
		justify-content: center;
		padding: $default-spacing 0px;

		.delete-btn {
			background-color: $color-status-fully-booked;
			height: 48px;
			border: none;
			font-size: $font-size-lg;
			width: 100%;
		}
	}

	.delete-btn-container-hidden {
		height: 0;
	}
}
