// style placeholder
%list-item {
	display: flex;
	box-sizing: border-box;
	background-color: $color-neutral-light;
	border-radius: 10px;
	line-height: $line-height-xsm;
	@include sm-box-shadow;
	padding: $spacing-m;
	margin: $spacing-s 0 0;

	&-no-features {
		color: $color-main;
		font-weight: $font-weight-bold;
		line-height: $line-height-sm;
		font-size: $font-size-md;
	}

	&-yellow-text {
		color: $color-yellow-50 !important;
	}

	&-more-features {
		font-size: $font-size-md;
		color: $color-neutral-80;
		line-height: $line-height-sm;
		margin-left: $spacing-xs;
	}

	&-action-wrapper {
		margin-left: auto;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&-action-icon {
		width: 20px;
		height: 20px;
		margin-right: 0;
		align-self: flex-end;
	}

	&-user-icon {
		width: 20px;
		height: 20px;
	}

	&-occupancy {
		margin-right: $spacing-s;
		margin-bottom: $spacing-xxs;
		position: relative;
		display: flex;
		align-items: center;

		&:after {
			content: '';
			display: flex;
			width: 1px;
			height: 20px;
			background: $color-neutral-90;
			position: absolute;
			top: 0;
			right: 0;
		}

		&-number {
			margin-right: $spacing-s;
			font-size: $font-size-lg;
			line-height: $line-height-md;
			font-weight: $font-weight-semibold;
			margin-left: $spacing-xxs;
		}
	}

	&-area-name {
		font-size: $font-size-lg;
		line-height: $line-height-lgx;
		width: 100%;
		color: $color-neutral-80;
		font-weight: $font-weight-semibold;
	}

	&-name {
		color: $color-neutral-60;
		font-weight: $font-weight-bold;
		font-size: $font-size-mmlg;
		line-height: $font-size-xxlg;
		width: 100%;
	}

	&-desc {
		font-size: $font-size-lg;
		line-height: $line-height-lgx;
		width: 100%;
		font-weight: $font-weight-semibold;
		margin-bottom: $spacing-xs;
	}

	&-img-wrap {
		width: 32px;
		height: 32px;
		border-radius: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: $spacing-xs;
		background: $color-neutral-95;
	}

	&-info {
		align-items: center;
		display: flex;
		width: calc(100% - 80px);
		flex-wrap: wrap;
	}

	&-icons {
		display: flex;
		margin-bottom: $spacing-xxs;

		&-wrap {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

// style initialization
.list-item {
	@extend %list-item;

	&-no-features {
		@extend %list-item-no-features;
	}

	&-yellow-text {
		@extend %list-item-yellow-text;
	}

	&-more-features {
		@extend %list-item-more-features;
	}

	&-action-wrapper {
		@extend %list-item-action-wrapper;
	}

	&-action-icon {
		@extend %list-item-action-icon;
	}

	&-user-icon {
		@extend %list-item-user-icon;
	}

	&-occupancy {
		@extend %list-item-occupancy;

		&-number {
			@extend %list-item-occupancy-number;
		}
	}

	&-area-name {
		@extend %list-item-area-name;
	}

	&-name {
		@extend %list-item-name;
	}

	&-desc {
		@extend %list-item-desc;
	}

	&-img-wrap {
		@extend %list-item-img-wrap;

		.custom-icon {
			width: 16px;
			height: 16px;
		}
	}

	&-info {
		@extend %list-item-info;
	}

	&-icons {
		@extend %list-item-icons;

		&-wrap {
			@extend %list-item-icons-wrap;

			.custom-icon {
				width: 20px;
				height: 20px;
				margin-right: $spacing-xs;
			}
		}
	}

	.size-icon {
		color: $color-main;
		margin-right: $spacing-xxs;
		margin-left: $spacing-xxs;
	}
}
