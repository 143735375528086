.statistics-info {
	&-page {
		box-sizing: border-box;
		display: flex;
		flex-flow: column;
		overflow: hidden;
		height: 100%;

		.chart-simple {
			margin-right: 5px;
		}

		.close-button1 {
			margin-right: 0;
		}

		.responsive-radial-bar {
			height: 250px;
			position: relative;

			.loading-wrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
			}
		}

		.radialBar {
			&-wrapper {
				display: flex;
				flex-direction: column;
				align-items: center;
				align-content: center;
				text-align: center;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&-text {
				color: $color-neutral-80;
				font-size: $font-size-md;
				line-height: $line-height-md;
			}

			&-number {
				font-size: 32px;
				font-weight: $font-weight-bold;
				line-height: 39px;
				max-width: 115px;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.statistics-icon {
			border-radius: 4px;
			height: 18px;
			margin-right: 3px;
		}
	}

	&-total-entities,
	&-checkedin-entities,
	&-sub-heading {
		font-size: $font-size-mlg;
		font-weight: $font-weight-bold;
		line-height: $line-height-mlg;
	}

	&-sub-heading {
		margin-top: 0;
		margin-bottom: 5px;
	}

	&-number {
		font-size: $font-size-mlg;
		font-weight: $font-weight-bold;
		line-height: $line-height-mlg;
		max-width: 115px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&-text {
		font-size: $font-size-md;
		line-height: $line-height-md;
		opacity: 0.5;
	}

	&-total-entities {
		opacity: 0.5;
	}

	&-content,
	&-time-wrapper {
		padding: 0 20px 10px;
		overflow-y: auto;
	}

	&-time-wrapper {
		margin-top: 0;
		margin-bottom: 0;
		padding-bottom: 0;
		padding-right: 0;
	}

	&-header {
		margin-bottom: 10px;
		margin-top: 0;
		@extend .main-heading;
	}

	&-wrapper {
		margin-top: 25px;
	}

	&-wrapper,
	&-bookings {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&-bookings {
		box-sizing: border-box;
		padding: $default-spacing;
		background-color: $color-neutral-light;
		border-radius: 10px;
		line-height: 14px;
		align-items: flex-start;
		@include md-box-shadow;
	}

	&-bookings-per-asset {
		margin-top: 20px;
		margin-bottom: 20px;
		overflow: visible;
	}

	&-insights {
		align-items: center;
		display: flex;
		margin-top: 0;
		margin-bottom: 0;
	}

	&-time {
		font-size: $font-size-sm;
		opacity: 0.5;
		margin-left: 10px;
	}

	&-dynamics {
		display: flex;
		height: 24px;
		align-items: center;
		justify-content: center;
		padding: 4px 8px;
		box-sizing: border-box;
		border-radius: 4px;
		margin: 0 0 10px 10px;

		&-wrapper {
			display: flex;
			flex-direction: column;

			.statistics-info-text {
				align-self: flex-end;

				&-gray {
					color: $color-neutral-dark;
					opacity: 1;
				}
			}
		}

		&-blue {
			background: $color-blue-10;

			.symbol-plus-minus {
				color: $color-blue-30;
				font-size: 23px;
				margin-right: 3px;
			}
		}

		&-red {
			background: $color-red-95;

			.statistics-icon {
				color: $color-red-50;
				transform: rotate(-45deg);
			}
		}

		&-green {
			background: $color-green-95;

			.statistics-icon {
				color: $color-green-50;
				transform: rotate(45deg);
			}
		}

		&-error {
			color: $color-neutral-80;
			font-size: 10px;
			background: $color-neutral-90;
			justify-content: space-around;
		}

		.error-icon {
			height: 15px;
		}
	}

	&-slider {
		display: flex;
		overflow-x: scroll;
		padding-bottom: $default-spacing;
		padding-top: $default-spacing;
		margin-left: -$default-spacing;
		margin-right: -$default-spacing;

		&-item {
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
			border-radius: 10px;
			margin-right: $default-spacing;
			box-sizing: border-box;
			width: 200px;

			&:first-child {
				margin-left: $default-spacing;
			}
		}
	}
}

.circular-progressbar {
	text-align: center;
	padding-bottom: 20px;
	padding-top: 10px;
	width: 180px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
