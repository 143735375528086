.aspen-booking {
	&-page {
		height: 100%;
		display: flex;
		flex-flow: column;
	}

	&-content {
		flex-grow: 2;
		overflow: scroll;

		.datepicker-wrapper {
			width: 100%;
			margin: 0 $spacing-m;
			height: 20px;
		}
	}
}
