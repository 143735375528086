.event-feedback-card {
	&-content {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-flow: column;
		color: $color-neutral-60;

		&-icon {
			border-radius: 50%;
			border: 2px solid $color-main;
			padding: 5px;
		}

		.header {
			font-size: $font-size-xlg;
			font-weight: $font-weight-bold;
			margin-top: $spacing-xs;
			margin-bottom: 0;
			text-align: center;
		}
		.header-info {
			margin: 0;
			font-size: $font-size-sm;
			font-weight: $font-weight-light;
			margin-bottom: $spacing-m;
			color: $color-neutral-80;
		}

		.stars-wrapper {
			text-align: center;
			font-size: $font-size-xlg;
			display: flex;
			justify-content: center;
			margin-bottom: $spacing-m;
		}

		.star-item {
			display: flex;
			flex-direction: column;
		}

		.hidden-star {
			display: none;
		}

		.upper-layer-star {
			position: absolute;
		}
		.feedback-input {
			width: 80%;

			label {
				font-size: $font-size-sm;
				font-weight: $font-weight-medium;
			}
		}
	}
}
