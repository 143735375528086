.info-label {
	font-weight: $font-weight-bold;
	align-self: center;
	width: 100%;
	color: $color-heading;
	display: flex;
	align-items: center;
	margin: $spacing-m 0;
	font-size: $font-size-lg;
	word-break: break-all;

	p {
		margin: 0;
	}
	span {
		font-weight: normal;
		color: $color-heading;
		margin-left: $spacing-xxs;
	}
}

.refreshing-content {
	background-color: $color-neutral-light;
	padding-top: 30px;
}
