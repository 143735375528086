//history item css = only contains styling for history items that look like chat items
.history-item,
.comment-item {
	&-wrapper {
		display: flex;
		align-items: end;
		margin: 0 $spacing-m;
		margin-bottom: $spacing-md;
	}

	&-member {
		min-width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		color: $color-neutral-light;

		&-brown {
			background-color: brown;
			margin-left: $spacing-xs;
		}

		&-green {
			background-color: green;
			margin-right: $spacing-xs;
		}
	}
	&-content {
		width: 100%;
		background-color: $color-neutral-95;
		padding: $spacing-m;
		border-radius: 8px;
		border-bottom-left-radius: 0;
		@include sm-box-shadow;

		p {
			margin: 0;
		}
		.action {
			color: $color-neutral-70;
			font-style: italic;

			span {
				white-space: pre-wrap;
			}
		}
	}
}
