.report-problem-wrapper {
	height: calc(90% - 15px);
	overflow: auto;

	.report-problem-content {
		.select-problem {
			margin: $default-spacing;
			color: $color-secondary-text;
			text-transform: capitalize;
		}

		.report-problem-item {
			&-card {
				cursor: pointer;
				margin: $default-spacing;
				box-shadow: 0px 3px 8px rgb(0 0 0 / 10%);
				padding: 0px $default-spacing 0px 0px;
				border-radius: 10px;
				position: relative;
				display: flex;
				align-items: center;
				background-color: $color-neutral-light;

				.icon-wrapper {
					min-width: 50px;
					display: flex;
					justify-content: center;

					.custom-icon {
						width: 25px;
						text-align: center;
						fill: $color-main;
						position: absolute;
						top: $default-spacing;
					}
				}
			}

			&-heading {
				font-weight: $font-weight-bold;
				margin-bottom: 5px;
				margin-top: $default-spacing;
				font-size: $font-size-mmlg;
			}

			&-details {
				margin-bottom: $default-spacing;
				margin-top: 5px;
				font-size: $font-size-m;
			}

			&-heading,
			&-details {
				padding-right: 20px;
			}

			&-content {
				width: 100%;
			}
		}
	}

	.report-problem-message {
		margin: $spacing-xlg $default-spacing;
	}
}
