.ph-item,
.ph-row,
.ph-col-12 {
	border: none;
	width: 100%;
	height: 25px;
}
.time-tracker-wrapper {
	&-header {
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: space-between;
		font-size: $font-size-md;
		margin: $spacing-m $spacing-m 0 $spacing-m;
		.btn-wrapper {
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}

		.show-all {
			padding: $default-spacing 0 $default-spacing 0;

			.icon {
				width: 18px;
				height: 18px;
			}
		}
		.header {
			text-transform: uppercase;
		}
	}
	&-container {
		background-color: $color-main-0;
		border-radius: $default-border-radius;
		min-height: 150px;
		margin: $default-spacing;
		padding: $spacing-m 0;
		box-shadow: 4px 4px 20px rgb(0 0 0 / 10%);
		display: flex;
		justify-content: center;
		flex-flow: column;
		color: white;


		div:first-child {
			padding: 0 20px 0 20px;

			p.timer-header {
				font-weight: $font-weight-bold;
				font-size: $font-size-xlg;
				line-height: $line-height-xlg;
				margin: 0;
			}
			p.timer-description {
				font-weight: $font-weight-light;
				font-size: $font-size-mlg;
				line-height: $line-height-lgx;
			}
		}

		p {
			font-weight: $font-weight-bold;
			font-size: $font-size-xlg;
		}

		hr {
			width: 100%;
		}

		&-buttons {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			gap: 2rem;

			div {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				gap: 2rem;
				button {
					padding: 0;
					.icon {
						margin: 0;
						width: $spacing-xxlg;
						height: $spacing-xxlg;
					}
					svg {
						path {
							color: $color-neutral-light;
						}
					}
				}
				button:is(:active) {
					svg {
						path {
							color: $color-neutral-60;
						}
					}
				}
			}
		}
		.white{
			color:white;
		}

		&.overworking {
			background-color: #E8E8E8;
			color: #898989;

			.time-tracker-wrapper-container-buttons {
				div {
					button {
						svg {
							path {
								color: #898989;
							}
						}
					}
					button:is(:active) {
						svg {
							path {
								color: #898989;
							}
						}
					}
				}
			}
		}
		.grey-out-error{
			color:$color-red-0;
		}
	}
}
