.tour-finished {
	&-page {
		height: 100%;
		width: 100%;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-content: center;
	}

	&-headline {
		margin-top: 10%;
		margin-left: 10%;
		margin-right: 10%;
		letter-spacing: 2px;

		&-text {
			margin: 0;
			font-size: $font-size-xlg;
			color: $color-main;
			font-weight: $font-weight-black;
		}
	}

	&-image {
		margin-top: 2 * $default-spacing;
		background-image: url('../../../image/start_tour_image.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		height: 30%;
	}

	&-description {
		margin: 2 * $default-spacing;
		line-height: $line-height-lg;

		&-headline {
			margin: 0;
			margin-left: 10%;
			margin-right: 10%;
			font-size: $font-size-md;
			font-weight: $font-weight-normal;
		}

		&-text {
			margin: 0;
			font-size: $font-size-sm;
			color: $color-secondary-text;
		}
	}
	&-buttons {
		text-align: center;

		.btn {
			@extend .btn-primary;
		}
		&-skip {
			margin-top: $default-spacing;
			color: $color-main;
			font-size: $font-size-md;
			cursor: pointer;
		}
	}
}
