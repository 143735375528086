.office-switcher-page {
	display: flex;
	flex-flow: column;
	height: 100%;

	.select-office {
		margin: $default-spacing 0 0 $default-spacing;
		color: $color-main;
		font-size: $font-size-lg;
	}

	.search-input-wrapper {
		margin: $default-spacing;
	}

	.offices-list {
		display: flex;
		flex-flow: column;
		overflow-y: auto;
		margin-bottom: 100px;

		.office-item {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			max-height: 100px;
			border-bottom: solid $color-neutral-95 1px;
			margin: 5px $default-spacing;
			padding-bottom: 5px;

			.circle {
				min-width: 50px;
				min-height: 50px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				text-transform: uppercase;
				background-color: $color-neutral-95;
				color: $color-neutral-60;
			}

			.selected-office {
				background-color: $color-green-95;
			}

			.item-info {
				display: flex;
				flex-flow: column;

				p {
					margin: 5px $default-spacing;
				}

				.country {
					color: $color-neutral-60;
				}
			}

			.icon-favorite {
				margin-left: auto;
			}
		}

		.no-results {
			text-align: center;
			font-weight: bold;
		}
	}

	.button-wrapper {
		text-align: center;
		position: absolute;
		width: 100%;
		bottom: 0;
		height: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $color-neutral-light;

		.btn-primary {
			width: 80%;
		}
	}
}
