.event-agenda-page {
	height: 100%;
	display: flex;
	flex-flow: column;

	&-content {
		display: flex;
		flex-flow: column;
		overflow-y: scroll;

		.month {
			text-align: center;
			font-size: $font-size-mmlg;
			color: $color-neutral-60;
			font-weight: $font-weight-semibold;
		}

		&-days {
			display: flex;
			align-items: center;
			justify-content: center;
			border-top: 1px solid $color-neutral-95;
			padding: $spacing-m 0;
			box-shadow: 0 8px 20px -10px rgb(0 0 0 / 10%);

			.day-item-wrapper {
				min-width: 50px;
			}
		}
		&-agenda {
			flex-grow: 2;
			overflow-y: scroll;
			padding-top: $default-spacing;

			.dnd-wrapper {
				width: 100%;
				position: relative;

				.hour-skeleton-item {
					height: 120px;
					display: flex;
					justify-content: flex-end;
					position: relative;

					.hour-mark {
						width: 15%;
						display: flex;
						justify-content: flex-end;
						font-size: $font-size-sm;
						position: absolute;
						top: -5px;
						left: 0;

						p {
							margin: 0;
						}
					}

					.hour-item-empty {
						width: 85%;
						border-top: 1px dashed $color-neutral-95;
					}
				}

				.current-hour-mark {
					position: absolute;
					width: 85%;
					right: 0;
					display: flex;
					align-items: center;
					z-index: 1;

					.dot-hour-mark {
						width: 7px;
						height: 7px;
						border-radius: 50%;
						background: $color-main;
					}

					.line-hour-mark {
						height: 2px;
						width: 100%;
						background: $color-main;
					}
				}

				.agenda-item {
					border-radius: 10px;
					background-color: $color-neutral-light;
					border-left: 4px $color-red-50 solid;
					width: 83%;
					right: 0;
					position: relative;
					box-shadow: $color-neutral-95 5px 2px 2px 1px;

					&-content {
						width: 100%;
						height: 100%;
					}

					&-name {
						padding-left: $default-spacing;
						margin: 0;
						font-size: $font-size-sm;
						font-weight: bold;
					}

					&-timeslot {
						font-weight: normal;
					}
				}
			}
			.main-list-wrapper {
				height: 100%;
			}

			&-loading {
				display: flex;
				justify-content: center;
				margin-top: 10%;
			}
		}
	}
}
