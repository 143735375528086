.day-selection-wrapper {
	.month-selection {
		display: flex;
		justify-content: center;
		align-items: center;
		border-bottom: 1px $color-neutral-95 solid;
		padding: 10px $default-spacing;
		margin-bottom: 5px;
		color: $color-main;

		.selected-month {
			font-weight: bold;
			text-align: center;
			width: 200px;
			margin: 0;
		}
	}

	.day-selection {
		display: flex;
		justify-content: space-between;
		min-height: 70px;
		margin-bottom: $default-spacing;

		.action-icon {
			display: flex;
			align-items: center;
			margin: 0 5px;

			.icon {
				width: 20px;
				height: 20px;
			}
		}
	}

	.search-placeholder {
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 50px;
			height: 50px;
		}
	}
	.day-item-wrapper {
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		padding: $default-spacing 10px;
		border-radius: 10px;
		min-width: 20px;

		.day-header {
			font-weight: bold;
			font-size: $font-size-lg;
		}

		.day-abbreviation {
			font-size: $font-size-sm;
		}

		p {
			margin: 0;
		}
	}

	.today {
		background-color: $color-main;
		color: $color-neutral-light;

		.day-dot {
			background-color: $color-neutral-light;
		}
	}

	.past {
		background-color: $color-neutral-95;
	}

	.future {
		background-color: $color-main-80;
	}

	.day-dot {
		border-radius: 50%;
		width: 5px;
		height: 5px;
		background-color: $color-main;
	}
}
