.switch {
	&-checkbox {
		height: 0;
		width: 0;
		visibility: hidden;
	}

	&-label {
		display: flex;
		flex-shrink: 0;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		width: 50px;
		min-width: 50px;
		height: 30px;
		background: grey;
		border-radius: 50px;
		position: relative;
		transition: background-color 0.2s;

		.switch-button {
			content: '';
			position: absolute;
			top: 3px;
			left: 2px;
			width: 23px;
			height: 23px;
			border-radius: 50%;
			transition: 0.2s;
			background: $color-neutral-light;
			box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
		}
	}

	&-checkbox:checked + &-label &-button {
		left: calc(100% - 2px);
		transform: translateX(-100%);
	}

	&-label:active &-button {
		width: 24px;
	}
}
