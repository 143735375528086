.add-people-section {
	border-top: 4px solid $color-neutral-95;
	padding: $padding-m $padding-s;
	background: $color-neutral-light;
	position: relative;

	.colleagues-container {
		overflow-y: auto;
		max-height: 300px;
	}

	.title {
		@extend .booking-page-headline;
		margin: 0 0 $padding-s 0;
	}

	.subtitle {
		margin: $padding-xs 0 $padding-m 0;
		color: $color-heading;
	}

	.bold {
		font-weight: bold;
	}

	.angle-down {
		width: 15px;
		display: inline-flex;
		align-items: flex-end;
		justify-content: center;
		height: 15px;
		margin-left: 3px;
	}

	.list-wrapper {
		display: flex;
		flex-flow: column;
		overflow-y: auto;
		max-height: 250px;
		scroll-behavior: smooth;

		&-show-all {
			overflow: initial;
			max-height: unset;
		}

		&-show-all-opened.list-wrapper .hr-colleague-item-bottom:nth-of-type(n + 4),
		&-show-all-opened.list-wrapper .colleague-item-wrapper {
			display: flex;
		}

		.colleague-item-wrapper {
			margin: 10px 0;
			display: none;

			&:last-of-type {
				padding-bottom: 0;
			}

			.photo-wrapper {
				margin-left: 0;
			}
		}

		.colleague-item-wrapper:nth-of-type(-n + 4) {
			display: flex;
		}

		.hr-colleague-item-bottom:nth-of-type(n + 4) {
			display: none;
		}
	}

	.added-people-show-more {
		text-align: center;
		bottom: 0;
		font-weight: $font-weight-semibold;
		font-size: $font-size-lg;
		line-height: $line-height-md;
		color: $color-main;
		background: $color-neutral-light;
		margin: 0;
		padding: $padding-s 0 $padding-s 0;
	}

	.add-more-button {
		width: 100%;
		display: flex;
		align-items: center;
		color: var(--color-main);
		font-style: normal;
		font-weight: $font-weight-bold;
		font-size: $font-size-mmlg;
		line-height: $line-height-lgx;
		position: sticky;
		bottom: 0;
		background: $color-neutral-light;
		justify-content: center;
		padding-top: 8px;

		.plus-sign {
			margin-right: $default-spacing;
			width: 24px;
			height: 24px;
			color: var(--color-main);
			font-size: $font-size-mlg;
			background-color: $color-neutral-light;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 2px solid var(--color-main);
		}
	}

	.hr-colleague-item-bottom {
		margin: 0;
	}

	.colleague-item-wrapper {
		margin: 10px 0;
	}
}
.add-people-wrapper,
.colleagues-empty-wrapper {
	border-style: dashed;
	border-width: 2px;
	border-color: $color-neutral-95;
	display: flex;
	flex-flow: column;
	align-items: center;
	padding-top: $default-spacing;
	margin-top: 10px;

	p {
		color: $color-heading;
	}

	.add-colleagues-button {
		width: 30px;
		height: 30px;
		color: $color-neutral-light;
		font-size: $font-size-xlg;
		background-color: $color-neutral-80;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
	}
}
