.member-profile-edit-page {
	height: 100%;
	display: flex;
	flex-flow: column;
	&-content {
		flex-grow: 2;
		overflow: scroll;
		margin-top: $spacing-m;
		padding-bottom: 100px;

		.edit-info {
			margin: $spacing-m;
			font-style: italic;
		}

		.section-title {
			margin: $spacing-m;
			margin-top: $spacing-xlg;
			font-weight: bold;
		}
		.content-header {
			display: flex;
			align-items: center;
			margin: 0 $spacing-m;

			.member-image {
				img {
					width: 100px;
					height: 100px;
					border-radius: 50%;
					box-shadow: 5px 10px 20px rgb(0 0 0 / 30%);
				}
			}

			.member-name-title {
				margin-left: $spacing-m;
				p {
					margin: 0;
				}
				.name {
					font-size: $font-size-xlg;
					font-weight: $font-weight-semibold;
					color: $color-main;
					margin-bottom: $spacing-xs;
				}

				.title {
					font-size: $font-size-mmlg;
					margin: 0;
					color: $color-secondary-text;
				}

				.business-unit {
					font-size: $font-size-mmlg;
					margin: 0;
					margin-bottom: $spacing-m;
					color: $color-neutral-80;
				}
			}
		}

		.edit-section {
			margin: $spacing-m;

			label {
				margin-bottom: $spacing-s;
				color: $color-main;
				font-weight: $font-weight-bold;
			}
			textarea {
				max-width: none;
			}

			.multiselect {
				margin-top: $spacing-s;
			}

			.custom-option-label {
				margin-left: 5px;
			}
		}
	}
}
