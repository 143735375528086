.covid-status-page {
	.negative-test-wrapper {
		@include sm-box-shadow;
		padding: $default-spacing;
		margin-bottom: $default-spacing;

		.negative-test-header {
			font-weight: bold;
		}
	}

	.btn-wrapper {
		display: flex;
		width: 100%;
		text-align: center;

		.btn-primary {
			width: 50%;
			margin-top: $default-spacing;
		}
	}

	.plus-status-content {
		height: 300px;

		&-info {
			margin: $default-spacing 0;
			background: $color-neutral-95;
			padding: 24px;
			box-sizing: border-box;
			border-radius: 12px;
		}
	}
}
