.search-page {
	&-office-elements {
		overflow-y: scroll;
		padding-bottom: 100px;

		&-with-padding {
			padding-bottom: 350px; // if website is opened from mobile devices add a padding of 350px to account for opened keyboard
		}

		.loading-wrapper {
			width: 100%;
			display: flex;
			justify-content: center;
		}

		.letter-header,
		.recent-search-header {
			background-color: $color-neutral-95;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			span {
				margin-left: $default-spacing;
				font-size: $font-size-lg;
				color: $color-secondary-text;
				text-transform: uppercase;
				padding: 5px 0;
			}
		}

		.option-wrapper {
			padding: $default-spacing;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			border-bottom: 1px solid $color-neutral-95;
			cursor: pointer;

			.icon-wrapper {
				background-color: $color-blue-20;
				width: 50px;
				height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
			}

			span {
				margin-left: $default-spacing;
			}
		}
		.recent-search-option {
			.icon-wrapper {
				background-color: $color-neutral-95;

				.icon {
					width: 20px;
				}
			}
		}
		.option-wrapper:hover {
			background-color: $color-neutral-95;
		}

		.no-results-wrapper {
			width: 100%;
			display: flex;
			justify-content: center;
			font-size: $font-size-mlg;
		}
	}
}
