.colleague-page {
	height: 100%;
	background-color: $color-neutral-95;
	display: flex;
	flex-flow: column;

	&-colleague-info {
		display: flex;
		flex-flow: column;
		align-items: center;
		background-color: $color-neutral-light;

		&-profile-photo {
			border: 1px solid $color-neutral-95;
			width: 65px;
			height: 65px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 60px;
				height: 60px;
				border-radius: 50%;
			}
		}

		.name {
			font-weight: bold;
			font-size: $font-size-mlg;
			margin-bottom: $spacing-xs;
		}

		.email {
			color: $color-neutral-60;
			margin-bottom: $spacing-md;
		}

		.job-title {
			font-size: $font-size-mmlg;
		}

		p {
			margin: 0;
		}

		&-added-colleague {
			border: 1px solid $color-neutral-95;
			display: flex;
			align-items: center;
			border-radius: 32px;
			padding: $spacing-xs $spacing-m;
			color: $color-neutral-60;
			font-weight: bold;
		}
	}

	&-day-selection {
		background-color: $color-neutral-light;
		padding-top: 5px;

		@media (min-height: $breakpoint-height-sml) {
			padding-top: calc(2 * $default-spacing);
		}
	}

	&-container-bookings {
		display: flex;
		overflow-x: scroll;
		padding: 0 $default-spacing;

		&-date-header {
			margin: $spacing-s $spacing-m;
			font-size: $font-size-mmlg;

			span {
				font-weight: bold;
			}
		}

		.loading-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
		}

		.colleague-booking-item {
			padding: $spacing-xlg $spacing-m;
			border-radius: 12px;
			background-color: $color-neutral-light;
			margin-right: $spacing-m;
			display: flex;
			flex-flow: column;
			align-items: center;
			min-height: 210px;
			min-width: 252px;

			&-info {
				display: flex;
				align-items: center;
				flex-flow: column;
				position: relative;
			}

			.type-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-flow: column;
				flex-wrap: nowrap;
			}

			.icon-wrapper {
				width: 72px;
				height: 72px;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: $color-neutral-95;
				border-radius: 10px;
				margin-bottom: $spacing-xs;

				.icon {
					width: 36px;
					height: 36px;
				}
			}

			.teak-type-name,
			.entity-name {
				font-size: $font-size-mmlg;
				font-weight: bold;
				margin: 0;
				margin-bottom: 4px;
			}

			.entity-name {
				color: $color-main;
				margin-bottom: 10px;
			}

			.area-name,
			.time-slot {
				color: $color-neutral-60;
				font-size: $font-size-lg;
				margin: 0;
			}
			.time-slot {
				margin-bottom: $spacing-xlg;
				p {
					margin: 0;
				}
			}

			p {
				text-align: center;
			}

			.action-icon-wrapper {
				display: flex;
				width: 100%;
				justify-content: flex-end;
				position: absolute;

				.icon {
					width: 22px;
					height: 24px;
				}
			}

			.icon-add-favorite {
				top: -12px;
			}

			.icon-ellipsis {
				bottom: -12px;
			}
		}
	}

	&-no-colleagues {
		display: flex;
		align-items: center;
		flex-flow: column;
		text-align: center;
		font-weight: $font-weight-black;
		margin-top: calc(2 * $default-spacing);
		width: 100%;

		.icon {
			width: 50px;
			height: 50px;
		}

		.info-text {
			font-weight: $font-weight-normal;
			width: 80%;
		}
	}
}
