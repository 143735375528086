.our-areas-card {
	width: 30%;
	min-width: 120px;
	margin-right: 10px;

	&-holder {
		display: flex;
		overflow: auto;
	}

	&-info {
		text-transform: initial;
		font-weight: 300;
		font-size: 10px;
		padding-left: 0;
		vertical-align: middle;
		display: inline-block;
	}

	&-img-wrap {
		display: flex;
		justify-content: flex-end;
		border-top-left-radius: 15px;
		border-bottom-left-radius: 15px;
		height: 100px;

		.ph-item,
		.ph-row,
		.ph-col-12 {
			padding: 0;
			margin: 0;
			border: none;
			width: 100%;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
			border-radius: 15px;
		}
	}

	&-name {
		font-weight: 500;
		margin-top: 10px;
		margin-bottom: 10px;
		position: relative;
		font-size: 14px;
		max-width: 120px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		&:after {
			content: '';
			display: inline-block;
			position: absolute;
			width: 50px;
			height: 2px;
			background: $color-neutral-95;
			bottom: -3px;
			left: 0;
		}
	}

	&-descr {
		font-size: 10px;
		letter-spacing: 0.3px;
		margin-bottom: 10px;

		&-text {
			&:after {
				content: '\2022';
				display: inline-block;
				position: relative;
				padding-left: 5px;
				padding-right: 5px;
			}
		}

		&-qty {
			color: $color-text-special;
		}
	}
}
