.member-profile-page {
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	&-content {
		display: flex;
		width: 100%;
		flex-flow: column;
		align-items: center;
		margin-bottom: 50px;
		.content-header {
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				margin-right: 5px;
			}
		}

		.member {
			&-image-wrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				width: 100%;

				.custom-icon {
					position: absolute;
					right: 15px;
					top: 15px;
					cursor: pointer;
				}
			}
			&-image {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 30px;
				position: relative;

				.ph-item,
				.ph-row,
				.ph-col-12 {
					padding: 0;
					margin: 0;
					width: 130px;
					height: 130px;
					border-radius: 50%;
					border: none;
				}
				img {
					width: 130px;
					height: 130px;
					border-radius: 50%;
					box-shadow: 5px 10px 20px rgb(0 0 0 / 30%);
				}

				.icon {
					position: absolute;
					top: 10px;
					right: 5px;
				}
			}

			&-name {
				max-width: 250px;
				text-align: center;
				font-size: $font-size-xlg;
				font-weight: $font-weight-semibold;
				color: $color-main;
				margin-bottom: $spacing-xs;
			}

			&-title {
				max-width: 250px;
				text-align: center;
				font-size: $font-size-mmlg;
				margin: 0;
			}

			&-business-unit {
				max-width: 250px;
				text-align: center;
				font-size: $font-size-mmlg;
				margin: 0;
				margin-bottom: $spacing-m;
				color: $color-neutral-80;
			}
			&-info-label {
				text-align: center;
				font-size: $font-size-sm;
				color: $color-neutral-80;
				text-transform: uppercase;
				margin-top: $spacing-lg;
			}

			&-bio-info,
			&-diet {
				margin: 0 $spacing-xlg;
				text-align: center;
				font-size: $font-size-lg;
				color: $color-neutral-80;
			}

			&-hashtags {
				max-width: 250px;
				font-size: $font-size-m;
				color: $color-main;
				text-transform: uppercase;
				text-align: center;
			}

			&-languages {
				.language-ball {
					margin: 0 5px;
					width: 40px;
					height: 40px;
				}
			}

			&-domains {
				display: flex;
				align-items: center;
				gap: $spacing-lg;
				margin: 0 $spacing-lg;
				.domain {
					display: flex;
					flex-flow: column;
					justify-content: center;
					align-items: center;
					.icon {
						width: 20px;
						height: 20px;
					}

					p {
						margin: 0;
						margin-top: $spacing-xxxs;
						font-size: 9px;
					}
				}
			}
			&-social {
				display: flex;
				gap: $spacing-xlg;
			}

			&-add-new-contact {
				margin-top: $spacing-lg;
			}
		}

		.already-connected {
			color: $color-main;
		}
	}
}
