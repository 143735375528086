.member-badge-modal {
	flex-direction: column;
	justify-content: start;
	display: none;
	position: fixed;
	z-index: 4;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;

	.modal-member-card {
		background-color: $background-color-main;
		border-radius: 32px;
		margin: 15% 10% 0 10%;
		box-shadow: 1px 1px 4px 2px #a6a6a6;

		.close-badge-modal {
			text-align: right;
			padding-top: $default-spacing;

			.close {
				margin: 10%;
				font-size: $font-size-lg;
			}
		}

		.confirmation-wrapper {
			text-align: center;
			margin-bottom: 10%;

			.header {
				padding: 0 $default-spacing;
				font-weight: 600;
			}

			.icon {
				width: 150px;
				height: 150px;
			}
		}

		.member-card {
			display: flex;
			align-items: center;
			flex-direction: column;
			padding: $default-spacing;
			margin-bottom: $default-spacing;

			&-profile-photo {
				width: 150px;
				height: 150px;
				background-color: $color-background-profile-photo;
				border-radius: 50%;
				margin: $default-spacing 0;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					border-style: solid;
					border-width: 3px;
					border-color: $color-green-50;
				}
			}

			&-name {
				font-size: $font-size-lg;
				font-weight: 600;
			}

			&-job-title {
				font-size: $font-size-md;
				color: $color-secondary-text;
			}

			&-digital-badge {
				margin-top: 2 * $default-spacing;
				font-size: $font-size-md;
				font-weight: 600;
			}

			&-identity-verified {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 10px;

				p {
					margin: 0;
					margin-left: 5px;
					color: $color-green-50;
				}
			}

			.ph-picture {
				width: 150px;
				height: 150px;
				border-radius: 50%;
			}
			.ph-row {
				flex-direction: row;
				width: 100%;
			}
		}
	}
}

.show {
	display: flex;
}
