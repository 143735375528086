.confirm-booking-wrapper {
	height: 100%;
	display: flex;
	flex-flow: column;

	.container {
		display: flex;
		flex-flow: column;
		background: $color-neutral-95;
		overflow: scroll;
		flex-grow: 2;
	}

	.meeting-name-input {
		padding: 24px 16px;
		border-top: 4px solid $color-neutral-95;
		background-color: white;
	}
	.title,
	.primary-input-label {
		color: $color-main;
		font-weight: $font-weight-bold;
		font-size: $font-size-mlg;
		margin: 0 0 $padding-s 0;
	}
	.booking-information {
		padding: 0 $padding-s $padding-m $padding-s;
		display: flex;
		flex-flow: column;
		background: white;

		.status-description {
			padding: 10px $default-spacing;
			display: flex;
			align-items: center;
			font-size: $font-size-sm;
			margin-bottom: $padding-s;
			background-color: $color-neutral-90;

			.icon {
				font-size: $font-size-lg;
				margin-right: 10px;
			}
		}

		.entity-information {
			display: flex;
			flex-direction: column;
			gap: 12px;

			p {
				margin: 0 0 5px;
			}

			.info-entity {
				font-weight: $font-weight-bold;
				align-self: center;
				width: 100%;
				color: $color-heading;

				span {
					font-weight: normal;
					color: $color-heading;
				}
			}

			.entity-features {
				display: flex;
				flex-wrap: wrap;
			}

			.features-content {
				display: flex;
				margin: 5px 0;
				padding: 5px 0;
				max-height: 190px;
				overflow-x: scroll;
				color: $color-heading;
			}
		}
	}

	.buttons-wrapper {
		display: flex;
		padding: 16px 16px 32px;
		align-items: center;
		justify-content: space-between;
		flex-flow: row;
		background: $color-neutral-light;
		box-shadow: 4px 0 20px rgba(0, 0, 0, 0.1);

		.button {
			width: 50%;
		}

		button:not(:nth-child(2n + 1)) {
			margin-left: 8px;
		}
	}

	//see details of booking page

	.recurring-booking-info {
		padding: 0 $default-spacing;
		border-top: 4px solid $color-neutral-95;

		.info-section {
			background-color: $color-main-80;
			display: flex;
			align-items: center;

			.icon {
				margin: 0 10px;
			}
		}
	}

	.recurring-booking-wrapper {
		.title {
			color: $color-main;
			font-weight: $font-weight-bold;
			font-size: $font-size-mlg;
			margin: 0 0 $padding-s 0;
		}
	}

	.price {
		font-weight: bold;
		margin: 0 0 5px 15px;

		span {
			font-weight: normal;
		}
	}

	.button {
		@media all and (max-width: $breakpoint-sm) {
			font-size: $font-size-m;
		}
	}
}
